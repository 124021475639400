import css from './Menu.module.scss';
import Button from "../../../../UI/Button/Button";
import {Link} from 'react-scroll';
import {Sections, ListSections} from "../model";
import ModalStore from "../../../../../stores/ModalStore";
import OrderForm from "../OrderForm/OrderForm";
import React from "react";
import useNotEmptySections from "../useNotEmptySections";
import {observer} from "mobx-react";
import ym from "react-yandex-metrika";
import {VkPixelPush} from "../../../../../utils/vkPixel";

export const listSections:ListSections = {
    [Sections.description]: 'Описание',
    [Sections.conditions]: 'Условия и проживание',
    [Sections.schedules]: 'Программа и расписание',
    [Sections.coaches]: 'Тренеры',
    [Sections.price]: 'Цены',
    [Sections.services]: 'Дополнительные услуги',
    [Sections.location]: 'Местоположение',
}

const getList = () => Object.entries(listSections).map(item => ({
    slug: item[0] as Sections,
    name: item[1]
}));

const Menu:React.FC = () => {

    const notEmptySections = useNotEmptySections();

    const orderButtonClick = () => {
        ModalStore.showModal(<OrderForm/>);
        if (process.env.NODE_ENV === 'production') {
            ym('reachGoal', 'camp_order');
            VkPixelPush('camp_order_button_click');
        }
    }

    return (
        <div className={css.container}>
            <ul className={css.list}>
                {getList().map(item => notEmptySections[Sections[item.slug]] ?
                    <Link
                        to={item.slug}
                        key={item.slug}
                        className={`${css.item}`}
                        offset={50}
                        spy={true}
                        activeClass={css.active}
                        smooth={true}
                        duration={300}
                    >{item.name}</Link> : null
                )}
            </ul>
            <Button
                buttonText={'Оставить заявку'}
                onClick={orderButtonClick}
                className={css.button}
            />
        </div>
    );
};

export default observer(Menu);