import css from './FilterMobButton.module.scss';
import FilterIcon from "../Icon/FilterIcon";
import {ClassName} from "../../../../models/ui/ClassName";
import classNames from "classnames";

interface FilterMobButtonProps extends ClassName {
    text?: string,
    onClick: () => void,
    activeFiltersCount?: number
}

const FilterMobButton:React.FC<FilterMobButtonProps> = ({
    text= 'Фильтр',
    className,
    onClick,
    activeFiltersCount
                                                        }) => {
    return (
        <div className={classNames(
            css.container,
            className
        )} onClick={onClick}>
            <FilterIcon/>
            <div className={css.text}>{text}</div>
            {!!activeFiltersCount && <div className={css.activeFiltersCount}>{activeFiltersCount}</div>}
        </div>
    );
};

export default FilterMobButton;