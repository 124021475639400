import CampPageStore from "../../../../stores/CampPageStore";
import {useMemo} from "react";
import {Sections} from "./model";

type NotEmptySections = {
    [key in Sections]: boolean
}

const useNotEmptySections = () => {

    const data = CampPageStore.data;

    const notEmptySections = useMemo(():NotEmptySections => ({
        [Sections.description]:
            !!data?.name ||
            (!!data && !!Object.keys(data.location).length) ||
            !!data?.sports.length ||
            !!data?.description ||
            !!(data?.dateFrom && data?.dateTo) ||
            (!!data && !!Object.keys(data.organizer).length),
        [Sections.conditions]:
            !!data?.accommodations.length,
        [Sections.schedules]:
            !!data?.schedules.length,
        [Sections.coaches]:
            !!data?.coaches.length,
        [Sections.price]:
            !!data?.price,
        [Sections.services]:
            !!data?.service,
        [Sections.location]:
            !!data?.place ||
            !!data?.mapUrl
    }), [data])

    return notEmptySections;
};

export default useNotEmptySections;