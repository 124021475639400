import {ClassName} from "../../../../models/ui/ClassName";
import React from "react";
import classNames from "classnames";
import css from './FilterIcon.module.scss';

interface FilterIconProps extends ClassName {}

const FilterIcon:React.FC<FilterIconProps> = ({
    className
                                              }) => {
    return (
        <svg viewBox="0 0 1792 1792" className={classNames(
            css.icon,
            className
        )} xmlns="http://www.w3.org/2000/svg">
            <path d="M1595 295q17 41-14 70l-493 493v742q0 42-39 59-13 5-25 5-27 0-45-19l-256-256q-19-19-19-45V858L211 365q-31-29-14-70 17-39 59-39h1280q42 0 59 39z"/>
        </svg>
    );
}

export default FilterIcon;