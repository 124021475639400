import arrowImg from 'components/Pages/Profile/LKLayout/BackLink/img/arrow.svg';
import style from 'components/Pages/Profile/LKLayout/BackLink/BackLink.module.css';
import browserHistory from 'browserHistory';
import { getQuery } from '../../../../../utils/urlConstructors';
import HistoryStore from "../../../../../stores/HistoryStore";

interface BackLinkProps {
    scrollToOld?: boolean
}

const BackLink:React.FC<BackLinkProps> = ({
    scrollToOld
                                          }) => {
  if (!browserHistory.length) return null;

  if (getQuery('source')) {
    return null;
  }

  return (
    <div className={style.backLink} onClick={() => {
        if (scrollToOld) HistoryStore.setShouldScrolling(true);
        browserHistory.goBack();
    }}>
      <img src={arrowImg} alt="React Logo" />{' '}
      <span className={style.backLinkText}>Назад</span>
    </div>
  );
};

export default BackLink;
