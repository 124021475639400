import Archive from "../Archive";
import api from "../../../../../../api";
import InsideTabs from "../InsideTabs/InsideTabs";

enum Tabs {
    games,
    trainings
}

const content = [
    { tabIndex: Tabs.games, title: 'Игры', content: <Archive apiCall={api.profile.getPastEvents} emptyText="Нет доступных игр"/> },
    { tabIndex: Tabs.trainings, title: 'Тренировки', content: <Archive apiCall={api.profile.getPastTrainings} emptyText="Нет доступных тренировок"/> }
]

const EventsArchive:React.FC = () => {
    return (
        <InsideTabs
            defaultTab={Tabs.games}
            tabs={content}
        />
    );

};

export default EventsArchive;