import InsideTabs from "../InsideTabs/InsideTabs";
import Archive from "../Archive";
import api from "../../../../../../api";
import UserStore from "../../../../../../stores/UserStore";
import commonCss from '../Common.module.scss';

enum Tabs {
    best,
    all
}

const content = [
    { tabIndex: Tabs.best, title: 'Лучшие', content: <Archive apiCall={api.profile.getRecommendationsTop} emptyText="Нет доступных рекомендаций"/> },
    { tabIndex: Tabs.all, title: 'Все', content: <Archive apiCall={api.profile.getRecommendations} emptyText="Нет доступных рекомендаций"/> }
]

const Recommendations:React.FC = () => {
    return UserStore.userData?.isTester ? (
        <InsideTabs
            defaultTab={Tabs.best}
            tabs={content}
        />
    ) : <div className={commonCss.container}>Данный раздел в разработке</div>;
};

export default Recommendations;