import { useState } from 'react';

interface UseTabsProps {
  defaultTab: number,
  routerAnchor?: string
}

function useTabs(
    defaultTab: UseTabsProps['defaultTab'] = 0,
    routerAnchor?: UseTabsProps['routerAnchor']
    ) {

  const [currentTab, setCurrentTab] = useState(defaultTab);
  const tabs = { currentTab, setCurrentTab };
  const connect = (index: number) => ({ tabs, index, routerAnchor });

  return { ...tabs, connect };
}

export default useTabs;
