import arenaCss from './ArenaPage.module.scss';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { HashLink as Link } from 'react-router-hash-link';
import Arena from '../../../models/entities/arena';
import ArenaStore from '../../../stores/ArenaStore';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import { observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';
import ModalStore from 'stores/ModalStore';
import ArenaBookingForm from 'components/Pages/Arena/ArenaBookingForm';
import ErrorPage from "../Error/ErrorPage";
import commonCss from "../../../css/Common.module.css";
import EventCard from "../../common/EventCard/EventCard";
import Page from "../../Hoc/Page/Page";
import classNames from "classnames";
import noImg from 'imgs/no-img.png';
import {SearchResultGrid} from "../Search/SearchResults/SearchResultGrid/SearchResultGrid";
import Button from "../../UI/Button/Button";
import SpinnerWrapper from "../../UI/Loader/SpinnerWrapper/SpinnerWrapper";

SwiperCore.use([Navigation]);

function ArenaPage() {

  const { slug } = useParams<{ slug: Arena['slug'] }>();

  useEffect(() => {
    ArenaStore.getArena(slug);
    return () => ArenaStore.reset();
  }, [slug]);

  const data = ArenaStore.data;
  const loading = ArenaStore.requestState === 'pending';
  const pageNotExist = ArenaStore.arenaNotExist;

  if (pageNotExist) return <ErrorPage/>;

  return (
      <Page>
        {loading && <SpinnerWrapper/>}
        {data ?
            <>
              <div className={commonCss.wrap}>

                <div className={arenaCss.headContent}>
                  <Swiper navigation={true} className={arenaCss.swiper}>
                    {data.arena.photos.map((p, key) => (
                        <SwiperSlide className={arenaCss.swiperSlide} key={key}>
                          <img src={p} alt={ArenaStore.data?.arena.slug} className={commonCss.stretchedImg}/>
                        </SwiperSlide>
                    ))}
                  </Swiper>
                  <div className={arenaCss.headInfo}>
                    <div className={arenaCss.headInfoTitle}>
                      <h1>{data.arena.name}</h1>
                    </div>
                  </div>
                </div>

                <div className={classNames(
                    arenaCss.infoContent,
                    !data.arena.advantages.length && arenaCss.noAdvantages
                )}>
                  <div className={arenaCss.infoLogo}>
                    <img
                        src={data.arena.logo}
                        className={classNames(
                            arenaCss.img,
                            commonCss.stretchedImg
                        )}
                        alt={data.arena.name}
                    />
                  </div>
                  <div className={arenaCss.infoDesc}>
                    <div className={arenaCss.infoDesc__titles}>
                      <div className={arenaCss.infoDesc__title}>
                        {data.arena.address}
                      </div>
                      <div className={arenaCss.infoDesc__subtitle}>
                        <Link
                            to={`/arenas/${data.arena.slug}#map-arena`}
                            className="scrollLink"
                        >
                          Как добраться?
                        </Link>
                      </div>
                    </div>
                    <div className={arenaCss.infoDesc__kinds}>
                      {ArenaStore.sportsInfo.map((d, key) => (
                          <div className={arenaCss.kindItem} key={key}>
                            <div className={arenaCss.kindItem__icon}>
                              <img
                                  src={
                                      d.sport?.logo || noImg
                                  }
                                  alt={d.sport?.name}
                              />
                            </div>
                            <div>
                              <div className={arenaCss.kindItem__title}>
                                {d.sport?.name}
                              </div>
                              <div className={arenaCss.kindItem__info}>
                                {d.formats.join(', ')}
                              </div>
                            </div>
                          </div>
                      ))}
                    </div>
                  </div>
                  {!!data.arena.advantages.length && (
                      <div className={arenaCss.contentArenaConditions}>
                        <div className={arenaCss.contentArenaConditions__title}>Преимущества</div>
                        <div className={arenaCss.contentArenaConditions__list}>
                          <ul className={arenaCss.list}>
                            {data.arena.advantages.map((a, key) => (
                                <li
                                    className={`${arenaCss.list__item} ${
                                        arenaCss[a.code || '']
                                    }`}
                                    key={key}
                                >
                                  {a.name}
                                </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                  )}
                </div>
              </div>

              <div className={arenaCss.container__content__arena}>
                <div className={commonCss.wrap}>
                  <div className={arenaCss.contentArena}>
                    <div className={classNames(
                        arenaCss.contentArena__desc,
                        !data.arena.advantages.length && arenaCss.noAdvantages
                    )}>
                      <div className={arenaCss.contentArena__descText} dangerouslySetInnerHTML={{
                        __html: data.arena.description,
                      }}
                      />
                      <div className={arenaCss.contentArena__date}>
                        <div className={arenaCss.contentArena__date__title}>Время работы</div>
                        <div className={arenaCss.contentArena__date__time}>
                          Будни: {data.arena.weekdayStart} -{' '}
                          {data.arena.weekdayEnd}.
                        </div>
                        <div className={arenaCss.contentArena__date__time}>
                          Выходные: {data.arena.weekendStart} -{' '}
                          {data.arena.weekendEnd}.
                        </div>
                        {!data.arena.deleted &&
                            (
                                <div className={arenaCss.buttonWrapper}>
                                  {data.arena?.createEventsAvailable ?
                                      <NavLink to={`/search/?tab=rental&arena=${data.arena?.id}`}><Button className={arenaCss.button} buttonText={'Забронировать'}/></NavLink> :
                                      <Button className={arenaCss.button} buttonText={'Оставить заявку'} onClick={() => {
                                        ModalStore.showModal(<ArenaBookingForm />);
                                      }}/>}
                                </div>
                            )
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={commonCss.wrap}>
                <div className={arenaCss.games}>
                  <div className={arenaCss.games__section}>
                    <div className={arenaCss.games__head}>Ближайшие игры</div>
                    {data.events.length ?
                        <SearchResultGrid items={data.events.map(item => <EventCard event={item} key={item.event.hash}/>)}/> :
                        <div className={arenaCss.games__head__cards__items__profile__empty}>Нет доступных игр</div>
                    }
                  </div>
                  <div className={arenaCss.games__section}>
                    <div className={arenaCss.games__head}>Ближайшие тренировки</div>
                    {data.trainings.length ?
                        <SearchResultGrid items={data.trainings.map(item => <EventCard event={item} key={item.event.hash}/>)}/> :
                        <div className={arenaCss.games__head__cards__items__profile__empty}>Нет доступных тренировок</div>
                    }
                  </div>
                </div>

                {
                    (!!data.arena?.address || !!data.arena?.navigation || !!data.arena?.mapUrl) && (
                        <div className={arenaCss.mapArena} id="map-arena">
                          <div className={arenaCss.mapArena__title}>Местоположение</div>
                          {data.arena?.address && <div className={arenaCss.mapArena__place}>{data.arena.address}</div>}
                          {data.arena?.navigation && <div className={arenaCss.mapArena__place}>{data.arena.navigation}</div>}
                          {data.arena?.mapUrl && <iframe
                              src={data.arena?.mapUrl}
                              title={data.arena?.name}
                              width="100%"
                              height="450"
                              frameBorder="0"
                              className={arenaCss.map}
                          />}
                        </div>
                    )}

              </div>
            </> : null
        }
      </Page>
  );
}

export default observer(ArenaPage);
