import Page from "../../Hoc/Page/Page";
import commonCss from "../../../css/Common.module.css";
import Tab from "../../UI/Tabs/Tab";
import TabList from "../../UI/Tabs/TabList";
import React, {useEffect, useLayoutEffect, useMemo, useState} from "react";
import useTabs from "../../UI/Tabs/tabHook";
import TabPanel from "../../UI/Tabs/TabPanel";
import css from './SearchPage.module.scss';
import classNames from "classnames";
import DateFilter from "../Search/Filters/DateFilter/DateFilter";
import SortFilter from "../Search/Filters/SortFilter/SortFilter";
import SportFilter from "../Search/Filters/SportFilter/SportFilter";
import ArenaFilter from "../Search/Filters/ArenaFilter/ArenaFilter";
import PlayerLevelFilter from "../Search/Filters/PlayerLevelFilter/PlayerLevelFilter";
import SearchPageStore from "../../../stores/SearchPageStore";
import ViewSwitcher from "./ViewSwitcher/ViewSwitcher";
import GamesSection from "../Search/GamesSection/GamesSection";
import {Tabs, titles, Views} from "./model";
import MapSection from "./MapSection/MapSection";
import CreateGameSection from "../Search/SearchResults/CreateGameSection";
import {observer} from "mobx-react";
import Filter from "../../UI/Filter/Filter";
import EventTypeFilter from "./Filters/EventTypeFilter/EventTypeFilter";
import {applyQuery, getQuery} from "../../../utils/urlConstructors";
import HistoryStore from "../../../stores/HistoryStore";
import FilterMobButton from "../../UI/Filter/MobButton/FilterMobButton";
import {LocationUpdater} from "../../../utils/locationUpdater";

const SearchPage:React.FC = () => {

    const eventsPending = useMemo(() => {
        return SearchPageStore.requestState === 'pending';
    }, [SearchPageStore.requestState]);

    useEffect(() => {
        SearchPageStore.setDefaultFilters();
        SearchPageStore.getGameEvents();
        SearchPageStore.getFilters();
        return () => {
            SearchPageStore.resetData();
        }
    }, []);

    const defaultTabIndex = useMemo(() => {
        const index = Number(getQuery('tabId')) || Tabs.events;
        if (index < 0) {
            return Tabs.events;
        }
        return index;
    }, []);

    useLayoutEffect(() => {
        return () => {
            HistoryStore.setScrollPosition(window.scrollY);
        }
    }, []);

    useEffect(() => {
        if (!eventsPending && SearchPageStore.events && HistoryStore.shouldScrolling && HistoryStore.scrollPosition) {
            window.scrollTo(0, HistoryStore.scrollPosition);
            HistoryStore.resetScrollingData();
        }
    }, [eventsPending]);

    const { connect, currentTab, setCurrentTab } = useTabs(defaultTabIndex);

    const defaultActiveView = useMemo(() => {
        return getQuery('view') && Views[getQuery('view') as keyof typeof Views]
            ? Views[getQuery('view') as keyof typeof Views] :
            Views.table;
    }, []);

    const [activeView, setActiveView] = useState<Views>(defaultActiveView);

    useEffect(() => {
        if (currentTab === Tabs.create && activeView === Views.map) {
            setActiveView(Views.table);
            LocationUpdater.replaceLocation(
                applyQuery(
                    'view',
                    Views[0]
                )
            );
        }
        LocationUpdater.replaceLocation(
            applyQuery(
                'tabId',
                String(currentTab)
            )
        );
    }, [currentTab]);

    useEffect(() => {
        if (activeView === Views.map && currentTab === Tabs.create) {
            setCurrentTab(Tabs.events);
        }
    }, [activeView]);

    const [filterMobileShow, setFilterMobileShow] = useState(false);
    const toggleFilterMobile = () => setFilterMobileShow(!filterMobileShow);

    const activeFiltersCount = SearchPageStore.activeFiltersCount;

    const allFiltersCleaning = () => {
        SearchPageStore.allFiltersCleaning();
    }

    return (
        <Page className={css.page}>
            <div className={classNames(commonCss.wrap, css.mainContainer)}>
                <div className={css.tabsContainer}>
                    <div className={css.dateFilter}>
                        <DateFilter/>
                    </div>
                    <div className={css.tabsHeader}>
                        <TabList className={css.tabsList}>
                            <Tab {...connect(Tabs['events'])}>{titles[Tabs['events']]}</Tab>
                            <Tab {...connect(Tabs['create'])}>{titles[Tabs['create']]}</Tab>
                        </TabList>
                        <div className={classNames(
                            css.sort,
                            activeView === Views.map && css.disabled
                        )}>
                            <div className={css.sort__title}>Сортировка:</div>
                            <SortFilter disabled={activeView === Views.map}/>
                        </div>
                    </div>
                    <div className={css.filtersRow}>
                        <div className={css.filters}>
                            <ArenaFilter className={css.filterItem}/>
                            <SportFilter className={css.filterItem}/>
                            <PlayerLevelFilter className={css.filterItem}/>
                            <EventTypeFilter className={css.filterItem}/>
                        </div>
                        <FilterMobButton
                            activeFiltersCount={activeFiltersCount}
                            onClick={toggleFilterMobile}
                            className={css.filterMob}
                        />
                        <ViewSwitcher
                            state={{
                                activeView,
                                setActiveView
                            }}
                            className={css.viewSwitcher}
                        />
                    </div>
                    <TabPanel {...connect(Tabs['events'])}>
                        <div className={css.tabPanelContainer}>
                            {activeView === Views.table && <GamesSection/>}
                            {activeView === Views.map && <MapSection setCurrentTab={setCurrentTab}/>}
                        </div>
                    </TabPanel>
                    <TabPanel {...connect(Tabs['create'])}>
                        <div className={css.tabPanelContainer}>
                            <CreateGameSection/>
                        </div>
                    </TabPanel>
                </div>
            </div>
            <Filter
                onClose={toggleFilterMobile}
                openedOnMob={filterMobileShow}
                className={css.filtersCompMob}
                allCleaning={allFiltersCleaning}
                activeFiltersCount={activeFiltersCount}
            >
                <ArenaFilter />
                <SportFilter />
                <PlayerLevelFilter />
                <EventTypeFilter />
                <div className={css.sortTitleMob}>Сортировка</div>
                <SortFilter
                    disabled={
                        currentTab === Tabs['create'] ||
                        activeView === Views.map
                    }
                    type={'GrayRoundedSelect'}
                />
            </Filter>
        </Page>
    );
};

export default observer(SearchPage);