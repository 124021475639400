import React from "react";
import CheckboxProps from "../../../models/ui/form/checkboxProps";
import Checkbox from "./Checkbox";
import styles from "./FlatOrangeCheckbox.module.scss";

type Props = Omit<CheckboxProps, "styles">;

export const FlatOrangeCheckbox: React.FC<Props> = (props) => {
    return (
        <Checkbox {...props} styles={styles}/>
    )
}