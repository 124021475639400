import css from './AccountConfirmModal.module.scss';
import { useEffect, useState } from 'react';
import useInterval from '../../../../common/hooks/useInterval';
import api from '../../../../../api';

interface CountdownProps {
  seconds?: number;
  callback: () => void;
}

function Countdown({ seconds, callback }: CountdownProps) {
  const [secondsLeft, setSeconds] = useState(seconds || 5);
  const [delay, setDelay] = useState<number | null>(1000);
  useInterval(() => {
    setSeconds((s) => s - 1);
  }, delay);
  useEffect(() => {
    if (secondsLeft <= 0) {
      setDelay(null);
      callback();
    }
  }, [secondsLeft, callback]);
  return <>{secondsLeft} сек.</>;
}

interface Props {
  sendFirstEmail?: boolean;
  delay?: number;
}

function AccountConfirmModal({ sendFirstEmail, delay }: Props) {
  const initialDelay = Number.isInteger(delay) ? delay : 60;
  const [sendDelay, setSendDelay] = useState(initialDelay);
  const [canSend, setCanSend] = useState(false);

  useEffect(() => {
    if (sendFirstEmail) {
      sendEmail();
    }
  }, [sendFirstEmail]);

  const sendEmail = async () => {
    const { delay } = await api.auth.sendEmail();
    setSendDelay(delay);
    setCanSend(false);
  };

  return (
    <>
      <div className={css.header}>Добро пожаловать в команду Sploteam🚀</div>

      <p className={css.text}>
        На указанный вами адрес отправлено электронное письмо. Для завершения
        регистрации пройдите по ссылке, указанной в письме. <br />
        <br />
        Если письмо не пришло, проверьте папку «Спам» или отправьте запрос еще
        раз:
      </p>

      {!canSend && (
        <div className={css.emailSend}>
          Отправить повторно письмо:{' '}
          <Countdown callback={() => setCanSend(true)} seconds={sendDelay} />
        </div>
      )}
      {canSend && (
        <div className={css.emailSendOrange} onClick={sendEmail}>
          Отправить повторно письмо
        </div>
      )}
    </>
  );
}

export default AccountConfirmModal;
