import api from 'api';
import browserHistory from 'browserHistory';
import { EventCreateFormValues } from 'components/Pages/Event/Create/EventCreatePage';
import { SearchAccess } from 'enums/event';
import { PlayerLevelStrings } from 'enums/player';
import { makeAutoObservable, toJS } from 'mobx';
import PlayerLevel from 'models/entities/playerLevel';
import Sport from 'models/entities/sport';
import SportFormat from 'models/entities/sportFormat';
import { RequestState } from 'models/responses/common';
import PrepareEventResponse from 'models/responses/prepareEvent';
import ReactSelectOption from 'models/ui/form/reactSelectOption';
import {VkPixelPush} from "../utils/vkPixel";

const searchAccessTexts: Record<SearchAccess, string> = {
  [SearchAccess.private]: 'Нет, у меня есть свои игроки, поиграем между собой',
  [SearchAccess.searchPartners]: 'Хочу найти игроков для совместной игры',
  [SearchAccess.searchTeam]: '',
  [SearchAccess.offlineBooking]: '',
  [SearchAccess.maintenance]: '',
  [SearchAccess.searchPlayers]: '',
  [SearchAccess.training]: '',
  [SearchAccess.externalTraining]: '',
  [SearchAccess.partnerGame]: '',
  [SearchAccess.vip]: `Хочу найти игроков из группы "Хард"`,
};

export const searchAccessHints: Record<string, string> = {
  [SearchAccess.private]:
    'Можно будет бесплатно отменить бронирование в течение часа после оплаты или за 4 часа до начала игры',
  [SearchAccess.searchPartners]:
    'Если мы не найдем с кем тебе поиграть, то автоматически вернем все оплаченные средства',
};

export const playerLevelHints: Record<Partial<PlayerLevelStrings>, string> = {
  unknown: '',
  light: 'Не играл, не сильно спортивный, нужен тренер так как хочу научиться;',
  medium:
    'Достаточно спортивный, ловкий, но с данным видом спорта только знакомлюсь или играл ну очень давно и сейчас не дотягиваю до уровня любителя;',
  hard: 'Периодически играю и тренируюсь - уверенный среднячок;',
};

export const defaultSelectOption: ReactSelectOption<string> = {
  label: 'Выберите значение',
  value: '',
};

class EventCreateStore {
  constructor() {
    makeAutoObservable(this);
  }

  eventData?: PrepareEventResponse;
  requestState: RequestState = 'initial';
  eventNotExist?: boolean

  reset() {
    this.eventData = undefined;
    this.requestState = 'initial';
    this.eventNotExist = false;
  }

  async getData(hash: string) {
    if (this.requestState === 'pending') return;
    this.requestState = 'pending';
    try {
      const eventData = await api.gameEvents.getPreparedEvent(hash);
      this.getDataDone(eventData);
    } catch(e: any) {
      this.getDataError();
      if (e?.error?.response?.data?.code === 'event_not_found') {
        this.eventNotExist = true;
      }
    }
  }

  getDataDone = (data: PrepareEventResponse) => {
    this.eventData = data;
    this.requestState = 'done';
  };

  getDataError = () => {
    this.requestState = 'error';
  };

  get searchAccessOptions() {
    if (!this.eventData) return [defaultSelectOption];
    const options: ReactSelectOption<SearchAccess>[] =
      this.eventData.searchAccesses.map((sa) => ({
        value: sa,
        label: searchAccessTexts[sa],
      }));
    return [defaultSelectOption, ...options];
  }

  get playerLevelsOptions() {
    if (!this.eventData) return [];
    return this.eventData.playerLevels.map((pl) => ({
      label: pl.name,
      value: toJS(pl),
    }));
  }

  sportFormatOptions(sportId?: Sport['id']) {
    if (!this.eventData || !sportId) return [];
    return this.eventData.sportFormats
      .filter((sf) => sf.sportId === sportId)
      .map((s) => ({
        label: s.gameFormat.name,
        value: toJS(s),
      }));
  }

  get sportOptions() {
    if (!this.eventData) return [];
    return this.eventData.sports.map((s) => ({
      label: s.name,
      value: toJS(s),
    }));
  }

  getSearchAccessHint = (s: SearchAccess) => searchAccessHints[s] || '';
  getPlayerLevelHint = (pl: PlayerLevel) => playerLevelHints[pl?.label] || '';

  getCost(
    searchAccess: SearchAccess,
    sportId?: Sport['id'],
    sportFormatId?: SportFormat['id']
  ) {
    if (!this.eventData) return undefined;
    const costContainer = this.eventData.costs.find((c) => {
      switch (searchAccess) {
        case SearchAccess.private:
          return c.sportId === sportId && c.searchAccess === searchAccess;
        case SearchAccess.searchPartners:
          return (
            c.sportId === sportId &&
            c.searchAccess === searchAccess &&
            sportFormatId === c.sportFormatId
          );
        case SearchAccess.vip:
          return (
            c.sportId === sportId &&
            c.searchAccess === searchAccess &&
            sportFormatId === c.sportFormatId
          );
        default:
          return false;
      }
    });
    return costContainer ? costContainer.cost : undefined;
  }

  submitEventCreateForm = async (values: EventCreateFormValues) => {
    if (!this.eventData) return;
    const beforePayEventResponse = await api.gameEvents.editEvent(
      this.eventData.event.hash,
      {
        playerLevel: values.playerLevel?.value?.id || undefined,
        comment: values.comment || undefined,
        searchAccess: values.searchAccess.value,
        sportId: values.sport.value.id,
        sportFormatId: values.sportFormat?.value?.id || undefined,
      }
    );
    if (beforePayEventResponse) {
      VkPixelPush('create_event_completed');
      browserHistory.replace(
        `/event/pay/${this.eventData.event.hash}/order/${beforePayEventResponse.order.id}`
      );
    }
  };
}

export default new EventCreateStore();
