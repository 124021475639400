import css from './Slider.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import {SwiperOptions} from "swiper";
import React from "react";
import noPhoto from './no-photo.png';

export interface Slide {
    img?: string,
    title?: string,
    description?: string
}

interface SliderProps extends SwiperOptions {
    slides: Slide[],
}

const Slider:React.FC<SliderProps> = ({
    slides,
    ...rest
                                      }) => {
    return (
        <Swiper
            navigation={true}
            spaceBetween={20}
            className={css.slider}
            breakpoints={{
                320: {
                    slidesPerView: 1
                },
                900: {
                    slidesPerView: 3
                }
            }}
            {...rest}
        >
            {slides.map((slide) => (
                <SwiperSlide key={`${slide.title}_${slide.img}`} className={css.slide}>
                    <div className={css.slide__imgWrapper}>
                        {slide.img ?
                            <img src={slide.img} alt={slide.title} className={css.slide__img}/> :
                            <img src={noPhoto} alt={'Нет фото'} className={css.slide__noImg}/>
                        }
                    </div>
                    <div className={css.slide__inner}>
                        {slide.title && <div className={css.slide__title}>{slide.title}</div>}
                        {slide.description && <div className={css.slide__desc}>{slide.description}</div>}
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
    );
}

export default Slider;