import ControlledSelect from 'components/UI/DefaultFormUI/ControledSelect/ControlledSelect';
import ControlHint from 'components/UI/DefaultFormUI/ControlHint/ControlHint';
import ControlLabel from 'components/UI/DefaultFormUI/ControlLabel/ControlLabel';
import FormControl from 'components/UI/DefaultFormUI/FormControl/FormControl';
import FormSelectProps from 'models/ui/form/formSelectProps';
import React from 'react';

const FormSelectGroup: React.FC<FormSelectProps<string>> = (props) => {
  const { hidden, getHint, ...rest } = props;
  const hint = getHint && getHint(props.value?.value);
  return (
    <FormControl hidden={!!hidden}>
      <ControlLabel>{rest.label}</ControlLabel>
      <ControlledSelect {...rest} className="react-select-container" />
      {hint && <ControlHint children={hint} />}
    </FormControl>
  );
};

export default FormSelectGroup;
