import css from './CampsButton.module.scss';
import {WithChildren} from "../../../../models/technical/withProps";
import {Link} from 'react-router-dom';

interface CampsButtonProps extends WithChildren {}

const CampsButton:React.FC<CampsButtonProps> = ({
    className
                                                }) => {

    return (
        <Link to={'/camps'} className={`${css.linkWrapper} ${className ? className : ''}`}>
            <div className={`${css.button}`}>
                <div className={css.img}></div>
                <div className={css.textWrapper}>
                    <div className={`${css.new} color-orange`}>Новинка!</div>
                    <div className={css.title}>Спортивные кемпы</div>
                </div>
            </div>
        </Link>
    );
};

export default CampsButton;