import { makeAutoObservable } from 'mobx';
import api from '../api';
import { FaqItem } from '../models/entities/faqItem';
import {RequestState} from "../models/responses/common";
import {FaqResponse} from "../models/responses/faqResponse";

class FAQStore {
  items?: FaqItem[] | null;
  selectedItem?: FaqItem | null;
  requestState: RequestState = 'initial';

  constructor() {
    makeAutoObservable(this);
  }

  async loadItems() {
    try {
      this.requestState = 'pending';
      const response = await api.faq.getItems();
      this.loadItemsDone(response.answers);
    }
    catch {
      this.loadItemsError();
    }
  }

  loadItemsDone(data: FaqResponse['answers']) {
    this.items = data;
    this.selectedItem = data[0];
    this.requestState = 'done';
  }

  loadItemsError() {
    this.requestState = 'error';
    this.items = null;
  }

  selectItem(title: string) {
    this.selectedItem = this.items?.find((item) => item.title === title);
  }

  reset() {
    this.items = null;
    this.requestState = 'initial';
    this.selectedItem = null;
  }
}

export default new FAQStore();
