import { observer } from 'mobx-react';
import css from '../ViewEventPage.module.scss';
import { useState } from 'react';
import Form from '../../../../Hoc/Form/Form';
import { useForm } from 'react-hook-form';
import { FormField } from '../../../../Hoc/Form/Field';
import PJInput from '../../../../UI/PartnerJoin/PJInput';
import EventViewStore from '../../../../../stores/EventViewStore';
import { SearchAccess } from '../../../../../enums/event';

export interface PartnerJoinFV {
  name: string;
  email: string;
}

function PartnerJoin() {

  const [open, setOpen] = useState(false);
  const form = useForm({ mode: 'onBlur' });

  return (
    <div
      className={`${css.join}`}
    >
      {open && (
        <div>
          <Form
            onSubmit={async (partner: PartnerJoinFV) => {
              await EventViewStore.joinForPartner(partner);
              form.reset();
              setOpen(false);
            }}
            formReturn={form}
          >
            <FormField
              Component={PJInput}
              name={'name'}
              placeholder={'Введи имя партнёра*'}
              rules={{ max: 30, required: true }}
            />
            <FormField
              Component={PJInput}
              name={'email'}
              placeholder={'Введи E-Mail партнёра*'}
              rules={{ required: true }}
            />
            <div className={css.joinPartnerWrapper}>
              <button
                type="submit"
                className={css.btn}
                style={{ marginTop: '23px' }}
              >
                {EventViewStore.data?.event.searchAccess === SearchAccess.private ? 'Пригласить партнёра' : 'Оплатить за партнера'}
              </button>
              {EventViewStore.data?.event.searchAccess !==
                SearchAccess.private && (
                <div className={css.price}>
                  Стоимость участия: <b>{EventViewStore.data?.event.cost}</b> ₽
                </div>
              )}
            </div>
          </Form>
        </div>
      )}
      {!open && (
          <button className={css.btn} onClick={() => setOpen(true)}>
            {EventViewStore.data?.event.searchAccess === SearchAccess.private ? 'Пригласить партнёра' : 'Оплатить за партнера'}
          </button>
      )}
    </div>
  );
}

export default observer(PartnerJoin);
