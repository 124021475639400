import { makeAutoObservable } from 'mobx';
import api from 'api';
import City from 'models/entities/city';
import { RequestState } from 'models/responses/common';
import UserResponse from 'models/responses/user';
import appStore from 'stores/AppStore';
import ModalStore from "./ModalStore";
import NotEmptyWallet from "../components/Pages/Profile/EditProfilePage/RemoveProfileModal/NotEmptyWallet";
import FutureEventsExistsAndNotEmptyWallet from "../components/Pages/Profile/EditProfilePage/RemoveProfileModal/FutureEventsExistsAndNotEmptyWallet";
import moment from "moment";
import {Timezone} from "models/entities/city";
import SearchPageStore from "./SearchPageStore";

enum RemoveProfileErrorCodes {
  future_events_exists = 'future_events_exists',
  not_empty_wallet = 'not_empty_wallet',
  future_events_exists_and_not_empty_wallet = 'future_events_exists_and_not_empty_wallet'
}

class UserStore {
  userData?: UserResponse;
  currentCity?: City;
  requestState: RequestState = 'initial';
  serverTimeOffset?: number;
  serverTimezone?: Timezone;

  constructor() {
    makeAutoObservable(this);
  }

  setCurrentCity = async (city: City) => {
    await api.city.setCity(city);
    this.currentCity = city;
    appStore.refreshApp();
  };

  async getUserData() {
    if (this.requestState === 'pending') return;
    this.requestState = 'pending';

    const homeRequest = api.home.initial();

    try {
      const data = await homeRequest;
      this.getUserDataDone(data);
    } catch (e) {
      console.log(e);
      this.getUserDataError();
    }
  }

  getCurrentTime(timezone?: Timezone) {
    const localTimezone = timezone || this.currentCity?.timezone;
    return moment().add({
      millisecond: this.serverTimeOffset,
      hour: (() => {
        if ((!localTimezone && localTimezone !== 0) || (!this.serverTimezone && this.serverTimezone !== 0)) return 0;
        else return localTimezone - this.serverTimezone;
      })()
    });
  }

  getUserDataDone(data: UserResponse) {
    this.userData = data;
    this.currentCity = data.city;
    this.serverTimeOffset = moment(data.serverTime).diff(new Date());
    this.serverTimezone = data.serverTimezone;
    this.requestState = 'done';
    SearchPageStore.setToday(this.getCurrentTime().startOf('day').toDate());
  };

  getUserDataError = () => {
    this.requestState = 'error';
    appStore.criticalError = true;
  };

  get canLoadApp() {
    return !!this.userData;
  }

  removeAvatar() {
    if (this.userData && this.userData.user) {
      this.userData.user.photo = null;
    }
  }

  handleVkPopupClose = async () => {
    if (!this.userData) return;
    this.userData.config.vkSocialPopup = false;
    await api.profile.updateUserFlags({ vkSocialPopup: false });
  };

  handleRemindPhonePopupClose = async () => { /* empty commit */
    if (!this.userData) return;
    this.userData.config.phonePopup = false;
    await api.profile.updateUserFlags({ phonePopup: false });
  };



  async removeProfile() {
    if (!this.userData?.user) return;
    try {
      await api.profile.removeProfile();
      if (this.userData) this.userData = undefined;
      appStore.refreshApp();
    }
    catch (e: any) {
      if (e.error?.response?.status === 422) {
        const errorCode = e.error.response.data.code;
        if (errorCode === RemoveProfileErrorCodes.future_events_exists) {
          ModalStore.showModal(
              <div>
                Для удаления ваших данных необходимо выйти из всех игр/тренировок.<br/><br/>
                Пожалуйста, проверьте свои события.
              </div>
          );
        }
        else if (errorCode === RemoveProfileErrorCodes.future_events_exists_and_not_empty_wallet) {
          ModalStore.showModal(<FutureEventsExistsAndNotEmptyWallet/>);
        }
        else if (errorCode === RemoveProfileErrorCodes.not_empty_wallet) {
          ModalStore.showModal(<NotEmptyWallet/>);
        }
      }
    }
  }

}

export default new UserStore();
