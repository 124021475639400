import css from './VkPopup.module.scss';
import vkIcon from './icon_vk.svg';
import closeIcon from './icon_close.svg';
import { useLayoutEffect } from 'react';
import { observer } from 'mobx-react';
import UserStore from '../../../stores/UserStore';

const VkPopup = observer(function () {

  useLayoutEffect(() => {
      document.documentElement.classList.add('vkPopupIsOpen');
    return () => {
        document.documentElement.classList.remove('vkPopupIsOpen');
    };
  }, []);

  return (
    <div className={css.vkPopupContainer}>
      <div className={css.text}>
        Вступай в нашу группу в Вконтакте и получи свою первую{' '}
        <span className={css.orangeText}>игру бесплатно</span>
      </div>
      <div className={css.buttonContainer}>
        <div
          className={css.buttonGoTo}
          onClick={() => {
            UserStore.handleVkPopupClose();
            window.open('https://vk.com/sploteamcom', '_blank');
          }}
        >
          Перейти в группу{' '}
          <img className={css.buttonIcon} src={vkIcon} alt="VK" />
        </div>
        <img
          className={css.buttonClose}
          src={closeIcon}
          alt="Закрыть"
          onClick={UserStore.handleVkPopupClose}
        />
      </div>
    </div>
  );
});

export default VkPopup;
