import useTabs from "../../../../../UI/Tabs/tabHook";
import css from "../Common.module.scss";
import Tab from "../../../../../UI/Tabs/Tab";
import TabPanel from "../../../../../UI/Tabs/TabPanel";
import React from "react";

type Tab = {
    tabIndex: number,
    title: string,
    content: React.ReactNode
}

interface InsideTabsProps {
    defaultTab: number,
    tabs: Tab[]
}

const InsideTabs:React.FC<InsideTabsProps> = ({
    defaultTab,
    tabs
                                              }) => {

    const { connect } = useTabs(defaultTab);

    return (
        <div className={css.container}>
            <div className={css.tabs}>
                {tabs.map(item =>
                    <Tab className={css.insideTab}{...connect(item.tabIndex)} key={item.tabIndex}>{item.title}</Tab>)
                }
            </div>
            <div className={css.content}>
                {tabs.map(item =>
                    <TabPanel {...connect(item.tabIndex)} key={item.tabIndex}>{item.content}</TabPanel>)
                }
            </div>
        </div>
    );
};

export default InsideTabs;