import commonCss from 'css/Common.module.css';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from "react-router";
import EventPayStore from '../../../stores/EventPayStore';
import BillingForm from '../shared/BillingForm/BillingForm';
import ControlHint from 'components/UI/DefaultFormUI/ControlHint/ControlHint';
import Button from '../../UI/Button/Button';
import { observer } from 'mobx-react';
import browserHistory from "../../../browserHistory";
import Page from "../../Hoc/Page/Page";
import css from './ErrorPaymentPage.module.scss';
import classNames from "classnames";

function ErrorPaymentPage() {
  const { orderId } = useParams<{ orderId: string }>();
  const [canPay, setCanPay] = useState(false);
  const { search } = useLocation();
  const checkStatusFailed = new URLSearchParams(search).get('checkStatusFailed');

  useEffect(() => {
    EventPayStore.getOrder(orderId);
    return () => EventPayStore.reset();
  }, [orderId]);

  return (
    <Page>
      <div className={classNames(
          commonCss.wrap,
          css.container
      )}>
          <div className={css.formWrapper}>
            <h1 className={commonCss.pageTitle}>{checkStatusFailed === '1' ? 'Не удалось проверить статус оплаты' : 'Ошибка оплаты заказа'}</h1>
              {EventPayStore.billingData?.order.id && EventPayStore.billingData?.order.cost && (
                  <ControlHint>{`Заказ № ${EventPayStore.billingData?.order.id} на сумму ${EventPayStore.billingData?.order.cost} рублей`}</ControlHint>
              )}
            {checkStatusFailed === '1'?
              <Button
                buttonText={'Подобрать игру'}
                onClick={() => browserHistory.push('/search')}
              />
              :
              <Button
                buttonText="Попробовать ещё раз"
                onClick={() => setCanPay(true)}
              />
            }
          </div>
      </div>
      <BillingForm
        payment={canPay ? EventPayStore.billingData?.payment : undefined}
      />
    </Page>
  );
}

export default observer(ErrorPaymentPage);
