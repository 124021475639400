import CooperationReqestData from '../models/requests/cooperation';
import HomeResponse from '../models/responses/home';
import UserResponse from '../models/responses/user';
import backendApi from 'api/config';
import SupportRequest from '../models/requests/support';

class HomeAPI {
  prefix = '/home/';
  async getHome(): Promise<HomeResponse> {
    const r = await backendApi.get(this.prefix);
    return r.data;
  }

  async sendCooperation(data: CooperationReqestData): Promise<boolean> {
    const r = await backendApi.post('/callbacks/cooperation/', data);
    return r.status === 200;
  }

  async sendSupport(data: SupportRequest): Promise<boolean> {
    const r = await backendApi.post('/callbacks/support/', data);
    return r.status === 200;
  }

  async initial(): Promise<UserResponse> {
    const r = await backendApi.get('initial/');
    return r.data;
  }
}

const homeAPI = new HomeAPI();
export default homeAPI;
