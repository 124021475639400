export class LocationUpdater {
  static pushPage(newLocation: Location | string) {
    // @ts-ignore
    window.location = newLocation;
  }

  static replaceLocation(newLocation: string) {
    window.history.replaceState(null, '', newLocation);
  }
}
