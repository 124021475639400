import React, { useCallback, useEffect, useState } from 'react';
import EventInfo from '../../../../../models/entities/eventInfo';
import User from '../../../../../models/entities/user';
import ProfileStore from '../../../../../stores/ProfileStore';
import EventCard from "../../../../common/EventCard/EventCard";
import Spinner from "../../../../UI/Loader/Loader";
import cssTabs from "../Tabs.module.scss";
import {SearchResultGrid} from "../../../Search/SearchResults/SearchResultGrid/SearchResultGrid";

type apiCall = (
  userId: User['id']
) => Promise<EventInfo[]>;

interface ArchiveProps {
  apiCall: apiCall;
  emptyText: string;
}

function Archive({
  apiCall,
  emptyText
}: ArchiveProps) {

  const [games, setGames] = useState<EventInfo[] | null>(null);
  const [pending, setPending] = useState<boolean>(false);

  const fetch = useCallback(async () => {
    setPending(true);
    const games = await apiCall(
      ProfileStore.profile?.id as User['id']
    );
    setGames(games);
    setPending(false);
  }, [apiCall]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  if (pending) return <Spinner className={cssTabs.spinner}/>

  if (!games || !games.length) return <div>{emptyText}</div>;

  return (
      <SearchResultGrid items={games.map((e) => <EventCard event={e} key={e.event.hash} />)}/>
  );
}

export default Archive;
