import {observer} from 'mobx-react';
import React from 'react';
import SearchPageStore from 'stores/SearchPageStore';
import {SearchResultGrid} from '../SearchResults/SearchResultGrid/SearchResultGrid';
import EventInfo from '../../../../models/entities/eventInfo';
import {Ad} from '../../../../models/entities/ad';
import {AdCard} from '../SearchResults/AdCard/AdCard';
import Spinner from "../../../UI/Loader/Loader";
import EventCard from "../../../common/EventCard/EventCard";
import CardType, {CardTypes} from "../../../../models/entities/cardType";

const GamesSection: React.FC = observer(() => {

  const items = SearchPageStore.getEventsWithAds();

  const loading = SearchPageStore.requestState === 'pending';

  if (loading) return <Spinner/>

  function renderItem(item: CardType<EventInfo | Ad>) {
    if (item.type === CardTypes.ad) {
      const adItem = item.data as Ad;
      return <AdCard ad={adItem} key={`ad-${adItem.id}`} />;
    }
    else if (item.type === CardTypes.event) {
      const eventItem = item.data as EventInfo;
      return <EventCard event={eventItem} key={eventItem.event.hash} />;
    }
  }

  return (
      <>
        {!!items.length ?
            <SearchResultGrid items={items.map((item) => renderItem(item))} /> :
            <div>Упс... на этот день нет ни одной игры, но Вы можете найти подходящую игру в другой день.</div>
        }
      </>
  );
});

export default GamesSection;
