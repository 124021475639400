import useOnClickOutside from 'components/common/hooks/onClickOutside';
import SelectDropdown from 'components/UI/Select/SelectDropdown';
import { onSelectChange, SelectOption } from 'models/entities/SelectOption';
import { CssModulesStyles } from 'models/technical/cssModules';
import React, {Dispatch, SetStateAction, useMemo, useRef, useState} from 'react';
import defaultStyles from 'components/UI/Select/Select.module.css';
import classNames from "classnames";

interface SwitcherProps extends Omit<SelectProps, 'className' | 'disabled' | 'styles'> {
  expanded: boolean;
  toggleDropdown: () => void;
  setExpanded: Dispatch<SetStateAction<boolean>>;
  styles: CssModulesStyles;
}

const SelectSwitcher: React.FC<SwitcherProps> = ({
  expanded,
  value,
  toggleDropdown,
  label,
  styles,
  options,
  setExpanded,
  onChange,
  withCrossIcon,
  notFlex
}) => {

  const refCrossIcon = useRef(null);

  const notFirstElActive = useMemo(() => {
    return options[0]?.id !== value?.id;
  }, [value, options]);

  const crossIconClick = () => {
    onChange(options[0]);
    setExpanded(false);
  }

  return (
    <div
      className={classNames(
          styles.control,
          expanded && styles.expanded,
          withCrossIcon && notFirstElActive && styles.notFirstElActive,
          withCrossIcon && styles.withCrossIcon,
          notFlex && styles.notFlex
      )}
      onClick={(e) => {
        if (refCrossIcon.current !== e.target) {
          toggleDropdown();
        }
      }}
    >
      {label && (
        <div
          className={`${styles.label} ${
            !!value?.name ? styles.labelActive : ''
          }`}
        >
          {label}
        </div>
      )}
      <div className={classNames(
          styles.controlValue
      )}>{value?.name || ''}</div>
      {withCrossIcon && <i ref={refCrossIcon} className={classNames(
          styles.crossIcon,
          notFirstElActive && styles.show
      )} onClick={crossIconClick}/>}
      <i className={styles.toggleIcon} />
    </div>
  );
};

export interface SelectProps {
  value: SelectOption<any> | undefined;
  options: SelectOption<any>[];
  onChange: onSelectChange;
  className?: string;
  disabled?: boolean;
  label?: string;
  styles?: CssModulesStyles;
  withCrossIcon?: boolean,
  notFlex?: boolean
}

const Select: React.FC<SelectProps> = ({
  value,
  onChange,
  className,
  options,
  disabled,
  label,
  styles,
  withCrossIcon,
  notFlex
}) => {
  const css = styles ? styles : defaultStyles;
  const ref = useRef<HTMLDivElement>(null);
  const [expanded, setExpanded] = useState(false);
  const toggleDropdown = () => setExpanded(!expanded);
  const onValueChange = (sort: SelectOption) => {
    if (disabled) return;
    onChange(sort);
    setExpanded(false);
  };
  useOnClickOutside(ref, () => setExpanded(false));
  return (
    <div ref={ref} className={classNames(
        css.select,
        expanded && css.expanded,
        disabled && css.disabled,
        className
    )}>
      <SelectSwitcher
        {...{
          expanded,
          value,
          toggleDropdown,
          onChange,
          label,
          styles: css,
          options,
          setExpanded,
          withCrossIcon,
          notFlex
        }}
      />
      {expanded && (
        <SelectDropdown
          options={options}
          onChange={onValueChange}
          value={value}
          styles={css}
        />
      )}
    </div>
  );
};

export default Select;
