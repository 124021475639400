import LKLayout from 'components/Pages/Profile/LKLayout/LKLayout';
import OrangeLink from 'components/UI/CSS/OrangeLink/OrangeLink';
import ProfileAvatar from 'components/UI/ProfileAvatar/ProfileAvatar';
import React, { useEffect } from 'react';
import UserStore from 'stores/UserStore';
import lkStyle from '../LKLayout/LKLayout.module.css';
import style from '../ProfilePage/ProfileCard/ProfileCard.module.scss';
import ModalStore from '../../../../stores/ModalStore';
import { AvatarUploadContainer } from './Avatar/avatarUpload';
import { observer } from 'mobx-react';
import ProfileStore from '../../../../stores/ProfileStore';
import { ProfileEditForm } from './Form/ProfileEditForm';
import ChangePasswordForm from './Form/ChangePasswordForm';
import ConfirmModal from "./RemoveProfileModal/ConfirmModal";

function AvatarUpload() {
  return <AvatarUploadContainer />;
}

const EditProfilePage = () => {

  const removeProfile = () => {
    ModalStore.showModal(<ConfirmModal/>);
  }

  useEffect(() => {
    ProfileStore.getProfile(UserStore.userData?.user?.id);
  }, []);
  if (!ProfileStore.profile) return null;
  return (
    <LKLayout>
      <div className={lkStyle.title}>Редактирование профиля</div>
      <div className={lkStyle.content}>
        <div className={style.cardContainer} style={{ gap: '65px' }}>
          <div className={style.cardItem} style={{ alignItems: 'center', minWidth: '175px' }}>
            <ProfileAvatar
              alt={UserStore.userData?.user?.name || ''}
              src={UserStore.userData?.user?.photo}
            />
            {ProfileStore.profile.photo ? (
              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <OrangeLink onClick={() => ModalStore.showModal(<AvatarUpload />)}>
                  Изменить фото
                </OrangeLink>
                <OrangeLink className={style.deleteButton} onClick={() => ProfileStore.removeAvatar()}>
                  Удалить фото
                </OrangeLink>
              </div>
            ) : (
              <OrangeLink onClick={() => ModalStore.showModal(<AvatarUpload />)}>
                Загрузить новое фото
              </OrangeLink>
            )}
            <OrangeLink className={style.removeProfile} onClick={removeProfile}>
              Удалить данные и профиль
            </OrangeLink>
          </div>
          <div className={style.cardItem} style={{ width: '342px' }}>
            <ProfileEditForm
              name={ProfileStore.profile.name}
              gender={ProfileStore.profile.gender}
              birthday={ProfileStore.profile.birthday}
              phone={ProfileStore.profile.phone}
            />
            <ChangePasswordForm />
          </div>
        </div>
      </div>
    </LKLayout>
  );
};

export default observer(EditProfilePage);
