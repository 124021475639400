import backendApi from 'api/config';
import {NotificationsConfig} from "../models/entities/notificationsConfig";
import {NotificationCounters, NotificationGroupCounters} from "../models/responses/notificationCounters";
import {PagedData} from "../models/responses/pagedData";
import PersonalNotification from "../models/entities/personalNotification";
import {PersonalNotificationGroup} from "../enums/notification";
import ChatNotification from "../models/entities/chatNotification";

class NotificationsAPI {
  async getConfig(): Promise<NotificationsConfig> {
    const r = await backendApi.get('/notifications/configuration/');
    return r.data;
  }

  async postConfig(config: NotificationsConfig) {
    await backendApi.post('/notifications/configuration/', config);
  }

  async getCounters(): Promise<NotificationCounters> {
    const r = await backendApi.get('/notifications/');
    return r.data;
  }

  async getGroupCounters(): Promise<NotificationGroupCounters> {
    const r = await backendApi.get('/notifications/groups/');
    return r.data;
  }

  async getPersonalNotifications(params: {group: PersonalNotificationGroup, page: number, pageSize: number}): Promise<PagedData<PersonalNotification>> {
    const r = await backendApi.get('/notifications/personal/', {params});
    return r.data;
  }

  async postPersonalNotifications(data: {group?: PersonalNotificationGroup, ids?: number[]}) {
    await backendApi.post('/notifications/personal/', data);
  }

  async deletePersonalNotifications(data: {group?: PersonalNotificationGroup, ids?: number[]}) {
    await backendApi.delete('/notifications/personal/', {data});
  }

  async getChatNotifications(params: {page: number, pageSize: number}): Promise<PagedData<ChatNotification>> {
    const r = await backendApi.get('/notifications/chat/', {params});
    return r.data;
  }

  async postChatNotifications(data: {ids?: number[]}) {
    await backendApi.post('/notifications/chat/', data);
  }

  async deleteChatNotifications(data: {ids?: number[]}) {
    await backendApi.delete('/notifications/chat/', {data});
  }
}

const notificationsAPI = new NotificationsAPI();

export default notificationsAPI;