import React from 'react';
import styles from './FAQButton.module.scss';
import { NavLink } from 'react-router-dom';

interface Props {}

interface State {}

export const FAQButton: React.FC<Props> = (props) => {
  return (
    <div className={styles.container}>
      <div className={styles.hint}>Вопросы и ответы</div>
      <NavLink to="/faq/" className={styles.button} />
    </div>
  );
};
