import { AxiosError } from 'axios';
import {
  formErrorTypeToMessageMap,
  formErrorCode,
} from 'components/Hoc/Form/errorCodes';
import ErrorResponse from 'models/responses/error';
import { Path, UnpackNestedValue } from 'react-hook-form';
import { UseFormReturn } from 'react-hook-form/dist/types';
import { UseFormSetError } from 'react-hook-form/dist/types/form';
import { SubmitError } from '../../../models/technical/form';

export const doSubmit =
  (onSubmit: any, form: UseFormReturn<any>) =>
  async (data: UnpackNestedValue<any>) => {
    try {
      await onSubmit(data);
    } catch (e) {
      console.log(e);
      catchAndShowFormErrors(e, form.setError);
    }
  };

const catchAndShowFormErrors = (e: any, setError: UseFormSetError<any>) => {
  if (e.isSubmitError) {
    const error = e as SubmitError;
    setError(error.key, {
      type: 'manual',
      message: formErrorTypeToMessageMap[error.value] || error.value,
    });
    return;
  }
  if (!e?.error?.isAxiosError) throw e;
  const error: AxiosError<ErrorResponse> = e.error;
  if (error.response?.data.code === formErrorCode) {
    for (const [key, value] of Object.entries(error.response.data.context)) {
      setError(key as Path<any>, {
        type: 'manual',
        message: formErrorTypeToMessageMap[value] || value,
      });
    }
    return;
  }
  if (
    error.response?.data.code &&
    Object.keys(formErrorTypeToMessageMap).includes(error.response?.data.code)
  ) {
    setError('formError' as Path<any>, {
      type: 'manual',
      message: formErrorTypeToMessageMap[error.response?.data.code],
    });
    return;
  }
  throw e;
};
