import css from './CityChangedAlertModal.module.scss';
import Button from "../../../../UI/Button/Button";
import UserStore from "../../../../../stores/UserStore";
import City from "../../../../../models/entities/city";
import browserHistory from "../../../../../browserHistory";
import ModalStore from "../../../../../stores/ModalStore";

interface CityChangedAlertModalProps {
    cityForChange: City
}

const CityChangedAlertModal:React.FC<CityChangedAlertModalProps> = ({
    cityForChange
                                                                    }) => {

    const confirm = () => {
        UserStore.setCurrentCity(cityForChange);
        ModalStore.hideModal();
    }

    const cancel = () => {
        browserHistory.push('/');
        ModalStore.hideModal();
    }

    const timezoneChanged = UserStore.currentCity?.timezone !== cityForChange.timezone;

    return (
        <div>
            <div className={css.text}>
                Данное событие находится в другом городе{timezoneChanged ? ' и часовом поясе' : ''}.<br/><br/>Город будет переключён.
            </div>
            <div className={css.buttons}>
                <Button buttonText={'Ок'} onClick={confirm}/>
                <Button buttonText={'Отмена'} onClick={cancel}/>
            </div>
        </div>
    );
}

export default CityChangedAlertModal;