import { useForm } from 'react-hook-form';
import Form from '../../../../Hoc/Form/Form';
import { FormField } from '../../../../Hoc/Form/Field';
import {
  FormInput,
  maskDateOnChange,
  MaskedInput,
  maskPhoneOnChange,
} from '../../../../UI/FormWithLabelsUI/FormInput/FormInput';
import Select from '../../../../UI/Select/Select';
import graySelectStyle from '../../../../UI/FormWithLabelsUI/Select/Select.module.css';
import Button from '../../../../UI/Button/Button';
import React, { useRef, useEffect } from 'react';
import { SelectOption } from '../../../../../models/entities/SelectOption';
import moment from 'moment';
import ProfileStore from '../../../../../stores/ProfileStore';
import { EditProfileRequestData } from '../../../../../models/requests/editProfile';
import { AsYouType } from 'libphonenumber-js';
import ModalStore from "../../../../../stores/ModalStore";

const man: SelectOption = { id: 1, name: 'Мужской' };
const woman: SelectOption = { id: 2, name: 'Женский' };
const genders: Record<string, SelectOption> = { man, woman };

const genderOptions: SelectOption[] = [man, woman];

function getGender(option?: SelectOption) {
  if (!option) return null;
  switch (option.id) {
    case 1:
      return 'man';
    case 2:
      return 'woman';
    default:
      return null;
  }
}

interface ProfileEditFormValues {
  name: string;
  gender: SelectOption;
  birthday: string;
  phone: string;
}

function normailize({
  name,
  gender,
  birthday,
  phone,
}: ProfileEditFormValues): EditProfileRequestData {
  const phoneNormalizer = new AsYouType('RU');
  phoneNormalizer.input(phone);
  return {
    name,
    birthday: !birthday ? '' : moment(birthday, 'DD.MM.YYYY').format('YYYY-MM-DD'),
    phone: (phoneNormalizer.getNumber()?.number as string) || '',
    gender: getGender(gender),
  };
}

const birthdayValidate = (value: string) => {
  return value.length === 0 ||
    moment(value, 'DD.MM.YYYY') > moment('01.01.1900', 'DD.MM.YYYY') &&
    moment(value, 'DD.MM.YYYY') < moment(Date.now());
};

const SuccessEditModal: React.FC = () => {
  return (
    <p style={{textAlign: 'center'}}>Ваш профиль успешно обновлён</p>
  );
};

export function ProfileEditForm(props: {
  name: string;
  gender: string | null;
  birthday: string | null;
  phone: string;
}) {
  const { name, birthday, phone, gender } = props;
  const form = useForm({
    defaultValues: {
      name: name || '',
      gender: gender ? genders[gender] : '',
      birthday: birthday ? moment(birthday).format('DD.MM.YYYY') : '',
      phone: phone ? new AsYouType('RU').input(phone) : '',
    },
  });

  const phoneRef = useRef(null);

  useEffect(() => {
    if (!phone) {
      // @ts-ignore
      phoneRef.current.focus();
    }
  }, []);

  return (
    <Form
      formReturn={form}
      onSubmit={async (data: ProfileEditFormValues) => {
        await ProfileStore.editProfile(normailize(data));
        ModalStore.showModal(<SuccessEditModal/>);
      }}
    >
      <FormField
        Component={FormInput}
        label="Ваше имя"
        name="name"
        rules={{ required: 'Выберите значение' }}
      />
      <FormField
        Component={Select}
        label="Пол"
        name="gender"
        styles={graySelectStyle}
        options={genderOptions}
      />
      <FormField
        Component={MaskedInput}
        label="Дата рождения ДД.ММ.ГГГГ"
        name="birthday"
        maskOnChange={maskDateOnChange}
        rules={{validate: birthdayValidate}}
      />
      <FormField
        Component={MaskedInput}
        maskOnChange={maskPhoneOnChange}
        label="Номер телефона"
        name="phone"
        inputRef={(e) => phoneRef.current = e}
      />
      <Button
        type="submit"
        buttonText="Сохранить изменения"
        disabled={form.formState.isSubmitting}
      />
    </Form>
  );
}
