import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import LoginForm from 'components/Pages/shared/LoginForm/LoginForm';
import React from 'react';
import { isIE } from 'react-device-detect';
import ModalStore from 'stores/ModalStore';
import { DOMAIN, PROD } from 'configuration';
import { apiErrorTypeToMessageMap } from '../components/Hoc/Form/errorCodes';

const cookie = require('cookie');

let config: AxiosRequestConfig = {
  withCredentials: true,
  baseURL: `${DOMAIN}/api/`,
  timeout: 30000,
  transformRequest: (data, headers) => {
    const csrftoken = cookie.parse(document.cookie).csrftoken;
    headers['X-CSRFToken'] = csrftoken || null;
    if (headers['custom']) {
      delete headers['custom'];
      return data;
    }
    if (!headers['Content-Type']) {
      headers['Content-Type'] = 'application/json';
    }
    return JSON.stringify(data);
  },
};

if (!PROD) {
  config.auth = {
    username: process.env.REACT_APP_AUTH_USERNAME || '',
    password: process.env.REACT_APP_AUTH_PASSWORD || ''
  };
}

export const backendApi = axios.create(config);

if (isIE) {
  backendApi.defaults.headers['Pragma'] = 'no-cache';
}

export const errorHandler = (error: AxiosError) => {
  if (error.config && error.config.data && JSON.parse(error.config.data).disabledErrorHandler) { /* если в теле запроса был флаг disabledErrorHandler, значит не нужно выкидывать модалку об ошибке если что-то пошло не так */
    return Promise.reject({ error });
  }
  if (axios.isCancel(error)) {
    return Promise.resolve({ error });
  } else if (
    error.response &&
    error.response.status >= 401 &&
    error.response.status <= 403
  ) {
    ModalStore.showModal(<LoginForm />);
  }
  else if (error.response?.status === 422 && apiErrorTypeToMessageMap[error.response?.data?.code]) {
    showAlert(error);
  }
  else if (!error.response || error.response.status !== 422) {
    showAllBad();
  }
  return Promise.reject({ error });
};

function showAlert(error: AxiosError) {
  ModalStore.showModal(
    <div>
      <p>{apiErrorTypeToMessageMap[error.response?.data?.code]}</p>
      {error.response?.data?.context?.message && (
        <p>{error.response?.data?.context.message}</p>
      )}
    </div>
  );
}

function showAllBad() {
  ModalStore.showModal(
    <p>К сожалению, что-то пошло не так.<br/>Мы уже работаем над этой проблемой и скоро все восстановится.<br/><br/>Попробуйте обновить страницу.</p>
  );
}

export const successHandler = (response: AxiosResponse) => {
  // const config: AxiosRequestConfig = response.config;
  // if (config.callback) {
  //   config.callback(response.data);
  // }
  return response;
};

backendApi.interceptors.response.use(
  (response: AxiosResponse) => successHandler(response),
  (error: AxiosError) => errorHandler(error)
);

export default backendApi;
