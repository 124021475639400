import CheckboxData from "../../../../../models/ui/form/checkboxData";

type StateKey = number | string;
type StateValue = CheckboxData | CheckboxData[] | string;
type OnChangeProps = (value: StateValue, restObj?: any) => void;

interface QuizOption {
  id: number,
  text: string | null
}

const getNormalizedOptions = (options: QuizOption[]):CheckboxData[] => options.map((option) => ({ value: option.id, label: option.text }));


export type { StateKey, StateValue, OnChangeProps, QuizOption };
export { getNormalizedOptions };

