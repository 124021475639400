import { DOMAIN } from 'configuration';
import React from 'react';
import ModalStore from "../../../../stores/ModalStore";
import Button from "../../../UI/Button/Button";
import LoginForm from "./LoginForm";

const socialLink = (socialName: string) =>
  `${DOMAIN}/api/social/login/${socialName}/?next=${encodeURI(
    window.location.origin
  )}`;

const FacebookAlert:React.FC = () => {
  return (
    <div>
      <p>Уважаемый пользователь,на сегодняшний день невозможно авторизоваться в сервисе с помощью учетной записи Facebook.<br/><br/>Пожалуйста воспользуйтесь другим типом авторизации. Если ранее вы использовали аккаунт связанный с Facebook, то напишите нам в поддержку. Мы перенесем вашу историю в Сплотиме на  новый аккаунт</p>
      <Button buttonText={'ОК'} onClick={() => {
        ModalStore.showModal(<LoginForm/>);
      }}/>
    </div>
  );
};

const SignInSocial = () => {
  return (
    <div className="signIn__social-list">
      <a href={socialLink('vk-oauth2')} className="signIn__social-link">
        <i className="social-icon social-icon_vk" />
      </a>
      <div onClick={() => {
        ModalStore.showModal(<FacebookAlert/>);
      }} className="signIn__social-link">
        <i className="social-icon social-icon_fb" />
      </div>
      <a href={socialLink('google-oauth2')} className="signIn__social-link">
        <i className="social-icon social-icon_google" />
      </a>
      <a href={socialLink('apple-id')} className="signIn__social-link">
        <i className="social-icon social-icon_apple" />
      </a>
    </div>
  );
};

export default SignInSocial;
