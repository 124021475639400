import backendApi from './config';
import {FaqResponse} from "../models/responses/faqResponse";

class FaqAPI {
  async getItems(): Promise<FaqResponse> {
    const r = await backendApi.get('/content/faq/');
    return r.data;
  }
}

const faqAPI = new FaqAPI();

export default faqAPI;
