import CampsSearchPageStore from "../../../../../stores/CampsSearchPageStore";
import {onSelectChange} from "../../../../../models/entities/SelectOption";
import Select from "../../../../UI/Select/Select";
import React from "react";
import {observer} from "mobx-react";

const CountriesFilter:React.FC = () => {

    const onChangeCountries:onSelectChange = (option) => {
        CampsSearchPageStore.setCountryFilter(option.id === 0 ? null : option.id, null);
    }

    const onChangeRegions:onSelectChange = (option) => {
        CampsSearchPageStore.setLocationFilter(option.id === 0 ? null : option.id);
    }

    return (
        <>
            <Select
                value={CampsSearchPageStore.getCountryFilter()}
                options={CampsSearchPageStore.getCountriesOptions()}
                onChange={onChangeCountries}
            />
            <Select
                disabled={!CampsSearchPageStore.countryFilter}
                value={CampsSearchPageStore.getLocationFilter()}
                options={CampsSearchPageStore.getLocationsOptions()}
                onChange={onChangeRegions}
            />
        </>
    );
};

export default observer(CountriesFilter);