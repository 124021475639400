import browserHistory from 'browserHistory';
import { FormField } from 'components/Hoc/Form/Field';
import Form from 'components/Hoc/Form/Form';
import ExitButton from 'components/Pages/Profile/ProfilePage/ExitButton';
import Button from 'components/UI/Button/Button';
import OrangeLink from 'components/UI/CSS/OrangeLink/OrangeLink';
import { FormInput } from 'components/UI/FormWithLabelsUI/FormInput/FormInput';
import ProfileAvatar from 'components/UI/ProfileAvatar/ProfileAvatar';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import style from './ProfileCard.module.scss';
import ProfileStore from '../../../../../stores/ProfileStore';
import ProfileSocial from './ProfileSocial';
import BillingForm from '../../../shared/BillingForm/BillingForm';
import { BILLING_MAX_RUB, BILLING_MIN_RUB } from '../../../../../configuration';
import EventPayStore from "../../../../../stores/EventPayStore";
import classNames from "classnames";
import Spinner from "../../../../UI/Loader/Loader";
import cssTabs from '../Tabs.module.scss';
import Checkbox from "../../../../UI/Checkbox/Checkbox";
import Loader from "../../../../UI/Loader/Loader";

function ProfileCard() {

  const form = useForm({ defaultValues: { amount: '' } });
  const [showDepositForm, setShowDepositForm] = useState(false);
  const amount = form.watch('amount');
  const userRemoved = ProfileStore.profile?.removed;
  const pending = ProfileStore.requestState === 'pending';
  if (pending) return  <Spinner className={cssTabs.spinner}/>
  if (!ProfileStore.profile) return null;

  return (
    <div className={style.cardContainer}>
      <div className={style.cardItem}>
        <ProfileAvatar
          alt={ProfileStore.profile.name || ''}
          src={ProfileStore.profile.photo}
          noPhoto={userRemoved}
        />
      </div>
      <div className={classNames(
          style.cardItem,
          userRemoved && style.userRemoved
      )}>
          {!userRemoved ? (
              <>
                  <div className={style.name}>
                      <b>{ProfileStore.profile.name}</b>
                  </div>
                  <div>
                      Игр сыграно: <b>{ProfileStore.profile.gamesPlayed}</b>
                  </div>
                  <div className={style.contacts}>
                      <span>{ProfileStore.profile.phone}</span>
                      <span>{ProfileStore.profile.email}</span>
                  </div>
                  {ProfileStore.isOwnProfile && (
                      <>
                          <ProfileSocial />
                          <OrangeLink onClick={() => browserHistory.push('/cabinet/edit')}>
                              Редактировать
                          </OrangeLink>
                      </>
                  )}
              </>
          ) : (
                <div className={style.removed}>Игрок удалён</div>
          )}
      </div>
      {ProfileStore.isOwnProfile && (
        <div className={`${style.cardItem} ${style.deposit}`}>
          <div>
            Личный счёт: <b>{ProfileStore.profile.balance} ₽</b>
          </div>

            <div className={style.refundsRow}>
                <Checkbox
                    onChange={(data, isChecked) => {
                        ProfileStore.updateRefundsOnlyToDeposit(isChecked);
                    }}
                    label={'Возвращать денежные средства на личный счет'}
                    checked={ProfileStore.profile.refundsOnlyToDeposit}
                    value={'refundToAccount'}
                    disabled={ProfileStore.requestStateUpdateConfig === 'pending'}
                />
                <Loader
                    className={classNames(
                        style.refundsLoader,
                        ProfileStore.requestStateUpdateConfig === 'pending' && style.active
                    )}
                    text={''}
                />
            </div>

            <div className={style.depositPayment}>
                {showDepositForm ? (
                    <Form
                        formReturn={form}
                        onSubmit={async (data: any) => {
                            await ProfileStore.getDepositPaymentData(data.amount);
                        }}
                        disabled={EventPayStore.billingFormSubmitting}
                    >
                        <FormField
                            Component={FormInput}
                            label="Сумма пополнения"
                            name="amount"
                        />
                        <Button
                            type="submit"
                            disabled={
                                Number(amount) < BILLING_MIN_RUB ||
                                Number(amount) > BILLING_MAX_RUB
                            }
                            buttonText="Пополнить"
                        />
                    </Form>
                ) : (
                    <Button
                        buttonText="Пополнить счет"
                        className="header__signIn"
                        onClick={() => setShowDepositForm(true)}
                    />
                )}
                <BillingForm payment={ProfileStore.billingData?.payment} />
            </div>


        </div>
      )}
      <ExitButton mobile />
    </div>
  );
}

export default observer(ProfileCard);
