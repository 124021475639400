import 'cropperjs/dist/cropper.css';
import * as React from 'react';
import Cropper from 'react-cropper';
import { base64string } from '../../../../../models/entities/image';
import Button from '../../../../UI/Button/Button';
import s from './css/avatar.module.css';

interface AvatarCropPresenterProps {
  cancelCrop: () => void;
  confirmCrop: (a: any) => void;
  originalImage?: base64string;
  extension?: string;
  croppedImage?: HTMLCanvasElement;
  setCropToState: (c: HTMLCanvasElement) => void;
}

export class AvatarCropPresenter extends React.Component<
  AvatarCropPresenterProps,
  any
> {
  cropper?: Cropper;

  public render() {
    const preview = this.props.croppedImage
      ? this.props.croppedImage.toDataURL(this.props.extension)
      : this.props.originalImage;
    return (
      <div className={s.avatarModal}>
        <div className={s.avatarCropPreview}>
          <span className="cropPreviewImg">
            <img src={preview} alt="Превью" />
          </span>
          <span className={s.avatarCropHelpText}>
            Пожалуйста, выберите часть изображения, которую Вы хотите
            использовать в качестве аватара.
          </span>
        </div>
        {this.renderCropper()}
        <div className={s.avatarBtnGroup}>
          <Button buttonText={'Отменить'} onClick={this.props.cancelCrop} />
          <br />
          <Button buttonText={'Подтвердить'} onClick={this.confirmWrap} />
        </div>
      </div>
    );
  }

  public confirmWrap = () => {
    if (!this.cropper) return;
    this.props.confirmCrop(this.cropper.getCroppedCanvas());
  };

  onCropperInit = (cropper: Cropper) => {
    this.cropper = cropper;
  };

  private renderCropper() {
    return (
      <Cropper
        src={this.props.originalImage}
        onInitialized={this.onCropperInit}
        className={s.avatarCropContainer}
        preview=".cropPreviewImg"
        aspectRatio={1}
        width={300}
        height={300}
        viewMode={2}
        guides={false}
        minCropBoxHeight={50}
        minCropBoxWidth={50}
      />
    );
  }
}
