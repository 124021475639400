import React from "react";
import styles from "./NotificationsHeader.module.scss";
import {FlatOrangeCheckbox} from "../../../../../UI/Checkbox/FlatOrangeCheckbox";
import store from "../../../../../../stores/NotificationsSectionStore";
import {observer} from "mobx-react";
import CheckboxData from "../../../../../../models/ui/form/checkboxData";
import NotificationWidgetsStore from "../../../../../../stores/NotificationWidgetsStore";


const NotificationsHeader: React.FC = () => {

    const labelText = () => store.allNotificationsSelected ? 'Снять выделение' : 'Выделить все';

    const getAllSelectedSize = () => store.allNotificationsSelected ? store.pageData?.count : store.selectedSet.size;

    const onCheckboxChange = (data: CheckboxData, isChecked: boolean) => {
        if (isChecked) {
            store.selectAll();
            return;
        }
        store.resetSelection();
    }

    const onRead = () => {
        (async () => {
            await store.readSelectedNotifications();
            await store.fullReload();
            await NotificationWidgetsStore.loadCounters();
        })();
    };

    const onDelete = () => {
        (async () => {
            await store.deleteSelectedNotifications();
            await store.fullReload();
            await NotificationWidgetsStore.loadCounters();
        })();
    }

    const buttons = () => {
        if (!store.hasSelectedNotifications()) {
            return null;
        }

        return (
            <div className={styles.buttons}>
                <div
                    className={`${styles.button} ${styles.readButton}`}
                    onClick={onRead}
                >
                    Прочитать
                </div>
                <div
                    className={`${styles.button} ${styles.deleteButton}`}
                    onClick={onDelete}
                >
                    Удалить
                </div>
            </div>
        )
    };

    return (
        <div className={styles.header}>
            <div className={styles.selectors}>
                <div className={styles.selected}>Выделено: <span className={styles.selected__value}>{getAllSelectedSize()}</span></div>
                <FlatOrangeCheckbox
                    label={labelText()}
                    checked={store.allNotificationsSelected}
                    onChange={onCheckboxChange}
                    value=""
                />
            </div>
            {buttons()}
        </div>
    )
}

export default observer(NotificationsHeader);