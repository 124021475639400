import React from "react";
import styles from "./NotificationsFooter.module.css";
import {Paginator} from "../../../../../UI/Paginator/Paginator";
import store from "../../../../../../stores/NotificationsSectionStore";
import {observer} from "mobx-react";


type Props = {
};

type State = {
};

const NotificationsFooter: React.FC<Props> = (props) => {
    const isDesktopVersion = window.matchMedia("(min-width: 840px)").matches;

    const onPageChange = (page: number) => {
        store.loadPage(page, true);
    };

    if (!store.pageData!.results.length) {
        return null;
    }

    const numberOfPages = Math.ceil(store.pageData!.count / store.pageData!.pageSize);

    return (
        <div className={styles.footer}>
            <div className={styles.paginatorContainer}>
                <Paginator
                    startCountingPagesFromOne={true}
                    currentPage={store.pageData!.pageNumber}
                    numberOfPages={numberOfPages}
                    displayedRange={isDesktopVersion ? 6 : 5}
                    onChange={onPageChange}
                />
            </div>
        </div>
    )
}

export default observer(NotificationsFooter);