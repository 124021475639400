import { makeAutoObservable } from 'mobx';
import moment from 'moment';
import {applyQuery, getQuery} from 'utils/urlConstructors';
import {LocationUpdater} from "../utils/locationUpdater";
import api from "../api";
import {CampsFiltersResp, CampsEventsResp} from "../models/responses/camps";
import {RequestState} from "../models/responses/common";
import {SelectOption} from "../models/entities/SelectOption";
import UserStore from "./UserStore";

export type Year = number;
export type Month = number | null;
export type OrganizerId = number | null;
export type SportId = number | null;
export type LocationId = number | null;
export type CountryId = number | null;
export type PaginationPage = number | null;

enum FilterQuery {
  month = 'month',
  year = 'year',
  organizer = 'organizer',
  sport = 'sport',
  location = 'location',
  country = 'country'
}

interface CommonFilterProps {
  key: string,
  data: any,
  query: FilterQuery
}

export const defaultFilterSport:SelectOption = {
  id: 0,
  name: 'Все виды спорта'
};

export const defaultFilterOrganizer:SelectOption = {
  id: 0,
  name: 'Все организаторы'
};

export const defaultFilterCountries:SelectOption = {
  id: 0,
  name: 'Все страны'
};

export const defaultFilterRegion:SelectOption = {
  id: 0,
  name: 'Все регионы'
};

class CampsSearchPageStore {
  constructor() {
    this.month = this.getMonth();
    this.year = this.getYear();
    this.organizerFilter = this.getOrganizer();
    this.sportFilter = this.getSport();
    this.locationFilter = this.getLocation();
    this.countryFilter = this.getCountry();
    makeAutoObservable(this);
  }
  requestState: RequestState = 'initial';
  month: Month;
  year: Year;

  filters?: CampsFiltersResp | null;
  events?: CampsEventsResp | null;
  organizerFilter: OrganizerId;
  sportFilter: SportId;
  locationFilter: LocationId;
  countryFilter: CountryId;

  page?: PaginationPage

  getDefaultMonth() {
    return null;
  }
  getMonth() {
    return getQuery(FilterQuery.month) ? Number(getQuery(FilterQuery.month)) : this.getDefaultMonth();
  }
  getDefaultYear() {
    return UserStore.getCurrentTime().year();
  }
  getYear() {
    return getQuery(FilterQuery.year) ? Number(getQuery(FilterQuery.year)) : this.getDefaultYear();
  }
  getDefaultOrganizer() {
    return null;
  }
  getOrganizer() {
    return getQuery(FilterQuery.organizer) ? Number(getQuery(FilterQuery.organizer)) : this.getDefaultOrganizer();
  }
  getDefaultSport() {
    return null;
  }
  getSport() {
    return getQuery(FilterQuery.sport) ? Number(getQuery(FilterQuery.sport)) : this.getDefaultSport();
  }
  getDefaultLocation() {
    return null;
  }
  getLocation() {
    return getQuery(FilterQuery.location) ? Number(getQuery(FilterQuery.location)) : this.getDefaultLocation();
  }
  getDefaultCountry() {
    return null;
  }
  getCountry() {
    return getQuery(FilterQuery.country) ? Number(getQuery(FilterQuery.country)) : this.getDefaultCountry();
  }

  setCommonFilter(data: CommonFilterProps) {
    // @ts-ignore
    this[data.key] = data.data;
    this.resetPage();
    window.scrollTo(0, 0);
    this.getEvents(true);
    LocationUpdater.replaceLocation(
        applyQuery(
            data.query,
            data.data ? String(data.data) : null
        )
    );
  }

  setYearFilter(date: Year) {
    this.year = date;
    this.resetPage();
    this.getEvents(true);
    LocationUpdater.replaceLocation(
        applyQuery(
            FilterQuery.year,
            String(date)
        )
    );
  }
  setMonthFilter(data: Month) {
    this.setCommonFilter({
      key: 'month',
      data,
      query: FilterQuery.month
    });
  }

  async getFilters() {
    try {
      const r = await api.camps.getFilters();
      this.getFiltersDone(r);
    }
    catch (e) {
      console.log(e);
    }
  }

  getFiltersDone(data: CampsFiltersResp) {
    this.filters = data;
  };

  async getEvents(reset?: boolean) {
    if (reset) this.events = null;
    try {
      this.requestState = "pending";
      const r = await api.camps.getEvents({
        year: this.year,
        month: this.month,
        organizer: this.organizerFilter,
        sport: this.sportFilter,
        location: this.locationFilter,
        country: this.countryFilter,
        page: this.page
      });
      this.getEventsDone(r);
    }
    catch (e) {
      this.events = null;
      this.requestState = "error";
      console.log(e);
    }
  }

  getEventsDone(data: CampsEventsResp) {
    this.requestState = "done";
    if (!this.page || this.page === 1) this.events = data;
    else this.events = {
      hasMore: data.hasMore,
      results: [
          ...this.events!.results,
          ...data.results
      ]
    }
  };

  setOrganizerFilter(data: OrganizerId) {
    this.setCommonFilter({
      key: 'organizerFilter',
      data,
      query: FilterQuery.organizer
    });
  }

  setSportFilter(data: SportId) {
    this.setCommonFilter({
      key: 'sportFilter',
      data,
      query: FilterQuery.sport
    });
  }

  setCountryFilter(country: CountryId, location: LocationId) {
    this.countryFilter = country;
    this.locationFilter = location;
    this.resetPage();
    window.scrollTo(0, 0);
    this.getEvents(true);
    LocationUpdater.replaceLocation(
        applyQuery(
            FilterQuery.country,
            country ? String(country) : null
        )
    );
    LocationUpdater.replaceLocation(
        applyQuery(
            FilterQuery.location,
            location ? String(location) : null
        )
    );
  }

  setLocationFilter(data: LocationId) {
    this.setCommonFilter({
      key: 'locationFilter',
      data,
      query: FilterQuery.location
    });
  }

  setPage(data: PaginationPage) {
    this.page = data;
  }

  getNextPage() {
    const nextPage =
        !this.page || this.page === 1 ?
        this.page = 2 :
        this.page + 1;
    this.setPage(nextPage);
    this.getEvents();
  }

  resetPage() {
    this.page = null;
  }

  resetData() {
    this.events = null;
    this.requestState = 'initial';
    this.resetFilters();
    this.resetPage();
  }

  resetFilters() {
    this.organizerFilter = this.getDefaultOrganizer();
    this.locationFilter = this.getDefaultLocation();
    this.month = this.getDefaultMonth();
    this.year = this.getDefaultYear();
    this.countryFilter = this.getDefaultCountry();
  }

  getSportFilter() {
    if (!this.sportFilter) return defaultFilterSport;
    else return this.filters?.sports.find(item => item.id === this.sportFilter);
  }

  getSportOptions() {
    return this.filters?.sports ? [defaultFilterSport, ...this.filters?.sports] : [];
  }

  getOrganizerFilter() {
    if (!this.organizerFilter) return defaultFilterOrganizer;
    else return this.filters?.organizers.find(item => item.id === this.organizerFilter);
  }

  getOrganizerOptions() {
    return this.filters?.organizers ? [defaultFilterOrganizer, ...this.filters?.organizers] : [];
  }

  getCountryFilter() {
    if (!this.countryFilter) return defaultFilterCountries;
    else return this.getCountriesOptions().find(item => item.id === this.countryFilter);
  }

  getCountriesOptions() {
    return this.filters?.locationsByCountries ? [defaultFilterCountries, ...this.filters?.locationsByCountries.map(item => ({
      id: item.country.id,
      name: item.country.name
    }))] : [];
  }

  getLocationFilter() {
    if (!this.locationFilter) return defaultFilterRegion;
    else return this.getLocationsOptions().find(item => item.id === this.locationFilter);
  }

  getLocationsOptions() {
    const countryValue = this.getCountryFilter();
    const targetLocations = countryValue && this.filters?.locationsByCountries.find(item => item.country.id === countryValue.id)?.locations;
    return targetLocations ?
        [defaultFilterRegion, ...targetLocations] :
        [defaultFilterRegion];
  }

  get activeFiltersCount() {
    return [
      this.sportFilter,
      this.locationFilter,
      this.countryFilter,
      this.organizerFilter
    ].filter(item => !!item).length
  }

  allFiltersCleaning() {
    this.sportFilter = null;
    this.locationFilter = null;
    this.countryFilter = null;
    this.organizerFilter = null;
  }

}

export default new CampsSearchPageStore();
