import FormInputProps from 'models/ui/form/formInputProps';
import React, { FocusEventHandler, useState } from 'react';
import defaultCss from 'components/UI/FormWithLabelsUI/FormInput/FormInput.module.css';
import { AsYouType } from 'libphonenumber-js';
import { onChangeType } from '../../../../models/technical/form';

export function BaseInput({
  setValue,
  label,
  onFocus,
  onBlur,
  inputRef,
  className,
  styles,
  ...rest
}: FormInputProps) {
  const [isFocused, setFocused] = React.useState(false);
  const _onFocus: FocusEventHandler<HTMLInputElement> = (e) => {
    setFocused(true);
    onFocus && onFocus(e);
  };
  const _onBlur: FocusEventHandler<HTMLInputElement> = () => {
    setFocused(false);
    onBlur && onBlur();
  };

  const css = styles ? styles : defaultCss;

  return (
    <>
      {label && (
        <label
          className={`${css.formInputLabel} ${
            isFocused || rest.value ? `${css.formInputLabel_active}` : ''
          }`}
        >
          {label}
        </label>
      )}
      <input
        className={css.formInputField}
        ref={inputRef}
        {...rest}
        onBlur={_onBlur}
        onFocus={_onFocus}
      />
    </>
  );
}


function ClosableInput(props: FormInputProps) {
  const css = props.styles ? props.styles : defaultCss;
  return (
    <>
      <BaseInput {...props} />
      {props.value && (
        <span
          className={css.formInputClear}
          onClick={() => props.setValue(props.name, '')}
        />
      )}
    </>
  );
}



function PasswordInput(props: FormInputProps) {
  const [showPassword, setShowPassword] = useState(false);
  const css = props.styles ? props.styles : defaultCss;
  return (
    <>
      <BaseInput {...props} type={showPassword ? 'text' : 'password'} />
      {props.value && (
        <span
          className={
            showPassword ? css.togglePassVisibleShow : css.togglePassVisibleHide
          }
          onClick={() => setShowPassword(!showPassword)}
        />
      )}
    </>
  );
}

export const FormInput = <T extends any>(props: FormInputProps<T>) => {
  const css = props.styles ? props.styles : defaultCss;
  return (
    <div
      className={`${css.formInput} ${props.className || ''} ${
        props.error && props.showErrorInComponent && css.inputError
          ? css.inputError
          : ''
      }`}
    >
      {props.type === 'password' ? (
        <PasswordInput {...props} />
      ) : (
        <ClosableInput {...props} />
      )}
    </div>
  );
};

export function maskPhoneOnChange(onChange: onChangeType) {
  const change: (...event: any[]) => void = (e) => {
    if (!e) return e;
    const formatter = new AsYouType('RU');
    const parsedValue = formatter.input(e.target.value);

    let maxLength = 11;
    if (parsedValue.startsWith('+')) {
      maxLength = 12;
    }
    if (formatter.getChars().length > maxLength) return;
    if (parsedValue.slice(0, -1) !== e.target.value) {
      e.target.value = parsedValue;
    }

    onChange(e);
  };
  return change;
}

export function maskDateOnChange(onChange: onChangeType) {
  const change: (...event: any[]) => void = (e) => {
    if (!e) return e;
    const trimmedValue = e.target.value.replaceAll('.', '');
    const isLastDot = e.target.value.slice(-1) === '.';
    let isnum = /^\d+$/.test(trimmedValue);
    if (!isnum) {
      if (e.target.value === '') {
        onChange('');
      }
      return;
    }
    const dateArray = [];
    if (trimmedValue.length < 2) {
      dateArray.push(trimmedValue);
    } else {
      dateArray.push(trimmedValue.slice(0, 2));
      dateArray.push(trimmedValue.slice(2, 4));
      dateArray.push(trimmedValue.slice(4, 8));
    }
    if (
      dateArray[2] > new Date().getFullYear() ||
      dateArray[1] > 12 ||
      dateArray[0] > 31
    ) {
      return;
    }
    const formattedString = dateArray.filter((v) => v !== '').join('.');
    onChange(isLastDot ? formattedString + '.' : formattedString);
  };
  return change;
}

export const MaskedInput = <T extends any>(
  props: FormInputProps<T> & {
    maskOnChange: (onChange: onChangeType) => onChangeType;
  }
) => {
  const { maskOnChange, onChange, ...rest } = props;
  const formattedProps: FormInputProps<T> = {
    onChange: maskOnChange(onChange),
    ...rest,
  };
  return <FormInput {...formattedProps} />;
};
