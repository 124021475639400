import React, { useEffect, useRef } from 'react';
import BillingData from '../../../../models/entities/payment';
import { simulateMouseClick } from '../../../../utils/functions';
import EventPayStore from "../../../../stores/EventPayStore";

interface Props {
  payment?: BillingData | null;
}

function BillingForm({ payment }: Props) {
  const r = useRef<HTMLButtonElement | null>(null);
  useEffect(() => {
    if (payment && r.current) {
      simulateMouseClick(r.current);
    }
  }, [payment]);
  return (
    <form method="POST" action={payment?.url || ''} style={{ display: 'none' }} onSubmit={() => {
        EventPayStore.billingFormSubmitting = true;
    }}>
      <input
        type="text"
        name="sum"
        readOnly={true}
        value={payment?.sum || ''}
      />
      <input
        type="text"
        name="sign"
        readOnly={true}
        value={payment?.sign || ''}
      />
      <input
        type="text"
        name="clientid"
        readOnly={true}
        value={payment?.clientId || ''}
      />
      <input
        type="text"
        name="orderid"
        readOnly={true}
        value={payment?.orderId || ''}
      />
      <input
        type="text"
        name="client_email"
        readOnly={true}
        value={payment?.clientEmail || ''}
      />
      <input
        type="text"
        name="user_result_callback"
        readOnly={true}
        value={payment?.userResultCallback || ''}
      />
      <input
        type="text"
        name="service_name"
        readOnly={true}
        value={payment?.serviceName || ''}
      />
      <button type="submit" value="" ref={r} />
    </form>
  );
}

export default BillingForm;
