import React from 'react';
import css from './Card.module.scss';
import classNames from 'classnames';
import {WithChildren} from "../../../models/technical/withProps";

interface CardProps extends WithChildren {}

export const Card:React.FC<CardProps> = (props) => {

  return <div className={classNames(
      css.card,
      props.className
  )}>{props.children}</div>;
};
