import React, { FocusEventHandler } from 'react';
import defaultCss from 'components/UI/FormWithLabelsUI/FormInput/FormInput.module.css';

import FormTextareaProps from 'models/ui/form/formTextareaProps';

export const FormTextarea = <T extends any>(props: FormTextareaProps<T>) => {
  const css = props.styles ? props.styles : defaultCss;
  return (
    <div
      className={`${css.formInput} ${props.className || ''} ${
        props.error && props.showErrorInComponent && css.inputError
          ? css.inputError
          : ''
      }`}
    >
      <BaseTextarea {...props} />
    </div>
  );
};

export function BaseTextarea({
  setValue,
  label,
  onFocus,
  onBlur,
  inputRef,
  className,
  styles,
  ...rest
}: FormTextareaProps) {
  const [isFocused, setFocused] = React.useState(false);
  const _onFocus: FocusEventHandler<HTMLTextAreaElement> = (e) => {
    setFocused(true);
    onFocus && onFocus(e);
  };
  const _onBlur: FocusEventHandler<HTMLTextAreaElement> = () => {
    setFocused(false);
    onBlur && onBlur();
  };

  const css = styles ? styles : defaultCss;

  return (
    <>
      {label && (
        <label
          className={`${css.formInputLabel} ${
            isFocused || rest.value ? `${css.formInputLabel_active}` : ''
          }`}
        >
          {label}
        </label>
      )}
      <textarea
        className={`${css.formInputField} ${css.inputTextarea}`}
        ref={inputRef}
        {...rest}
        onBlur={_onBlur}
        onFocus={_onFocus}
      />
    </>
  );
}
