import {observer} from "mobx-react";
import {SearchResultGrid} from "../../Search/SearchResults/SearchResultGrid/SearchResultGrid";
import CampCard from "../CampCard/CampCard";
import CampsSearchPageStore from "../../../../stores/CampsSearchPageStore";
import css from './GamesSection.module.scss';
import useIsInViewport from "../../../../utils/useIsInViewport";
import {useEffect, useRef} from "react";
import Spinner from "../../../UI/Loader/Loader";

const GamesSection:React.FC = () => {

    const events = CampsSearchPageStore.events?.results;
    const hasMore = CampsSearchPageStore.events?.hasMore;
    const loading = CampsSearchPageStore.requestState === 'pending';

    const moreButton = useRef(null);
    const isInViewport = useIsInViewport(moreButton);

    useEffect(() => {
        CampsSearchPageStore.getFilters();
        CampsSearchPageStore.getEvents(true);
        return () => {
            CampsSearchPageStore.resetData();
        }
    }, []);

    useEffect(() => {
        if (events && hasMore && !loading && isInViewport) {
            CampsSearchPageStore.getNextPage();
        }
    }, [isInViewport]);

    return (
        <>
            {!events?.length && !loading && <div>Упс... не удалось найти ни одного кемпа.</div>}
            {!!events?.length &&
                <SearchResultGrid
                    className={css.grid}
                    items={events.map(event =>
                        <CampCard
                            key={event.id}
                            data={event}
                        />
                    )}
                />
            }
            <div ref={moreButton} className={css.loadMore}>
                {!loading && hasMore && <div className={css.loadMore__text} onClick={CampsSearchPageStore.getNextPage}>Загрузить ещё</div>}
                {loading && <Spinner/>}
            </div>
        </>
    );
};

export default observer(GamesSection);