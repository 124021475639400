import React from 'react';
import { getPlayerSign } from 'utils/functions';
import Player from '../../../models/entities/player';
import css from './TeamMember.module.scss';
import classNames from "classnames";
import {useHistory} from "react-router-dom";
import linearGradient from "../../../css/linearGradient.module.css";
import {arrGradients} from "../../../utils/const";
import {ClassName} from "../../../models/ui/ClassName";

interface TeamMemberProps extends ClassName {
  player: Player;
  index: number;
}

const TeamMember:React.FC<TeamMemberProps> = ({
    player,
    index,
    className
                                              }) => {

  const history = useHistory();
  const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    history.push(`/cabinet/view/${player.user.id}`)
  }

  return (
    <div
      className={classNames(
          css.item,
          player.user.photo ? css.withPhoto : linearGradient[arrGradients[index % arrGradients.length]],
          className
      )}
      onClick={onClick}
    >
      <span className={css.bg} style={{ backgroundImage: player.user.photo ? `url(${player.user.photo})` : '' }}></span>
      {!player.user.photo && <span className={css.name} title={player.user.name}>{getPlayerSign(player.user.name)}</span>}
    </div>
  );
};

export default TeamMember;
