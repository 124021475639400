import { RequestState } from '../models/responses/common';
import { makeAutoObservable } from 'mobx';
import ArenaPageResponse from '../models/responses/arena';
import Arena from '../models/entities/arena';
import api from '../api';
import Sport from '../models/entities/sport';
import moment from 'moment';
import { timePeriodList } from '../components/Pages/Search/Filters/filtersConstants';
import reqArenaBookingOrder from 'models/requests/reqArenaBookingOrder';
import { getGameTimes } from 'moment/utils';

class ArenaStore {
  data?: ArenaPageResponse;
  requestState: RequestState = 'initial';
  arenaNotExist?: boolean

  constructor() {
    makeAutoObservable(this);
  }

  async getArena(slug: Arena['slug']) {
    try {
      this.requestState = 'pending';
      const data = await api.arena.getArena(slug);
      this.getDataDone(data);
    }
    catch(e: any) {
      this.getDataError();
      if (e?.error?.response?.data?.code === 'arena_not_found') {
        this.arenaNotExist = true;
      }
    }
  }

  getDataDone = (data: ArenaPageResponse) => {
    this.data = data;
    this.requestState = 'done';
  };

  getDataError = () => {
    this.requestState = 'error';
    this.data = undefined;
  };

  groupSportFormatsBySportId(): Record<Sport['id'], string[]> {
    if (!this.data) return [];
    return this.data.sportFormats.reduce(
      (accumulator: Record<Sport['id'], string[]>, currentValue) => {
        if (accumulator[currentValue.sportId]) {
          accumulator[currentValue.sportId].push(currentValue.gameFormat.name);
        } else {
          accumulator[currentValue.sportId] = [currentValue.gameFormat.name];
        }
        return accumulator;
      },
      {}
    );
  }

  getSportById(id: Sport['id']) {
    return this.data?.sports.find((s) => s.id === id);
  }

  get sportsInfo() {
    const sportFormatsBySportId = this.groupSportFormatsBySportId();
    return Object.entries(sportFormatsBySportId).map((d) => {
      const sport = this.getSportById(Number(d[0]));
      return { sport, formats: d[1] };
    });
  }

  getStartTimes(periodsCountOffset = 0) {
    if (!this.data) return [{ name: '', id: 0 }];
    const start = moment(this.data.arena.weekdayStart, 'HH:mm:ss');
    const end =
      this.data.arena.weekdayEnd === '23:59:59'
        ? moment('00:00:00', 'HH:mm:ss').add(1, 'days')
        : moment(this.data.arena.weekdayEnd, 'HH:mm:ss');
    return getGameTimes(start, end, periodsCountOffset);
  }

  getGameDurations(startTime?: string) {
    if (!this.data) return [{ name: '', id: 0 }];
    const end = moment(this.data.arena.weekdayEnd, 'HH:mm:ss');
    const start = moment(
      startTime ? startTime : this.data.arena.weekdayStart,
      'HH:mm:ss'
    );
    const minutes = end.diff(start, 'minutes', true);
    const periodsCount = Math.round(minutes / 30);
    const minPeriod = this.data.arena.minHalfHourPeriodsPerEvent;
    const maxPeriod = this.data.arena.maxHalfHourPeriodsPerEvent;
    return minPeriod && maxPeriod
      ? timePeriodList.filter(
          (t) => t.id >= minPeriod && t.id <= maxPeriod && t.id <= periodsCount
        )
      : timePeriodList.filter((t) => t.id <= periodsCount);
  }
  async sendBookingOrder(data: reqArenaBookingOrder) {
    if (this.data) {
      await api.arena.sendBookingOrder(this.data.arena.slug, data);
    }
  }
  reset() {
    this.data = undefined;
    this.requestState = 'initial';
    this.arenaNotExist = false;
  }
}

export default new ArenaStore();
