export enum NotificationKind {
    SIMPLE = "simple",
    IMPORTANT = "important",
}

export enum NotificationReason {
    EVENT_JOIN = "event_join",
    RECOMMENDATIONS = "recommendations"
}

export enum PersonalNotificationGroup {
    ALL = "all",
    NEW = "new",
    EVENTS = "events",
    SYSTEM = "system",
    PROMO = "promo",
    IMPORTANT = "important",
    RECOMMENDATIONS = 'recommendations'
}

export enum SpecialNotificationGroup {
    CHATS = "chats",
}