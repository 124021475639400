import * as React from 'react';
import api from '../../../../../api';
import { AvatarCropPresenter } from './avatarCrop';
import { AvatarSelectPresenter } from './avatarSelect';
import { base64string } from '../../../../../models/entities/image';
import { getExtensionFromBase64String } from './imageUploader';
import UserStore from '../../../../../stores/UserStore';
import ModalStore from '../../../../../stores/ModalStore';
import ProfileStore from "stores/ProfileStore";

interface AvatarUploadContainerProps {
  closeAvatarModal?: () => void;
  setProfileUrl?: (url: string) => void;
}

interface AvatarUploadContainerState {
  isImageSelected: boolean;
  fileName?: string;
  originalImage?: base64string;
  extension?: string;
  croppedImage?: HTMLCanvasElement;
}

export class AvatarUploadContainer extends React.Component<
  AvatarUploadContainerProps,
  AvatarUploadContainerState
> {
  private fileReader: FileReader;

  constructor(props: any) {
    super(props);
    this.state = { isImageSelected: false };
    this.fileReader = this.registerBase64Reader();
  }

  private registerBase64Reader(): FileReader {
    const readerBase64 = new FileReader();
    readerBase64.onloadend = (readerEvt) => {
      this.setState({
        originalImage: readerBase64.result as base64string,
        extension: getExtensionFromBase64String(
          readerBase64.result as base64string
        ),
        isImageSelected: true,
      });
    };
    readerBase64.onabort = () => {
      this.setState({ fileName: undefined });
    };
    readerBase64.onerror = () => {
      this.setState({ fileName: undefined });
    };
    return readerBase64;
  }

  public readImage = (e: any) => {
    e.preventDefault();
    const file: File = e.target.files[0];
    if (!file) {
      return;
    }
    const twoMb = 2097152;
    if (file.size > twoMb) {
      alert('Превышен максимальный размер файла (2 MB)');
      return;
    }
    if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
      alert('Тип файла должен быть jpeg или png.');
      return;
    }
    const img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = () => {
      const width = img.naturalWidth;
      const height = img.naturalHeight;
      const wrong = (dimension: number) => dimension < 100 || dimension > 3072;
      if (wrong(width) || wrong(height)) {
        alert(
          'Разрешение изображения должно быть между 100x100 и 3072x3072 пикселей'
        );
        return;
      }
      this.setState({ fileName: file.name }, () =>
        this.fileReader.readAsDataURL(file)
      );
    };
  };

  public moveToSelectImageStep = () => {
    const state: AvatarUploadContainerState = {
      originalImage: undefined,
      extension: '',
      croppedImage: undefined,
      isImageSelected: false,
    };
    this.setState(state);
  };

  public confirmCrop = (croppedImage: HTMLCanvasElement) => {
    return croppedImage.toBlob(this.uploadAvatar, this.state.extension);
  };

  public setCropToState = (crop: HTMLCanvasElement) =>
    this.setState({ croppedImage: crop });

  public uploadAvatar = async (file: Blob | null) => {
    if (!file) return;
    await api.profile.uploadAvatar(file, this.state.extension || '');
    await UserStore.getUserData();
    await ProfileStore.getProfile(UserStore.userData?.user?.id);
    ModalStore.hideModal();
  };

  public render() {
    if (this.state.isImageSelected) {
      return (
        <AvatarCropPresenter
          originalImage={this.state.originalImage}
          extension={this.state.extension}
          croppedImage={this.state.croppedImage}
          setCropToState={this.setCropToState}
          cancelCrop={this.moveToSelectImageStep}
          confirmCrop={this.confirmCrop}
        />
      );
    } else {
      return <AvatarSelectPresenter readImage={this.readImage} />;
    }
  }
}
