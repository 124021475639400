import api from 'api';
import browserHistory from 'browserHistory';
import { PayFormValues } from 'components/Pages/Event/Pay/PayForm';
import { makeAutoObservable } from 'mobx';
import Voucher from 'models/entities/voucher';
import BeforePayEventResponse from 'models/responses/beforePayEvent';
import { RequestState } from 'models/responses/common';
import UserStore from 'stores/UserStore';
import { AxiosError } from 'axios';
import PaymentResponse from '../models/responses/payment';
import {VkPixelPush} from "../utils/vkPixel";
import NotificationWidgetsStore from "./NotificationWidgetsStore";

class EventPayStore {
  eventData?: BeforePayEventResponse;
  voucherIsApplied = false;
  voucher?: Voucher;
  requestState: RequestState = 'initial';
  billingData?: PaymentResponse;
  billingFormSubmitting?: boolean

  constructor() {
    makeAutoObservable(this);
  }

  async getData(hash: string, orderId: string) {
    if (this.requestState === 'pending') return;
    this.requestState = 'pending';
    try {
      const eventData = await api.gameEvents.getEventForPay(hash, orderId);
      this.getDataDone(eventData);
    } catch (e) {
      this.getDataError(e as any);
    }
  }

  getDataDone = (data: BeforePayEventResponse) => {
    this.eventData = data;
    if (data.voucherUse) {
      this.voucher = data.voucherUse;
      this.voucherIsApplied = true;
    }
    this.requestState = 'done';
  };

  getDataError = ({ error }: { error: AxiosError }) => {
    if (
      error?.response?.status === 422 &&
      error?.response.data?.code === 'event_not_found'
    ) {
      browserHistory.push('/');
    }
    this.requestState = 'error';
  };

  applyVoucher = async ({ voucher }: { voucher: string }) => {
    console.log('applyVoucher', voucher);
    if (!this.eventData) return;
    const r = await api.vouchers.apply(voucher, this.eventData?.order.id);
    this.voucherIsApplied = true;
    this.voucher = r;
  };

  cancelVoucher = async () => {
    if (!this.voucherIsApplied || !this.voucher) return;
    await api.vouchers.cancel(this.voucher.userVoucherId);
    this.voucherIsApplied = false;
    this.voucher = undefined;
  };

  getOrder = async (orderId: string) => {
    this.billingData = await api.orders.getOrder(Number(orderId));
  };

  payWithCard = async ({ email }: PayFormValues) => {
    if (!this.eventData) return;
    if (this.billingData?.order?.status === "paid") {
      browserHistory.replace(`/event/view/${this.eventData.event.hash}`);
      return;
    }
    this.billingData = await api.orders.payWithCard(
      this.eventData.order.id,
      email
    );
    VkPixelPush('join_to_event');
    if (this.billingData?.order?.status === "paid") {
      browserHistory.replace(`/event/view/${this.eventData.event.hash}`);
      return;
    }
  };

  payWithDeposit = async ({ email }: PayFormValues) => {
    if (!this.eventData) return;
    await api.orders.payWithDeposit(this.eventData.order.id, email);
    VkPixelPush('join_to_event');
    await UserStore.getUserData();
    setTimeout(async () => {
      await NotificationWidgetsStore.loadCounters();
    }, 3000);
    browserHistory.replace(`/event/view/${this.eventData.event.hash}/`);
  };

  reset() {
    this.eventData = undefined;
    this.requestState = 'initial';
    this.voucher = undefined;
    this.voucherIsApplied = false;
    this.billingData = undefined;
  }
}

export default new EventPayStore();
