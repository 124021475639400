import City from 'models/entities/city';
import ChangeCityRequest from 'models/requests/changeCity';
import CityResponse from '../models/responses/city';
import backendApi from 'api/config';


class CityAPI {
  async getCities(): Promise<CityResponse> {
    const r = await backendApi.get('initial/cities/');
    return r.data;
  }
 
  async setCity(city: City): Promise<boolean> {
    const request: ChangeCityRequest = {cityId: city.id};   
    const r = await backendApi.post('/users/change_city', request);
    return r.status === 200;
  }

}

const cityAPI = new CityAPI();
export default cityAPI;
