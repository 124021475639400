import TabList from '../../../UI/Tabs/TabList';
import Tab from '../../../UI/Tabs/Tab';
import TabPanel from '../../../UI/Tabs/TabPanel';
import ProfileCard from './ProfileCard/ProfileCard';
import useTabs from '../../../UI/Tabs/tabHook';
import ProfileStore from "../../../../stores/ProfileStore";
import {Tabs, titles} from "./model";
import css from './Tabs.module.scss';
import EventsArchive from "./Games/EventsArchive/EventsArchive";

function TheirTabs() {

  const { connect } = useTabs(Tabs.profile);

  const userRemoved = ProfileStore.profile?.removed;

  return (
    <>
      <TabList>
        <Tab {...connect(Tabs.profile)}>{titles[Tabs.profile]}</Tab>
          {!userRemoved && (
              <Tab {...connect(Tabs.eventsArchive)}>{titles[Tabs.eventsArchive]}</Tab>
          )}
      </TabList>

        <div className={css.container}>
            <TabPanel {...connect(Tabs.profile)}>
                <ProfileCard />
            </TabPanel>
            {!userRemoved && (
                <TabPanel {...connect(Tabs.eventsArchive)}>
                    <EventsArchive/>
                </TabPanel>
            )}
        </div>

    </>
  );
}

export default TheirTabs;
