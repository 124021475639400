import { makeAutoObservable } from 'mobx';
const parser = require('ua-parser-js');

class AppStore {
  appKey = 0;
  userAgent = parser(navigator.userAgent);
  criticalError = false;
  mob: boolean = false;
  citySwitchByGetParamDone: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }
  refreshApp() {
    this.appKey += 1;
  }
  setMob(value: boolean) {
    this.mob = value;
  }
  setCitySwitchByGetParamDone() {
    this.citySwitchByGetParamDone = true;
  }
}

const appStore = new AppStore();

export default appStore;
