import {Gender} from "../../../../../models/entities/gender";
import Button from 'components/UI/Button/Button';
import css from './GenderAlertPopup.module.scss';
import {useHistory} from "react-router-dom";
import ModalStore from "../../../../../stores/ModalStore";

interface GenderAlertPopupProps {
    type: 'notMatch' | 'notSet',
    genderEvent: Gender | null,
    genderUser: Gender | null
}

const gendersEventText: Record<Gender, string> = {
    [Gender.any]: '',
    [Gender.man]: 'мужчин',
    [Gender.woman]: 'женщин'
}

const gendersUserText: Record<Gender, string> = {
    [Gender.any]: '',
    [Gender.man]: 'мужской',
    [Gender.woman]: 'женский'
}

const GenderAlertPopup:React.FC<GenderAlertPopupProps> = ({
    type,
    genderEvent,
    genderUser
                                                          }) => {

    const history = useHistory();

    const getText = () => {
        if (!genderEvent) return null;
        if (type === 'notSet') {
            return <div>К сожалению вы не можете присоединиться, т.к. событие создано для участников - <b>{gendersEventText[genderEvent]}</b><br/><br/>Ваш пол в системе не указан</div>;
        }
        else if (type === 'notMatch' && genderUser) {
            return <div>К сожалению вы не можете присоединиться, т.к. событие создано для участников - <b>{gendersEventText[genderEvent]}</b><br/><br/>Ваш пол в системе указан как <b>{gendersUserText[genderUser]}</b></div>
        }
    }

    const goToProfilePage = () => {
        history.push('/cabinet/edit');
        ModalStore.hideModal();
    }

    const hideModal = () => {
        ModalStore.hideModal();
    }

    return (
        <div>
            {getText()}
            {type === 'notSet' && (
                <div className={css.buttons}>
                    <Button
                        buttonText={'Указать'}
                        onClick={goToProfilePage}
                    />
                    <Button
                        buttonText={'Отмена'}
                        onClick={hideModal}
                    />
                </div>
            )}
        </div>
    );
};

export default GenderAlertPopup;