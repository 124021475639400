import Arena from 'models/entities/arena';
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { NavLink } from 'react-router-dom';
import SwiperCore, { Navigation, Autoplay, Pagination } from 'swiper/core';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/navigation/navigation.min.css';
import './AreaSlider.css';
import { splitByChunks } from 'utils/functions';

SwiperCore.use([Navigation, Autoplay, Pagination]);

interface Props {
  arenas: Arena[];
}

export const AreaSlider: React.FC<Props> = ({ arenas }): JSX.Element => {
  const sliderItem = (arena: Arena[]) => {
    return (
      <SwiperSlide key={arena[0].id} className="area-slider__item">
        <NavLink to={`/arenas/${arena[0].slug}`} className="area-slider__link">
          <img
            alt={arena[0].name}
            src={arena[0].logo}
            className="area-slider__img"
          />
        </NavLink>
        {arena[1] && (
          <NavLink
            to={`/arenas/${arena[1].slug}`}
            className="area-slider__link"
          >
            <img
              alt={arena[1].name}
              src={arena[1].logo}
              className="area-slider__img"
            />
          </NavLink>
        )}
      </SwiperSlide>
    );
  };
  return (
    <Swiper
      className="area-slider"
      slidesPerView={6}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
      pagination={{ clickable: true }}
      navigation
      loop
      breakpoints={{
        '0': {
          slidesPerView: 2,
        },
        '768': {
          slidesPerView: 4,
        },
        '1024': {
          slidesPerView: 6,
        },
      }}
    >
      {splitByChunks(arenas).map((a: Arena[]) => sliderItem(a))}
    </Swiper>
  );
};

export default AreaSlider;
