import Arena from '../models/entities/arena';
import ArenaPageResponse from '../models/responses/arena';
import reqArenaBookingOrder from '../models/requests/reqArenaBookingOrder';
import backendApi from './config';

class ArenaAPI {
  prefix = '/arenas';
  async getArena(slug: Arena['slug']): Promise<ArenaPageResponse>{
    const r = await backendApi.get(`${this.prefix}/${slug}/`);
    return r.data;
  }
  async sendBookingOrder(slug: Arena['slug'], data: reqArenaBookingOrder): Promise<boolean> {
    const r = await backendApi.post(`${this.prefix}/${slug}/booking/`, data);
    return r.status === 200;
  }
}

export default new ArenaAPI();