import React from 'react';
import RadioButtonGroupProps from "../../../models/ui/form/radioButtonGroupProps";
import CheckboxData from "../../../models/ui/form/checkboxData";
import css from "./RadioButtonGroup.module.scss";

const RadioButtonGroup:React.FC<RadioButtonGroupProps> = ({
                                                            options,
                                                            selectedOptions,
                                                            label,
                                                            onChange,
                                                            style,
                                                            optionsClass,
                                                            ...rest }) => {
  const update = (el: CheckboxData) => {
    onChange(el, rest);
  };
  const checkboxHasChecked = (target: CheckboxData) => selectedOptions?.value === target.value;
  return (
    <div className={`${css.radioButtonGroup} ${style && css[style]}`}>
      {label && (<p className={css.radioButtonGroup__title}>{label}</p>)}
      {options.map((item, index) =>
        <label
          key={item.value}
          className={`${css.radioButtonGroup__item} ${optionsClass && css[optionsClass[index]]}`}
        >
          <input
            className={css.radioButtonGroup__input}
            value={item.value}
            type="radio"
            checked={checkboxHasChecked(item)}
            onChange={() => {update(item)}}
          />
          <span className={css.checkbox}></span>
          <span className={css.radioButtonGroup__text}>{item.label}</span>
        </label>)}
    </div>
  );
};

export default RadioButtonGroup;