import Player from '../../../../../models/entities/player';
import css from './PlayerCard.module.scss';
import { getPlayerSign } from '../../../../../utils/functions';
import EventViewStore from '../../../../../stores/EventViewStore';
import React, { useState } from 'react';
import SendTicketForm from '../SendTicket/SendTicketForm';
import { NavLink } from 'react-router-dom';
import authorizedClick from '../../../../Hoc/AuthorizedComponent/authorizedClick';
import ModalStore from "../../../../../stores/ModalStore";
import ConfirmExitEvent from "../ConfirmExitEvent/ConfirmExitEvent";
import NotificationWidgetsStore from "../../../../../stores/NotificationWidgetsStore";
import classNames from "classnames";
import linearGradient from "../../../../../css/linearGradient.module.css";
import {arrGradients} from "../../../../../utils/const";
import commonCss from 'css/Common.module.css';
import arrowIcon from 'imgs/arrow.svg';

export function PlayerCard({
  player,
  index,
}: {
  player: Player;
  index: number;
}) {

  const [showForm, setShowForm] = useState(false);

  return (
    <div className={css.container}>
      {!showForm && (
        <div className={css.content}>
          <NavLink
            className={classNames(
                css.imgWrapper,
                !player.user.photo && linearGradient[arrGradients[index % arrGradients.length]]
            )}
            to={`/cabinet/view/${player.user.id}/`}
            onClick={authorizedClick}
          >
            {player.user.photo ? (
              <img src={player.user.photo} alt={player.name} className={commonCss.stretchedImg}/>
            ) : (
              getPlayerSign(player.user.name)
            )}
          </NavLink>
          <div className={css.textWrapper}>
            <span className={css.playerName}>{player.user.name}</span>
            {player.resendTicketAvailable && (
              <div className={classNames(
                  css.action,
                  css.withIcon,
                  css.sendTicket
              )} onClick={() => setShowForm(true)}>
                <span className={css.action__text}>Отправить билет на e-mail</span>
              </div>
            )}
            {player.exitAvailable && (
              <div
                className={classNames(
                    css.action,
                    css.withIcon,
                    css.exit
                )}
                onClick={
                  () => {
                    ModalStore.showModal(<ConfirmExitEvent
                      confirmEvent={
                        async () => {
                          await EventViewStore.exitFromEvent();
                          setTimeout(async () => {
                            await NotificationWidgetsStore.loadCounters();
                          }, 3000);
                          ModalStore.hideModal();
                        }
                      }
                      cancelEvent={ModalStore.hideModal.bind(ModalStore)}/>)
                  }
                }
              >
                <span className={css.action__text}>Покинуть игру</span>
              </div>
            )}
            {player.kickAvailable && (
              <div
                className={classNames(
                    css.action,
                    css.withIcon,
                    css.removeFriend
                )}
                onClick={
                  () => {
                    ModalStore.showModal(<ConfirmExitEvent
                    confirmEvent={
                      async () => {
                        await EventViewStore.kickFriendFromEvent(player.id);
                        setTimeout(async () => {
                          await NotificationWidgetsStore.loadCounters();
                        }, 3000);
                        ModalStore.hideModal();
                      }
                    }
                    type={'removeFriend'}
                    cancelEvent={ModalStore.hideModal.bind(ModalStore)}/>)
                  }
                }
              >
                <span className={css.action__text}>Удалить из игры</span>
              </div>
            )}
          </div>
        </div>
      )}
      {showForm && (
          <>
            <img src={arrowIcon} alt="Назад" className={css.backButton} onClick={() => { setShowForm(false) }}/>
            <SendTicketForm player={player} hideForm={() => setShowForm(false)} />
          </>
      )}
    </div>
  );
}
