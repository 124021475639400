import React from 'react';
import css from "./ModalSuccess.module.scss";

const ModalSuccess:React.FC = () => {
  return (
    <div className={css.modalSuccess}>
      <p className={css.modalSuccess__title}>Спасибо!</p>
      <p className={css.modalSuccess__text}>Благодарим, что потратили лучшие свои годы на заполнение нашей анкеты.<br/><br/>Изменить введенные данные можно в Личном кабинете.</p>
    </div>
  );
};

export default ModalSuccess;