import { makeAutoObservable } from 'mobx';
import ProfileInfoResponse from '../models/responses/profileInfo';
import User from '../models/entities/user';
import api from '../api';
import { RequestState } from '../models/responses/common';
import PaymentResponse from '../models/responses/payment';
import { EditProfileRequestData } from '../models/requests/editProfile';
import ChangePassword from '../models/requests/changePassword';
import UserStore from './UserStore';
import browserHistory from '../browserHistory';
import QuizAllInfo from "../models/responses/quizAllInfo";
import City from "../models/entities/city";
import {Config} from "../models/responses/user";

const profileGetErrors = {
  account_not_found: 'Аккаунт не найден',
  default: 'Что-то пошло не так',
};

class ProfileStore {
  profile?: ProfileInfoResponse;
  requestState: RequestState = 'initial';
  billingData?: PaymentResponse;
  quiz?: QuizAllInfo;
  requestStateUpdateConfig: RequestState = 'initial';

  constructor() {
    makeAutoObservable(this);
  }

  async getProfile(id?: User['id']) {
    if (!id) return;
    let profile;
    try {
      this.requestState = 'pending';
      profile = await api.profile.getProfile(id);
    } catch (e: any) {
      alert(
        // @ts-ignore
        profileGetErrors[e?.error?.response?.data?.code || ''] ||
          profileGetErrors.default
      );
      browserHistory.goBack();
      this.requestState = 'error';
      return;
    }
    this.getDataDone(id, profile);
  }

  getDataDone = (id: User['id'], profile: ProfileInfoResponse) => {
    profile.id = id;
    this.profile = profile;
    this.requestState = 'done';
  };

  getDataError = () => (this.profile = undefined);

  async getDepositPaymentData(amount: number) {
    this.billingData = await api.profile.getDepositPaymentData(amount);
  }

  async editProfile(values: EditProfileRequestData) {
    await api.profile.editProfile(values);
  }

  async changePassword(values: ChangePassword) {
    await api.profile.changePassword(values);
  }

  get isOwnProfile() {
    return this.profile?.id === UserStore.userData?.user?.id;
  }

  resetStore() {
    this.billingData = undefined;
    this.profile = undefined;
    this.requestState = 'initial';
    this.requestStateUpdateConfig = 'initial';
  }

  async removeAvatar() {
    const r = await api.profile.removeAvatar();
    if (r && this.profile) {
      this.profile.photo = null;
      UserStore.removeAvatar();
    }
  }
  async getQuiz() {
    try {
      this.requestState = 'pending';
      let r = await api.profile.getQuiz();
      if (!r.city) {
        if (UserStore.currentCity) await this.getQuizForCity(UserStore.currentCity.id);
      }
      else this.quizDataDone(r);
    }
    catch {
      this.requestState = 'error';
    }
  }
  async getQuizForCity(cityId: City['id']) {
    const r = await api.profile.getQuizForCity(cityId);
    this.quizDataDone(r);
  }
  quizDataDone(data: QuizAllInfo) {
    this.quiz = data;
    this.requestState = 'done';
  }

  updateRefundsOnlyToDeposit = async (data: Config['refundsOnlyToDeposit']) => {
    try {
      if (!this.profile) return;
      this.requestStateUpdateConfig = 'pending';
      await api.profile.updateUserFlags({
        refundsOnlyToDeposit: data
      });
      this.profile.refundsOnlyToDeposit = data;
      this.requestStateUpdateConfig = 'done';
    }
    catch {
      this.requestStateUpdateConfig = 'error';
    }
  };

}

export default new ProfileStore();
