import { FormField } from 'components/Hoc/Form/Field';
import Form from 'components/Hoc/Form/Form';
import { FormInput, maskDateOnChange } from "components/UI/FormWithLabelsUI/FormInput/FormInput";
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Button from 'components/UI/Button/Button';
import Select from 'components/UI/Select/Select';
import { FormTextarea } from 'components/UI/FormWithLabelsUI/FormTextarea/FormTextarea';
import { SelectOption } from 'models/entities/SelectOption';
import selectStyles from 'components/UI/FormWithLabelsUI/Select/Select.module.css';
import ArenaStore from 'stores/ArenaStore';
import moment from 'moment';
import { SearchAccess } from 'enums/event';
import { Dispatch, SetStateAction } from "react";
import ModalStore from "stores/ModalStore";
import { MaskedInput, maskPhoneOnChange, } from 'components/UI/FormWithLabelsUI/FormInput/FormInput';
import { AsYouType } from 'libphonenumber-js';
import reqArenaBookingOrder from "models/requests/reqArenaBookingOrder";

interface formFieldsData {
  name: string,
  email: string,
  phone: string,
  message: string,
  start_at: string,
  searchAccess: SelectOption,
  startTimes: SelectOption,
  gameDuration: SelectOption
}

interface OrderFormProps {
  setRespOk: Dispatch<SetStateAction<boolean>>
}

const searchAccessHelper = (obj: SelectOption) => {
  switch (obj.id) {
    case 0:
      return SearchAccess.private;
    case 1:
      return SearchAccess.searchPartners;
    default:
      return SearchAccess.private;
  }
};

const typeEventsArray: SelectOption[] = [
  { name: 'Есть свои игроки, поиграем сами', id: 0, },
  { name: 'С поиском участников', id: 1, }
];

const collectData = (formFieldsData: formFieldsData):reqArenaBookingOrder => {
  const phoneNormalizer = new AsYouType('RU');
  phoneNormalizer.input(formFieldsData.phone);
  return {
    name: formFieldsData.name,
    email: formFieldsData.email,
    phone: phoneNormalizer.getNumber()?.number as string,
    message: formFieldsData.message,
    searchAccess: searchAccessHelper(formFieldsData.searchAccess),
    startAt: moment(`${formFieldsData.start_at} ${formFieldsData.startTimes.name}`, 'DD.MM.YYYY HH:mm:ss').toISOString(),
    periodsCount: formFieldsData.gameDuration.id
  }
};

const OrderForm: React.FC<OrderFormProps> = ({ setRespOk }) => {
  /* получаем первоначальные значения для длительности и времени игры */
  const [startTimesArrayFirst] = ArenaStore.getStartTimes();
  const [gameDurationsArrayFirst] = ArenaStore.getGameDurations();

  /* задаём дефолтные значения селектов в форме */
  const form = useForm({
    defaultValues: {
      searchAccess: typeEventsArray[0],
      startTimes: startTimesArrayFirst,
      gameDuration: gameDurationsArrayFirst
    }
  });

  /* получаем значения полей startTimesValue и gameDurationValue когда они изменились в форме */
  // @ts-ignore
  const startTimesValue = form.watch('startTimes');
  const gameDurationValue = form.watch('gameDuration');

  return (
    <div className="order-form">
      <Form
        formReturn={form}
        onSubmit={async (data: formFieldsData) => {
          await ArenaStore.sendBookingOrder(collectData(data));
          setRespOk(true);
        }}
      >
        <FormField
          name="name"
          type="text"
          label="Имя"
          Component={FormInput}
          showErrorInComponent
        />
        <FormField
          name="email"
          type="email"
          label="Email"
          Component={FormInput}
          showErrorInComponent
        />
        <FormField
          name="phone"
          label="Контактный телефон"
          Component={MaskedInput}
          maskOnChange={maskPhoneOnChange}
          showErrorInComponent
        />
        <FormField
          Component={MaskedInput}
          label="Дата игры ДД.ММ.ГГГГ"
          name="start_at"
          maskOnChange={maskDateOnChange}
          showErrorInComponent
        />
        <FormField
          name="message"
          label="Комментарий"
          Component={FormTextarea}
          showErrorInComponent
        />
        <FormField
          name="searchAccess"
          label="Тип планируемого события"
          Component={Select}
          showErrorInComponent
          styles={selectStyles}
          options={typeEventsArray}
        />
        <FormField
          name="startTimes"
          Component={Select}
          options={ArenaStore.getStartTimes(gameDurationValue.id)}
          styles={selectStyles}
          label={'Планируемое время начала события'}
        />
        <FormField
          name="gameDuration"
          Component={Select}
          options={ArenaStore.getGameDurations(startTimesValue.name)}
          styles={selectStyles}
          label={'Планируемая длительность игры'}
        />
        <Button type="submit" buttonText="Отправить" />
      </Form>
    </div>
  );
};

const RespOkMessage: React.FC = () => {
  return (
    <div>
      <p>Ваша заявка успешно отправлена.</p>
      <Button buttonText="Ок" onClick={() => {
        ModalStore.hideModal();
      }}/>
    </div>
  );
};

const ArenaBookingForm: React.FC = () => {
  const [respOk, setRespOk] = useState(false);
  return !respOk ? <OrderForm setRespOk={setRespOk}/> : <RespOkMessage/>;
};

export default ArenaBookingForm;