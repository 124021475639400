import React from 'react';
import './Footer.css';
import { NavLink } from 'react-router-dom';
import fundImg from "imgs/logo_fasie.png";

export const Footer = (): JSX.Element => {
  return (
    <footer className="footer">
      <div className="wrap">
        <div className="footer__fund">
          <p className="footer__fund-text">Проект поддерживается <a href="https://fasie.ru/" target="_blank">Фондом содействия развитию</a> малых форм предприятий в научно-технической сфере по программе "Старт-2"</p>
          <div className="footer__fund-img-wrapper">
            <img src={fundImg} alt="Фондом содействия развитию в научно-технической сфере по программе Старт-2"/>
          </div>
        </div>
        <div className="footer__content">
          <div className="footer__logo-container">
            <div className="footer__logo" />
            <div className="footer__donate" style={{ display: 'none' }}>
              💰Хочу помочь проекту
            </div>
          </div>
          <div className="footer__links">
            <NavLink to="/support/" className="footer__link">
              Поддержка
            </NavLink>
            <NavLink to="/legal/" className="footer__link">
              Условия предоставления сервиса
            </NavLink>
            <NavLink to="/policy/" className="footer__link">
              Политика конфиденциальности
            </NavLink>
          </div>
          <div className="footer__social-container">
            <div className="footer__social-line">
              <div className="footer__social">
                <a
                  href="https://vk.com/sploteamcom"
                  target="_blank"
                  rel="noreferrer"
                  className="footer__social-link"
                >
                  <div className="footer__vk" />
                </a>
                <a
                  href="https://www.instagram.com/sploteam/"
                  target="_blank"
                  rel="noreferrer"
                  className="footer__social-link"
                >
                  <div className="footer__inst" />
                </a>
                <a
                  href="https://www.facebook.com/sploteam"
                  target="_blank"
                  rel="noreferrer"
                  className="footer__social-link"
                >
                  <div className="footer__fb" />
                </a>
              </div>
              <div className="footer__mobile-app">
                <a
                  href="https://play.google.com/store/apps/details?id=com.sploteam.app&amp;hl=ru"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="footer__gplay" />
                </a>
                <a
                  href="https://apps.apple.com/ru/app/sploteam/id1464576571"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="footer__appstore" />
                </a>
              </div>
            </div>
            <div className="footer__copyright">
              ©Sploteam {new Date().getFullYear()}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
