export enum Tabs {
    events,
    create
}

export const titles = {
    [Tabs.events]: 'События',
    [Tabs.create]: 'Создать игру'
}

export enum Views {
    table,
    map
}