import {useForm} from "react-hook-form";
import Form from "../../../../Hoc/Form/Form";
import {FormField} from "../../../../Hoc/Form/Field";
import {FormInput} from "../../../../UI/FormWithLabelsUI/FormInput/FormInput";
import {FormTextarea} from "../../../../UI/FormWithLabelsUI/FormTextarea/FormTextarea";
import CampPageStore from "../../../../../stores/CampPageStore";
import css from './OrderForm.module.scss';
import React from "react";
import Button from "../../../../UI/Button/Button";
import api from 'api';
import {SendOrderReq} from "../../../../../models/requests/camps";
import ModalStore from "../../../../../stores/ModalStore";
import UserStore from "../../../../../stores/UserStore";
import ym from "react-yandex-metrika";
import {VkPixelPush} from "../../../../../utils/vkPixel";

const OrderForm:React.FC = () => {

    const form = useForm({
        defaultValues: {
            email: UserStore.userData?.user?.email || '',
        },
        mode: 'onChange'
    });

    const onSubmit = async (data: SendOrderReq['data']) => {
        const r = await api.camps.sendOrder({
            slug: CampPageStore.data!.slug,
            data
        });
        if (r) {
            ModalStore.showModal(<SuccessMessage/>);
            if (process.env.NODE_ENV === 'production') {
                ym('reachGoal', 'send_order_camps');
                VkPixelPush('camp_order_send');
            }
        }
    }

    return (
        <div>
            <div className={css.title}>Заявка на бронь</div>
            <div className={css.campName}>{CampPageStore.data?.name}</div>
            <Form
                onSubmit={onSubmit}
                formReturn={form}
                className={css.form}
            >
                <FormField Component={FormInput} label="Ваше имя" name="name" rules={{required: true}} />
                <FormField Component={FormInput} label="Email" name="email" rules={{required: true}} />
                <FormField Component={FormInput} label="Номер телефона" name="phone" />
                <FormField Component={FormTextarea} label="Сообщение" name="message" rules={{maxLength: { value: 2000, message: 'Превышен допустимый размер сообщения' }}} />
                <Button buttonText={'Оставить заявку'} type={'submit'}/>
            </Form>
        </div>
    );
}

const SuccessMessage:React.FC = () => {
    return (
        <div>Заявка успешно отправлена, мы свяжемся с Вами в ближайшее время.</div>
    );
}

export default OrderForm;