import {WithChildren} from "../../models/technical/withProps";
import Header from "../Hoc/Header/Header";
import Footer from "../Hoc/Footer/Footer";

interface DefaultLayoutProps extends WithChildren {}

const DefaultLayout:React.FC<DefaultLayoutProps> = ({
    children
                                                    }) => {
    return (
        <>
            <Header/>
            {children}
            <Footer/>
        </>
    );
};

export default DefaultLayout;