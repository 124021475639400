import backendApi from 'api/config';
import { searchSorts } from 'components/Pages/Search/Filters/SortFilter/SortFilter';
import Arena from 'models/entities/arena';
import GameEvent from 'models/entities/gameEvent';
import Ground from 'models/entities/ground';
import Sport from 'models/entities/sport';
import EventCreateRequest from 'models/requests/eventCreate';
import BeforePayEventResponse from 'models/responses/beforePayEvent';
import PrepareEventResponse from 'models/responses/prepareEvent';
import moment from 'moment';
import EventFilters from '../models/entities/eventFilters';
import SendTicketRequest from '../models/requests/sendTicket';
import { PartnerJoinFV } from '../components/Pages/Event/View/PartnerJoin/PartnerJoin';
import Player from "../models/entities/player";
import EventType from "../models/entities/eventType";
import {EventsFiltersResp} from "../models/responses/searchEvents";
import GetEventResponse from "../models/responses/getEvent";

export interface GetEventParams {
  source: string
}

class GameEventsAPI {
  prefix = '/events/';

  getFilterParams(
    sportId: Sport['id'] | null,
    arenaId: Arena['id'] | null,
    playerLevel: string | undefined,
    eventType: EventType['id'] | null
  ) {
    return {
      sport_id: sportId || undefined,
      arena_id: arenaId || undefined,
      player_level: playerLevel,
      event_type: eventType
    };
  }

  async getEvents(
    date: Date,
    sort: typeof searchSorts[0] | undefined,
    sportId: Sport['id'] | null,
    arenaId: Arena['id'] | null,
    playerLevel: string | undefined,
    eventType: EventType['id'] | null
  ) {
    const r = await backendApi.get(`${this.prefix}v2/`, {
      params: {
        datetime_from: moment(date).format('YYYY-MM-DDT00:00:00'),
        sort: sort?.value,
        ...this.getFilterParams(sportId, arenaId, playerLevel, eventType),
      },
    });
    return r.data;
  }

  async getEvent(hash: GameEvent['hash'], params?: GetEventParams): Promise<GetEventResponse> {
    const r = await backendApi.get(this.prefix + `${hash}/`, {
      params
    });
    return r.data;
  }

  async prepareEvent(
    start_at: string,
    ground_id: Ground['id'],
    periods_count: number
  ): Promise<PrepareEventResponse> {
    const r = await backendApi.post(this.prefix + 'prepare', {
      start_at,
      ground_id,
      periods_count,
    });
    return r.data;
  }

  async getPreparedEvent(hash: string) {
    const r = await backendApi.get(this.prefix + `${hash}/prepare`);
    return r.data;
  }

  async editEvent(
    hash: GameEvent['hash'],
    data: EventCreateRequest
  ): Promise<BeforePayEventResponse> {
    const r = await backendApi.post(this.prefix + `${hash}/edit`, data);
    return r.data;
  }

  async getEventForPay(hash: GameEvent['hash'], orderId: string) {
    const r = await backendApi.get(this.prefix + `${hash}/orders/${orderId}`);
    return r.data;
  }

  async joinEvent(hash: GameEvent['hash']): Promise<BeforePayEventResponse> {
    const r = await backendApi.post(this.prefix + `${hash}/join`);
    return r.data;
  }

  async joinEventOfflinePay(hash: GameEvent['hash']): Promise<boolean> {
    const r = await backendApi.post(this.prefix + `${hash}/offline_pay_join`);
    return r.status === 200;
  }

  async joinEventFree(hash: GameEvent['hash']): Promise<boolean> {
    const r = await backendApi.post(this.prefix + `${hash}/free_join`);
    return r.status === 200;
  }

  async joinFriendFree(
    hash: GameEvent['hash'],
    friend: PartnerJoinFV
  ): Promise<boolean> {
    const r = await backendApi.post(
      this.prefix + `${hash}/free_join_friend`,
      friend
    );
    return r.status === 200;
  }

  async joinFriend(
    hash: GameEvent['hash'],
    friend: PartnerJoinFV
  ): Promise<BeforePayEventResponse> {
    const r = await backendApi.post(
      this.prefix + `${hash}/join_friend`,
      friend
    );
    return r.data;
  }

  async exitEvent(hash: GameEvent['hash']) {
    return await backendApi.post(this.prefix + `${hash}/exit`);
  }

  async kickFriendFromEvent(hash: GameEvent['hash'], playerId: Player['id']) {
    return await backendApi.post(this.prefix + `${hash}/kick`, {
      playerId
    });
  }

  async cancelEvent(hash: GameEvent['hash']) {
    return await backendApi.post(this.prefix + `${hash}/close`);
  }

  async getEventFilters(): Promise<EventFilters> {
    const r = await backendApi.get(this.prefix + `filters/`);
    return r.data;
  }

  async sendTicket(
    hash: GameEvent['hash'],
    data: SendTicketRequest
  ): Promise<boolean> {
    const r = await backendApi.post(this.prefix + `${hash}/send_ticket`, data);
    return r.status === 200;
  }

  async getFilters(): Promise<EventsFiltersResp> {
    const r = await backendApi.get(`${this.prefix}filters/`);
    return r.data;
  }
}

const gameEventsAPI = new GameEventsAPI();
export default gameEventsAPI;
