import {makeAutoObservable} from 'mobx';
import PersonalNotification from '../models/entities/personalNotification';
import {PersonalNotificationGroup, SpecialNotificationGroup,} from '../enums/notification';
import {NotificationGroupCounters} from '../models/responses/notificationCounters';
import api from '../api';
import {PagedData} from '../models/responses/pagedData';
import {NotificationGroup} from '../models/entities/notificationGroup';
import ChatNotification from '../models/entities/chatNotification';
import {isPersonalNotificationGroup} from '../models/guards/isPersonalNotificationGroup';

class NotificationsSectionStore {
  private readonly pageSize = 30;
  private readonly defaultGroup = PersonalNotificationGroup.NEW;

  allNotificationsSelected: boolean;
  selectedSet: Set<number>;
  groups: NotificationGroup[];
  selectedGroup?: NotificationGroup;
  counters?: NotificationGroupCounters;
  pageData?: PagedData<PersonalNotification | ChatNotification>;

  constructor() {
    this.allNotificationsSelected = false;
    this.selectedSet = new Set();
    this.groups = [
      PersonalNotificationGroup.ALL,
      PersonalNotificationGroup.NEW,
      PersonalNotificationGroup.EVENTS,
      PersonalNotificationGroup.SYSTEM,
      PersonalNotificationGroup.PROMO,
      PersonalNotificationGroup.RECOMMENDATIONS,
      PersonalNotificationGroup.IMPORTANT,
      SpecialNotificationGroup.CHATS,
    ];

    makeAutoObservable(this);
  }

  addSelected(id: number) {
    this.selectedSet.add(id);
  }

  removeSelected(id: number) {
    this.selectedSet.delete(id);
    this.allNotificationsSelected = false;
  }

  selectAll() {
    this.pageData!.results.forEach((data) => {
      this.selectedSet.add(data.id);
    });
    this.allNotificationsSelected = true;
  }

  resetSelection() {
    this.selectedSet.clear();
    this.allNotificationsSelected = false;
  }

  hasSelectedNotifications() {
    return !!this.selectedSet.size;
  }

  async loadCounters() {
    this.counters = await api.notifications.getGroupCounters();
  }

  private async fetchPage(
    page: number
  ): Promise<NotificationsSectionStore['pageData']> {
    if (isPersonalNotificationGroup(this.selectedGroup!)) {
      return await api.notifications.getPersonalNotifications({
        page,
        group: this.selectedGroup as PersonalNotificationGroup,
        pageSize: this.pageSize,
      });
    }

    return await api.notifications.getChatNotifications({
      page,
      pageSize: this.pageSize,
    });
  }

  async loadPage(page: number = 1, withoutResetAllNotificationsSelected: boolean = false) {
    if (!this.selectedGroup) {
      return;
    }

    this.pageData = await this.fetchPage(page);

    if (this.allNotificationsSelected && withoutResetAllNotificationsSelected) {
      this.selectedSet.clear();
      this.selectAll();
    }
    else {
      this.selectedSet.clear();
      this.allNotificationsSelected = false;
    }
  }

  async fullReload() {
    await this.loadPage(this.pageData!.pageNumber);
    await this.loadCounters();
  }

  async selectGroup(group: NotificationGroup) {
    if (group === this.selectedGroup) {
      return;
    }
    this.selectedGroup = group;
    await this.loadPage();
  }

  async selectDefaultGroup() {
    await this.selectGroup(this.defaultGroup);
  }

  private async readOrDeleteSelectedNotifications(read: boolean) {
    await this.readOrDeleteNotifications(read, Array.from(this.selectedSet));
  }

  private async readOrDeleteNotifications(read: boolean, ids?: number[]) {
    if (
      !ids &&
      !this.allNotificationsSelected &&
      !this.selectedSet.size
    ) {
      throw new Error('Cannot read or delete notifications: no notifications selected.');
    }

    if (isPersonalNotificationGroup(this.selectedGroup!)) {
      const clientMethod = read
        ? api.notifications.postPersonalNotifications
        : api.notifications.deletePersonalNotifications;
      const data = (() => {
        if (ids && !this.allNotificationsSelected) return { ids };
        else if (this.allNotificationsSelected) return { group: this.selectedGroup! as PersonalNotificationGroup };
        else throw new Error('Cannot read or delete notifications: no notifications selected.');
      })();
      await clientMethod(data);
      return;
    }

    if (this.selectedGroup! in SpecialNotificationGroup) {
      throw new Error(`Undefined group "${this.selectedGroup}".`);
    }

    const clientMethod = read
      ? api.notifications.postChatNotifications
      : api.notifications.deleteChatNotifications;
    await clientMethod({ ids });
  }

  async readNotifications(ids: number[]) {
    await this.readOrDeleteNotifications(true, ids);
  }

  async readSelectedNotifications() {
    await this.readOrDeleteSelectedNotifications(true);
  }

  async deleteSelectedNotifications() {
    await this.readOrDeleteSelectedNotifications(false);
  }

  getGroupCounter(group: NotificationGroup): number | undefined {
    if (!this.counters) {
      return;
    }

    return isPersonalNotificationGroup(group)
      ? this.counters.personal[group]
      : this.counters.chat;
  }
}

export default new NotificationsSectionStore();
