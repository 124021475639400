import { makeAutoObservable } from 'mobx';
import api from '../api';
import { RequestState } from '../models/responses/common';
import CityResponse from '../models/responses/city';
import City from "../models/entities/city";

class CityStore {
  cityData?: CityResponse;
  requestState: RequestState = 'pending';
  citySwitcherDisabled: boolean = false

  constructor() {
    makeAutoObservable(this);
  }

  getCityById(id: City['id']) {
    return this.cityData?.cities.find((city) => city.id === id);
  }

  async getCityData() {
    try {
      const resp = await api.city.getCities();
      this.getCityDataDone(resp)
    }
    catch (e) {
      this.getCityDataError();
    }
  }

  getCityDataDone = (data: CityResponse) => {
    this.cityData = data;
    this.requestState = 'done';
  };

  getCityDataError = () => (this.requestState = 'error');

  setCitySwitcherDisabled = (data: boolean) => {
    this.citySwitcherDisabled = data;
  }
}

export default new CityStore();
