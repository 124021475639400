import React from "react";
import {GrayRoundedSelect} from "../../../../../UI/Select/GrayRoundedSelect";
import styles from "./NotificationGroupsNav.module.scss";
import {observer} from "mobx-react";
import store from "../../../../../../stores/NotificationsSectionStore";
import {SelectOption} from "../../../../../../models/entities/SelectOption";
import {PersonalNotificationGroup, SpecialNotificationGroup} from "../../../../../../enums/notification";
import {NotificationGroup} from "../../../../../../models/entities/notificationGroup";

interface Props {
    type: "select" | "menu",
    separatorIndexes?: number[],
}

const groupToTitle: Record<NotificationGroup, string> = {
    [PersonalNotificationGroup.ALL]: "Все",
    [PersonalNotificationGroup.NEW]: "Непрочитанные",
    [PersonalNotificationGroup.EVENTS]: "События",
    [PersonalNotificationGroup.SYSTEM]: "Сервисные",
    [PersonalNotificationGroup.PROMO]: "Промо",
    [PersonalNotificationGroup.IMPORTANT]: "🔥 Важные",
    [PersonalNotificationGroup.RECOMMENDATIONS]: "Рекомендации",
    [SpecialNotificationGroup.CHATS]: "Чаты",
};

const NotificationGroupsNav: React.FC<Props> = (props) => {

    const onSelect = (group: NotificationGroup) => {
        store.selectGroup(group);
        store.loadCounters();
    }

    const navOption = (data: {group: NotificationGroup, name: string, label?: string | number, isSelected?: boolean}) => {
        return (
            <div
                key={data.group}
                className={`${styles.categoryOption} ${data.isSelected ? styles.selected : ""}`}
                onClick={() => onSelect(data.group)}
            >
                <div className={styles.categoryOptionName}>{data.name}</div>
                {(data.label || null) &&
                    <div className={styles.categoryOptionLabel}>{data.label}</div>
                }
            </div>
        )
    };

    const separator = (index: number) => {
        return (
            <div
                key={`separator-${index}`}
                className={styles.separator}
            />
        )
    };

    const getNavOptionData = (group: NotificationGroup) => {
        return {
            group,
            name: groupToTitle[group],
            label: store.getGroupCounter(group),
            isSelected: group === store.selectedGroup,
        };
    };

    const navList = () => {
        if (props.type === "select") {
            const selectOptions: SelectOption<NotificationGroup>[] = [];
            let selected: SelectOption<NotificationGroup> | undefined;

            store.groups
                .forEach(group => {
                    const optionData = getNavOptionData(group);
                    const selectOption: SelectOption<NotificationGroup> = {
                        id: group,
                        name: optionData.name,
                        customContent: navOption(optionData)
                    };

                    if (optionData.isSelected) {
                        selected = selectOption;
                    }
                    selectOptions.push(selectOption);
                });

            return (
                <GrayRoundedSelect
                    options={selectOptions}
                    value={selected}
                    onChange={(selectedOption) => onSelect(selectedOption.id)}
                />
            );
        }

        return store.groups
            .reduce<JSX.Element[]>((list, group, i) => {
                if (props.separatorIndexes?.includes(i)) {
                    list.push(separator(i));
                }

                list.push(
                    navOption(getNavOptionData(group))
                );

                return list;
            }, [])
    }

    return (
        <div className={styles.categories}>
            {navList()}
        </div>
    )
}

export default observer(NotificationGroupsNav);