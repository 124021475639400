import { doSubmit } from 'components/Hoc/Form/doSubmit';
import { FormField } from 'components/Hoc/Form/Field';
import Form from 'components/Hoc/Form/Form';
import Button from 'components/UI/Button/Button';
import { FormInput } from 'components/UI/FormWithLabelsUI/FormInput/FormInput';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import EventPayStore from 'stores/EventPayStore';
import css from './EventPayPage.module.scss';
import BillingForm from '../../shared/BillingForm/BillingForm';

export interface PayFormValues {
  email: string;
}

function PayForm() {
  const form = useForm<PayFormValues>({
    defaultValues: { email: EventPayStore.eventData?.user.email || '' },
  });
  const [showEmailInput, setShowEmailInput] = useState(false);
  return (
    <div className={css.infoBlockColumn}>
      <span className={css.infoBlockTitle}>E-mail для получения билета</span>
      <span
        className={`${css.infoBlockText} ${showEmailInput ? css.hidden : ''}`}
      >
        <div className={css.infoEmail}>
          {EventPayStore.eventData?.user.email}
        </div>
        <div
          className={css.changeEmail}
          onClick={() => setShowEmailInput(true)}
        >
          Указать другой
        </div>
      </span>
      <span>
        <Form onSubmit={() => null} formReturn={form} disabled={EventPayStore.billingFormSubmitting}>
          <FormField
            name="email"
            type="text"
            label="Электронная почта"
            Component={FormInput}
            className={`${css.infoBlockText} ${
              !showEmailInput ? css.hidden : ''
            }`}
          />
          <Button
            buttonText="Оплатить игру"
            onClick={() => {
              form.handleSubmit(doSubmit(EventPayStore.payWithCard, form))();
            }}
            className={css.buttonGap}
          />
          <Button
            buttonText="Оплатить игру с личного счета"
            onClick={() =>
              form.handleSubmit(doSubmit(EventPayStore.payWithDeposit, form))()
            }
          />
        </Form>
        <BillingForm payment={EventPayStore.billingData?.payment} />
      </span>
    </div>
  );
}

export default observer(PayForm);
