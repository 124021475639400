import { SelectOption } from 'models/entities/SelectOption';
import { CssModulesStyles } from 'models/technical/cssModules';
import ReactSelectOption from 'models/ui/form/reactSelectOption';
import React from 'react';
import { Controller, UseControllerProps, ControllerRenderProps } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import {
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form/dist/types/form';
import { ErrorMessage } from '@hookform/error-message';
import css from './form.module.css';
import { onChangeType } from '../../../models/technical/form';


interface HookedInputProps extends UseControllerProps {
  Component: React.ElementType;
  setValue?: UseFormSetValue<FieldValues>;
  register?: UseFormRegister<FieldValues>;
  label?: string;
  type?: HTMLInputElement['type'];
  className?: string;
  options?: SelectOption[] | ReactSelectOption<any>[];
  getHint?: (value: any) => string;
  hidden?: boolean;
  styles?: CssModulesStyles;
  maskOnChange?: (onChange: onChangeType) => onChangeType;
  placeholder?: string;
  rows?: number;
  disabled?: boolean;
  showErrorInComponent?: boolean;
  inputRef?: ControllerRenderProps['ref'];
}

export const FormField: React.FC<HookedInputProps> = (props) => {
  const { Component, rules, register, ...rest } = props;
  return (
    <Controller
      control={rest.control}
      name={rest.name}
      defaultValue={rest.defaultValue || ''}
      rules={rules}
      render={({ field: {
        ref,
        ...inputProps
      }, formState }) => {
        return (
          <>
            <Component
              {...inputProps}
              inputRef={ref}
              type={rest.type}
              label={rest.label}
              setValue={rest.setValue}
              className={`${rest.className} ${rules?.required ? css.required : ''}`}
              error={formState.errors[rest.name]}
              {...rest}
            />
            {!props.showErrorInComponent && (
              <ErrorMessage
                name={props.name}
                errors={formState.errors}
                as="p"
                className={css.error}
              />
            )}
          </>
        );
      }}
    />
  );
};
