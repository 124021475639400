import { observer } from 'mobx-react';
import React from 'react';
import SearchPageStore from 'stores/SearchPageStore';
import 'components/Pages/Search/Filters/DateFilter/DateFilter.css';
import NavLink from "../../../../UI/NavLink/NavLink";
import UserStore from "../../../../../stores/UserStore";

const DateFilter: React.FC = observer(() => {
  let closestDates: Date[] = [];
  let today = UserStore.getCurrentTime().toDate();
  for (let i = 0; i < 14; i++) {
    let nextDay = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + i
    );
    closestDates.push(nextDay);
  }

  const getWeekDay = (date: Date) => {
    let days = ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'];
    return days[date.getDay()];
  };

  return (
    <div className="date-filter">
      {closestDates.map((date, index) =>
          <NavLink
              key={index + date.getDate()}
              active={SearchPageStore.dateFilter?.valueOf() === date.valueOf()}
              onClick={() => SearchPageStore.setDateFilter(date)}
              topPrefix={getWeekDay(date)}
          >{date.getDate() === today.getDate() ? 'Сегодня' : date.getDate()}</NavLink>
      )}
    </div>
  );
});

export default DateFilter;
