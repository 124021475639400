import api from 'api';
import browserHistory from 'browserHistory';
import {SearchAccess} from 'enums/event';
import {makeAutoObservable} from 'mobx';
import GameEvent from 'models/entities/gameEvent';
import {RequestState} from 'models/responses/common';
import GetEventResponse from 'models/responses/getEvent';
import UserStore from 'stores/UserStore';
import ChatMessage from '../models/entities/chat';
import {ChatGroupedByDay} from '../components/Pages/Event/View/Chat/models';
import SendTicketRequest from '../models/requests/sendTicket';
import {PartnerJoinFV} from '../components/Pages/Event/View/PartnerJoin/PartnerJoin';
import moment from 'moment';
import Player from "../models/entities/player";
import {VkPixelPush} from "../utils/vkPixel";
import {GetEventParams} from "../api/gameEvents";

class EventViewStore {
  data?: GetEventResponse;
  requestState: RequestState = 'initial';
  waitScroll: boolean = false;
  chatMessages: ChatMessage[] = [];
  chatNextPageLink?: string | null;
  eventNotExist?: boolean

  constructor() {
    makeAutoObservable(this);
  }

  resetEvent() {
    this.data = undefined;
    this.requestState = 'initial';
    this.clearChat();
    this.eventNotExist = false;
  }

  async getEvent(hash: GameEvent['hash'], params?: GetEventParams) {
    if (!hash) return;
    try {
      this.requestState = 'pending';
      const data = await api.gameEvents.getEvent(hash, params);
      this.getDataDone(data);
    } catch(e: any) {
      this.getDataError();
      if (e?.error?.response?.data?.code === 'event_not_found') {
        this.eventNotExist = true;
      }
    }
  }

  getDataDone = (data: GetEventResponse) => {
    this.data = data;
    this.requestState = 'done';
  };

  getDataError = () => {
    this.requestState = 'error';
  };

  async join() {
    if (!this.data) return;
    try {
      this.data.event.searchAccess === SearchAccess.private
        ? await this.joinEventFree(this.data.event.hash)
        : await this.joinEvent(this.data.event.hash);
    } catch (e) {
      throw e;
    }
  }

  async joinEvent(hash: GameEvent['hash']) {
    try {
      const beforePayResponse = await api.gameEvents.joinEvent(hash);
      browserHistory.replace(
        `/event/pay/${hash}/order/${beforePayResponse.order.id}`
      );
    } catch (e) {
      throw e;
    }
  }

  async joinEventOfflinePay() {
    if (!this.data) return;
    try {
      await api.gameEvents.joinEventOfflinePay(this.data.event.hash);
      VkPixelPush('join_to_event');
      window.location.reload();
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  async joinEventFree(hash: GameEvent['hash']) {
    try {
      await api.gameEvents.joinEventFree(hash);
      window.location.reload();
    } catch (e) {
      throw e;
    }
  }

  async joinForPartner(friend: PartnerJoinFV) {
    if (!this.data) return;
    try {
      if (this.data.event.searchAccess === SearchAccess.private) {
        await api.gameEvents.joinFriendFree(this.data.event.hash, friend);
        await this.getEvent(this.data.event.hash);
      } else {
        const beforePay = await api.gameEvents.joinFriend(
          this.data.event.hash,
          friend
        );
        browserHistory.push(
          `/event/pay/${this.data.event.hash}/order/${beforePay.order.id}`
        );
      }
    } catch (e) {
      alert('Не удалось присоединить партнера к игре');
      throw e;
    }
  }

  async cancelEvent() {
    if (!this.data) return;
    try {
      await api.gameEvents.cancelEvent(this.data.event.hash);
      await this.getEvent(this.data.event.hash);
    } catch (e) {
      alert('Не удалось отменить игру');
      throw e;
    }
  }

  async exitFromEvent() {
    if (!this.data) return;
    try {
      await api.gameEvents.exitEvent(this.data.event.hash);
    } catch (e) {
      alert('Не удалось выйти из игры');
      throw e;
    }
    try {
      await this.getEvent(this.data.event.hash);
    } catch (e) {
      browserHistory.push('/');
    }
  }

  async kickFriendFromEvent(playerId: Player['id']) {
    if (!this.data) return;
    try {
      await api.gameEvents.kickFriendFromEvent(this.data.event.hash, playerId);
    }
    catch (e) {
      alert('Не удалось удалить игрока');
      throw e;
    }
    try {
      await this.getEvent(this.data.event.hash);
    } catch (e) {
      throw e;
    }
  }

  isCreator() {
    if (!this.data) return false;
    return this.data.event.creatorId === UserStore.userData?.user?.id;
  }

  async sendTicket(data: SendTicketRequest) {
    if (!this.data) return;
    await api.gameEvents.sendTicket(this.data.event.hash, data);
  }

  get canUseChat() {
    if (!this.data) return false;
    return !!this.data.players.find(
      (p) => p.user.id === UserStore.userData?.user?.id
    );
  }


  clearChat() {
    this.chatMessages = [];
    this.chatNextPageLink = null;
  }
  get getNextPageLink() {
    return this.chatNextPageLink;
  }
  async getChat() {
    if (!this.data || !this.data.event.hash) return false;
    const { results } = await api.chat.getChat(this.data.event.hash);
    this.getChatDone(results);
  }
  async sendMessage(message: string) {
    if (!this.data?.event) return;
    const { results } = await api.chat.sendMessage(message, this.data.event.hash);
    this.getChatDone(results);
  }
  getChatDone(data: ChatMessage[]) {
    const filteredMessages = this.filterChatMessages(data);
    if (filteredMessages.length > 0) {
      this.chatMessages = this.chatMessages.concat(filteredMessages).sort((a,b) => b.id - a.id).reverse();
    }
  }
  setChatNextPageLink(data: string | null) {
    this.chatNextPageLink = data;
  }
  filterChatMessages(newMessages: ChatMessage[]) {
    const msgIds = this.chatMessages.map((m) => m.id);
    return newMessages.filter((msg) => !msgIds.includes(msg.id));
  }
  async initChat() {
    if (!this.data || !this.data.event.hash) return false;
    const { next } = await api.chat.getChat(this.data.event.hash);
    this.setChatNextPageLink(next);
    await this.getChat();
  }
  async getNextPage(link: string) {
    const data = await api.chat.getNextChat(link);
    this.getChatDone(data.results);
    this.setChatNextPageLink(data.next);
    this.setWaitScroll(true);
  }
  setWaitScroll(value: boolean) {
    this.waitScroll = value;
  }



  compareChatDates(first: string, second: string) {
    const fd = new Date(first),
      sd = new Date(second);
    return (
      fd.getFullYear() === sd.getFullYear() &&
      fd.getMonth() === sd.getMonth() &&
      fd.getDate() === sd.getDate()
    );
  }

  get groupedMessages(): ChatGroupedByDay[] {
    const grouped: ChatGroupedByDay[] = [];
    let dayIndex = 0;
    const fillIndex = (cm: ChatMessage) => {
      grouped[dayIndex] = {
        firstMessageDate: cm.createdAt,
        chatMessages: [cm],
      };
    };
    for (const cm of this.chatMessages) {
      if (grouped[dayIndex] === undefined) {
        fillIndex(cm);
        continue;
      }
      if (
        this.compareChatDates(grouped[dayIndex].firstMessageDate, cm.createdAt)
      ) {
        grouped[dayIndex].chatMessages.push(cm);
      } else {
        dayIndex += 1;
        fillIndex(cm);
      }
    }
    return grouped;
  }

  get joinErrorMessage() {
    let game = '';
    if (this.data) {
      game = ` (${this.data.arena.name}, ${moment(
        this.data.event.startAt
      ).format('YYYY-MM-DDTHH:mm:ss')})`;
    }
    return `...К сожалению, вы не смогли присоединиться к игре${game}. Затраченные средства вернутся вам на карту в ближайшее время`;
  }

  get isFullyPaid() {
    if (!this.data) return false;
    return this.data.event.searchAccess === SearchAccess.private;
  }
}

export default new EventViewStore();
