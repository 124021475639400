import React from "react";
import styles from "./GrayRoundedSelect.module.scss";
import Select, {SelectProps} from "./Select";

type Props = Omit<SelectProps, "styles">;

export const GrayRoundedSelect: React.FC<Props> = (props: Props) => {
    return (
        <Select {...props} styles={styles} />
    )
}