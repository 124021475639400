import URLParse from 'url-parse';

interface QueryItem {
  queryKey: string,
  queryValue: string | boolean | null
}

export class QueryUrlConstructor {
  currentUrl: any;

  constructor(currentUrlInstance = URLParse(window.location.href, true)) {
    this.currentUrl = currentUrlInstance;
  }

  applyQuery(queryKey: QueryItem['queryKey'], queryValue: QueryItem['queryValue']) {
    const query = this.currentUrl.query;

    if (queryValue) {
      query[queryKey] = queryValue;
    } else {
      this._cleanQuery(queryKey);
    }
    this.currentUrl.set('query', query);
    return this.currentUrl.href;
  }

  applyQueryArr(queryArr: QueryItem[]) {
    const query = this.currentUrl.query;

    queryArr.forEach((item) => {
      if (item.queryValue) {
        query[item.queryKey] = item.queryValue;
      } else {
        this._cleanQuery(item.queryKey);
      }
    });

    this.currentUrl.set('query', query);
    return this.currentUrl.href;
  }

  getQuery(queryKey: string): string {
    const query = this.currentUrl.query[queryKey];
    return query ? query : '';
  }

  _cleanQuery(queryKey: string) {
    delete this.currentUrl.query[queryKey];
  }
}

export const getQuery = (queryKey: string) =>
  new QueryUrlConstructor().getQuery(queryKey);

export const applyQuery = (
  queryKey: QueryItem['queryKey'],
  queryValue: QueryItem['queryValue']
) => new QueryUrlConstructor().applyQuery(queryKey, queryValue);

export const applyQueryArr = (
    queryArr: QueryItem[]
) => new QueryUrlConstructor().applyQueryArr(queryArr);

export class FilterQueryUrlConstructor extends QueryUrlConstructor {
  getFiltersQuery(): string {
    const filters = this.currentUrl.query.filters;
    return filters ? filters : '';
  }

  getFiltersList(): string[] {
    const filtersQuery = this.getFiltersQuery();
    return filtersQuery ? filtersQuery.split(',') : [];
  }

  getFiltersQueryWithFilter(filter: string): string {
    const filtersList = this.getFiltersList();
    if (!filtersList.includes(filter)) {
      filtersList.push(filter);
    }
    return filtersList.join(',');
  }

  getFiltersQueryWithoutFilter(filter: string): string {
    let filtersList = this.getFiltersList();
    if (filtersList.includes(filter)) {
      filtersList = filtersList.filter((f) => f !== filter);
    }
    return filtersList.join(',');
  }

  applyFiltersQuery(code: string | null) {
    return this.applyQuery('filters', code);
  }

  applySingleFilter(filter: string) {
    return this.applyFiltersQuery(filter);
  }

  applyMultipleFilter(filter: string) {
    const query = this.getFiltersQueryWithFilter(filter);
    return this.applyFiltersQuery(query);
  }

  removeMultipleFilter(filter: string) {
    const query = this.getFiltersQueryWithoutFilter(filter);
    return this.applyFiltersQuery(query);
  }

  removeAllFilters() {
    return this.applyFiltersQuery(null);
  }
}
