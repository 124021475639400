import { observer } from 'mobx-react';
import React from 'react';
import { useForm } from 'react-hook-form';
import api from '../../../../api';
import CooperationReqestData from '../../../../models/requests/cooperation';
import ModalStore from '../../../../stores/ModalStore';
import SuccessCooperationModal from './SuccessCooperationModal';
import { FormField } from '../../../Hoc/Form/Field';
import { FormInput } from '../../../UI/FormWithLabelsUI/FormInput/FormInput';
import Form from '../../../Hoc/Form/Form';
import Button from '../../../UI/Button/Button';

// 22-30  - 1.30    выбор формы, добавление автоформатирования

const Cooperation: React.FC = observer(() => {
  const form = useForm<CooperationReqestData>();
  return (
    <>
      <h2 className="cooperation__title f-bold">
        Приглашаем к сотрудничеству{' '}
        <div className="color-orange">владельцев площадок и тренеров</div>
      </h2>
      <div className="cooperation__text">
        Присоединяйтесь к самой крупной сети игроков, готовых придти к вам уже
        сегодня!
      </div>
      <Form<CooperationReqestData>
        formReturn={form}
        onSubmit={async (data: CooperationReqestData) => {
          await api.home.sendCooperation(data);
          ModalStore.showModal(<SuccessCooperationModal />);
        }}
        className="cooperation-form"
      >
        <FormField
          name="name"
          type="text"
          label="Ваше имя"
          className="cooperation-form__field"
          Component={FormInput}
          showErrorInComponent
        />
        <FormField
          name="email_or_phone"
          type="text"
          label="E-mail или телефон"
          className="cooperation-form__field"
          Component={FormInput}
          showErrorInComponent
        />
        <Button
          type="submit"
          buttonText="Отправить заявку"
          className="cooperation-form__submit"
        />
      </Form>
    </>
  );
});

export default Cooperation;
