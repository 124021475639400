import api from 'api';
import browserHistory from 'browserHistory';
import {
  defaultArena, defaultEventType,
  defaultGame,
  defaultLevel,
} from 'components/common/gameDefaults';
import { timePeriodList } from 'components/Pages/Search/Filters/filtersConstants';
import { searchSorts } from 'components/Pages/Search/Filters/SortFilter/SortFilter';
import { makeAutoObservable } from 'mobx';
import Arena from 'models/entities/arena';
import { PeriodData } from 'models/entities/eventPeriod';
import PlayerLevel from 'models/entities/playerLevel';
import Sport from 'models/entities/sport';
import { RequestState } from 'models/responses/common';
import SearchEventsResponse, {EventsFiltersResp} from 'models/responses/searchEvents';
import moment, { Moment } from 'moment';
import { LocationUpdater } from 'utils/locationUpdater';
import { applyQuery, applyQueryArr, getQuery } from 'utils/urlConstructors';
import { TabId } from '../models/ui/searchTab';
import { getGameTimes } from 'moment/utils';
import EventInfo from '../models/entities/eventInfo';
import { Ad } from '../models/entities/ad';
import {dateFilterQueryFormat} from "../utils/const";
import {SelectOption} from "../models/entities/SelectOption";
import EventType from "../models/entities/eventType";
import CardType, {CardTypes} from "../models/entities/cardType";
import UserStore from "./UserStore";

enum FilterQuery {
  event_type = 'event_type',
  sport = 'sport',
  event_date = 'event_date',
  player_level = 'player_level',
  arena = 'arena',
  sort = 'sort'
}

class SearchPageStore {
  constructor() {
    makeAutoObservable(this);
    this.today = moment().startOf('day').toDate();
    this.dateFilter = this.getDefaultDateFilterOrNone() || this.today;
    this.sportFilter = this.getDefaultSportFilterOrNone();
    this.playerLevelFilter = this.getPlayerLevelFilterOrNone();
    this.arenaFilter = this.getDefaultArenaFilterOrNone();
    this.eventTypeFilter = this.getDefaultEventTypeFilterOrNone();
  }

  private readonly adIndexIncrement = 3;
  private readonly firstAdIndex = 1;
  events?: SearchEventsResponse | null;
  requestState: RequestState = 'initial';
  today: Date;
  dateFilter: Date;
  sportFilter: Sport['id'] | null;
  playerLevelFilter: PlayerLevel['id'] | null;
  eventTypeFilter: EventType['id'] | null;
  arenaFilter: Arena['id'] | null;
  sortFilter: typeof searchSorts[0]['legacy'] = 'time';
  periodDataIndex: number = -1;

  filters?: EventsFiltersResp;

  async getFilters() {
    try {
      const r = await api.gameEvents.getFilters();
      this.getFiltersDone(r);
    }
    catch (e) {
      console.log(e);
    }
  }

  getFiltersDone(data: EventsFiltersResp) {
    this.filters = data;
  };

  setDefaultFilters() {
    this.today = UserStore.getCurrentTime().startOf('day').toDate();
    this.dateFilter = this.getDefaultDateFilterOrNone() || this.today;
    this.sportFilter = this.getDefaultSportFilterOrNone();
    this.playerLevelFilter = this.getPlayerLevelFilterOrNone();
    this.arenaFilter = this.getDefaultArenaFilterOrNone();
    this.eventTypeFilter = this.getDefaultEventTypeFilterOrNone();
  }

  getDefaultDateFilterOrNone() {
    const dateFilterQuery = moment(
      getQuery('event_date'),
      dateFilterQueryFormat
    );
    return dateFilterQuery.isValid() ? dateFilterQuery.toDate() : null;
  }

  getDefaultSportFilterOrNone() {
    const sportId = Number(getQuery(FilterQuery.sport));
    return sportId && !isNaN(sportId) ? sportId : null;
  }

  getPlayerLevelFilterOrNone() {
    const playerLevelId = Number(getQuery(FilterQuery.player_level));
    return playerLevelId && !isNaN(playerLevelId) ? playerLevelId : null;
  }

  getDefaultArenaFilterOrNone() {
    const arenaId = Number(getQuery(FilterQuery.arena));
    return arenaId && !isNaN(arenaId) ? arenaId : null;
  }

  getDefaultEventTypeFilterOrNone() {
    const type = getQuery(FilterQuery.event_type);
    return type || null;
  }

  get defaultTab(): TabId {
    const tab = getQuery('tab') || 'game';
    return tab as TabId;
  }

  private fillWithAds(array: SearchEventsResponse['events'] | undefined): CardType<EventInfo | Ad>[] {
    if (!array || !this.events) {
      return [];
    }

    const { ads } = this.events;
    const arrayWithAds = [];
    const adsIterator = ads.values();
    let adInsertionIndex = this.firstAdIndex;

    for (let i = 0; i <= array.length; i++) {
      const nextIndex = arrayWithAds.length;

      if (nextIndex === adInsertionIndex) {
        const ad = adsIterator.next().value;

        if (ad) {
          arrayWithAds.push({
            type: CardTypes.ad,
            data: ad
          });
          adInsertionIndex += this.adIndexIncrement;
        }
      }

      if (i < array.length) {
        arrayWithAds.push({
          type: CardTypes.event,
          data: array[i]
        });
      }
    }

    return arrayWithAds;
  }

  getEventsWithAds(): CardType<EventInfo | Ad>[] {
    return this.fillWithAds(this.events?.events);
  }

  setDateFilter(date: Date) {
    this.dateFilter = date;
    this.getGameEvents();
    LocationUpdater.replaceLocation(
      applyQuery(
        FilterQuery.event_date,
        moment(this.dateFilter).format(dateFilterQueryFormat)
      )
    );
  }

  setSportFilter = (value: Sport['id']) => {
    const sportId = value === 0 ? null : value;
    this.sportFilter = sportId;
    this.getGameEvents();
    LocationUpdater.replaceLocation(
        applyQuery(FilterQuery.sport, sportId ? String(sportId) : null)
    );
  };

  getCurrentSportOrNone() {
    if (!this.events) return null;
    return (
      this.events.sports.find((s) => s.id === this.sportFilter) || defaultGame
    );
  }

  setPlayerLevelFilter = (value: PlayerLevel['id']) => {
    const plId = value === 0 ? null : value;
    this.playerLevelFilter = plId;
    this.getGameEvents();
    LocationUpdater.replaceLocation(
        applyQuery(FilterQuery.player_level, plId ? String(plId) : null)
    );
  };

  getCurrentPlayerLevelOrNone() {
    if (!this.events) return null;
    const level = this.events.playerLevels.find(
      (pl) => pl.id === this.playerLevelFilter
    );
    return level || defaultLevel;
  }

  setArenaFilter = (value: Arena['id']) => {
    const id = value === 0 ? null : value;
    this.arenaFilter = id;
    this.getGameEvents();
    LocationUpdater.replaceLocation(
        applyQuery(FilterQuery.arena, id ? String(id) : null)
    );
  };

  getCurrentArenaOrNone() {
    if (!this.events) return null;
    return (
      this.events.arenas.find((a) => a.id === this.arenaFilter) || defaultArena
    );
  }

  setSortFilter = (s: typeof searchSorts[0]) => {
    this.sortFilter = s.legacy;
    this.getGameEvents();
    LocationUpdater.replaceLocation(applyQuery(FilterQuery.sort, s.legacy));
  };

  get currentSortFilter(): typeof searchSorts[0] | undefined {
    return searchSorts.find(
      (s: typeof searchSorts[0]) => s.legacy === this.sortFilter
    );
  }

  setEventTypeFilter = (option: SelectOption<EventType['id']>) => {
    const value = option.id === 'all' ? null : option.id;
    this.eventTypeFilter = value;
    this.getGameEvents();
    LocationUpdater.replaceLocation(
        applyQuery(
            FilterQuery.event_type,
            value ? String(value) : null
        )
    );
  };

  getCurrentEventTypeOrNone() {
    if (!this.eventTypeFilter) return defaultEventType;
    else return this.filters?.eventTypes.find(item => item.id === this.eventTypeFilter);
  }

  getEventTypesOptions() {
    return this.filters?.eventTypes ? [defaultEventType, ...this.filters?.eventTypes] : [];
  }

  resetFilters() {
    this.sportFilter = null;
    this.arenaFilter = null;
    this.playerLevelFilter = null;
    this.eventTypeFilter = null;
    LocationUpdater.replaceLocation(
        applyQueryArr([
          { queryKey: FilterQuery.sport, queryValue: null },
          { queryKey: FilterQuery.arena, queryValue: null },
          { queryKey: FilterQuery.player_level, queryValue: null },
          { queryKey: FilterQuery.event_type, queryValue: null }
        ])
    );
    this.getGameEvents();
  }

  // загрузка данных для страницы

  async getGameEvents() {
    if (this.requestState === 'pending' || !this.dateFilter) return;
    this.requestState = 'pending';
    try {
      const events = await api.gameEvents.getEvents(
        this.dateFilter,
        this.currentSortFilter,
        this.sportFilter,
        this.arenaFilter,
        this.getCurrentPlayerLevelOrNone()?.label,
        this.eventTypeFilter
      );
      this.getGameEventsDone(events);
    } catch {
      this.getGameEventsError();
    }
  }

  getGameEventsDone = (e: SearchEventsResponse) => {
    this.events = e;
    this.requestState = 'done';
  };

  getGameEventsError = () => {
    this.requestState = 'error';
  };

  // Создание события
  getEventDurations(periodData: PeriodData, startTime?: Moment) {
    const minutes = moment(periodData.endTime).diff(
      startTime ? startTime : moment(periodData.startTime),
      'minutes',
      true
    );
    const periodsCount = Math.round(minutes / 30);
    return timePeriodList.filter((t) => t.id <= periodsCount);
  }

  getStartDates(periodData: PeriodData, periodsCountOffset = 0) {
    const startDate = moment(periodData.startTime);
    const endDate = moment(periodData.endTime);
    return getGameTimes(startDate, endDate, periodsCountOffset);
  }

  check24HoursLimits(startDate: Moment) {
    const hours = startDate.diff(moment(Date.now()), 'hours', true);
    return hours > 24;
  }

  async prepareEvent(
    periodData: PeriodData,
    startDate: Moment,
    periodsCount: number
  ) {
    if (this.requestState === 'pending') return;
    this.requestState = 'pending';
    try {
      const preparedEvent = await api.gameEvents.prepareEvent(
        startDate.format('YYYY-MM-DDTHH:mm:ss'),
        periodData.groundId,
        periodsCount
      );
      this.requestState = 'done';
      browserHistory.push(`/event/create/${preparedEvent.event.hash}`);
    } catch (e: any) {
      this.requestState = 'error';
      await this.getGameEvents();
    }
  }

  setCurrentPeriodData(index: number) {
    this.periodDataIndex = index;
  }

  resetData() {
    this.events = null;
    this.requestState = 'initial';
  }

  get activeFiltersCount() {
    return [
        this.sportFilter,
        this.eventTypeFilter,
        this.arenaFilter,
        this.playerLevelFilter
    ].filter(item => !!item).length
  }

  allFiltersCleaning() {
    this.sportFilter = null;
    this.eventTypeFilter = null;
    this.arenaFilter = null;
    this.playerLevelFilter = null;
    LocationUpdater.replaceLocation(
        applyQueryArr([
          { queryKey: FilterQuery.sport, queryValue: null },
          { queryKey: FilterQuery.arena, queryValue: null },
          { queryKey: FilterQuery.player_level, queryValue: null },
          { queryKey: FilterQuery.event_type, queryValue: null }
        ])
    );
    this.getGameEvents();
  }

  setToday(date: Date) {
    this.today = date;
    this.dateFilter = date;
  }

}

export default new SearchPageStore();
