import backendApi from 'api/config';
import Order from 'models/entities/order';
import Voucher from 'models/entities/voucher';

class VouchersAPI {
  prefix = '/vouchers/';

  async apply(voucher: string, orderId: Order['id']): Promise<Voucher> {
    const r = await backendApi.post(`${this.prefix}apply`, {
      voucher,
      orderId,
    });
    return r.data;
  }

  async cancel(userVoucherId: Voucher['userVoucherId']) {
    const r = await backendApi.post(`${this.prefix}cancel`, {
      userVoucherId,
    });
    return r.data;
  }
}

const vouchersAPI = new VouchersAPI();
export default vouchersAPI;
