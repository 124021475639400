import { TabProps } from 'components/UI/Tabs/models';
import classNames from "classnames";
import css from './Tabs.module.css';

function TabPanel({ tabs, index, children, className }: TabProps) {
  return tabs.currentTab === index ? <div className={classNames(
      className,
      css.tabPanel
  )}>{children}</div> : null;
}

export default TabPanel;
