import moment from "moment";
import css from './MonthSwitcher.module.scss';
import NavLink from "../../../UI/NavLink/NavLink";
import CampsSearchPageStore from "../../../../stores/CampsSearchPageStore";
import {WithChildren} from "../../../../models/technical/withProps";
import {Month} from "../../../../stores/CampsSearchPageStore";
import {observer} from "mobx-react";

interface MonthSwitcherProps extends WithChildren {}

const months = [{
    name: 'Все',
    index: 0
}, ...moment.months().map((month, index) => ({
    name: month,
    index: index + 1
}))]

const MonthSwitcher:React.FC<MonthSwitcherProps> = ({
    className
                                                    }) => {

    const active = CampsSearchPageStore.month ? CampsSearchPageStore.month : 0;
    const onClick = (date: Month) => {
        CampsSearchPageStore.setMonthFilter(date === 0 ? null : date);
    }

    return (
        <div className={`${css.container} ${className}`}>
            {months.map(month =>
                <NavLink
                    key={month.index}
                    onClick={() => {
                        onClick(month.index);
                    }}
                    className={`${css.item}`}
                    active={month.index === active}
                >{month.name}</NavLink>
            )}
        </div>
    );
};

export default observer(MonthSwitcher);