import React, {useState} from "react";
import css from './CampsSearchPage.module.scss';
import commonCss from 'css/Common.module.css';
import MonthSwitcher from "../MonthSwitcher/MonthSwitcher";
import YearSwitcher from "../YearSwitcher/YearSwitcher";
import {observer} from "mobx-react";
import Filter from "../../../UI/Filter/Filter";
import GamesSection from "../GamesSection/GamesSection";
import OrganizersFilter from "../Filters/Organizers/Organizers";
import SportsFilter from "../Filters/Sports/Sports";
import CountriesFilter from "../Filters/Countries/Countries";
import FilterMobButton from "../../../UI/Filter/MobButton/FilterMobButton";
import CampsSearchPageStore from "../../../../stores/CampsSearchPageStore";
import Page from "../../../Hoc/Page/Page";

const CampsSearchPage:React.FC = () => {

    const [filterOpenedOnMob, setFilterOpenedOnMob] = useState<boolean>(false);

    const closeFilter = () => {
        setFilterOpenedOnMob(false);
    }

    const openFilter = () => {
        setFilterOpenedOnMob(true);
    }

    const activeFiltersCount = CampsSearchPageStore.activeFiltersCount;
    const allFiltersCleaning = () => {
        CampsSearchPageStore.allFiltersCleaning()
    };

    return (
        <Page>
            <div className={css.pageHeader}>
                <div className={commonCss.wrap}>
                    <h1 className={css.pageTitle}>Спортивные кемпы</h1>
                    <div className={css.yearsRow}>
                        <YearSwitcher className={css.yearsSwitcher}/>
                        <FilterMobButton
                            onClick={openFilter}
                            className={css.filterMob}
                            activeFiltersCount={activeFiltersCount}
                        />
                    </div>
                    <MonthSwitcher className={css.monthSwitcher}/>
                </div>
            </div>
            <div className={css.mainSection}>
                <div className={commonCss.wrap}>
                    <div className={css.mainContainer}>
                        <div className={css.searchResults}>
                            <GamesSection/>
                        </div>
                        <Filter
                            onClose={closeFilter}
                            openedOnMob={filterOpenedOnMob}
                            className={css.filter}
                            activeFiltersCount={activeFiltersCount}
                            allCleaning={allFiltersCleaning}
                        >
                            <OrganizersFilter/>
                            <SportsFilter/>
                            <CountriesFilter/>
                        </Filter>
                    </div>
                </div>
            </div>
        </Page>
    );
};

export default observer(CampsSearchPage);