import { makeAutoObservable } from 'mobx';

class HistoryStore {

  scrollPosition: number | null
  shouldScrolling: boolean

  constructor() {
    makeAutoObservable(this);
    this.scrollPosition = null;
    this.shouldScrolling = false;
  }

  setScrollPosition(data: number) {
    this.scrollPosition = data;
  }
  setShouldScrolling(data: boolean) {
    this.shouldScrolling = data;
  }
  resetScrollingData() {
    this.scrollPosition = null;
    this.shouldScrolling = false;
  }

}

const historyStore = new HistoryStore();

export default historyStore;
