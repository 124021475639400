import ClipboardJS from 'clipboard';
import useOnClickOutside from 'components/common/hooks/onClickOutside';
import {cancelHint} from 'components/Pages/Event/View/hints';
import BackLink from 'components/Pages/Profile/LKLayout/BackLink/BackLink';
import {EventStatus, SearchAccess} from 'enums/event';
import {observer} from 'mobx-react';
import React, {useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router';
import ReactTooltip from 'react-tooltip';
import EventViewStore from 'stores/EventViewStore';
import datetime from 'utils/datetime';
import {declOfNum} from 'utils/translations';
import ownCss from './ViewEventPage.module.scss';
import Chat from './Chat/Chat';
import {PlayerCard} from './PlayerCard/PlayerCard';
import PartnerJoin from './PartnerJoin/PartnerJoin';
import OrangeLink from '../../../UI/CSS/OrangeLink/OrangeLink';
import {NavLink} from 'react-router-dom';
import ConfirmExitEvent from "./ConfirmExitEvent/ConfirmExitEvent";
import UserStore from "../../../../stores/UserStore";
import ModalStore from "../../../../stores/ModalStore";
import LoginForm from "../../shared/LoginForm/LoginForm";
import authStore from "../../../../stores/AuthorizationStore";
import {Gender} from "../../../../models/entities/gender";
import GenderAlertPopup from "./GenderAlertPopup";
import {gendersText} from "../../../../utils/const";
import CoachCard from "./Coach/CoachCard/CoachCard";
import CoachPopup from "./Coach/CoachPopup/CoachPopup";
import cssCoachPopup from './Coach/CoachPopup/CoachPopup.module.scss';
import ErrorPage from "../../Error/ErrorPage";
import SpinnerWrapper from "../../../UI/Loader/SpinnerWrapper/SpinnerWrapper";
import Page from "../../../Hoc/Page/Page";
import commonCss from 'css/Common.module.css';
import {isTraining} from "../../../../utils/functions";
import browserHistory from "../../../../browserHistory";
import {Tabs} from "../../Profile/ProfilePage/model";
import {SearchResultGrid} from "../../Search/SearchResults/SearchResultGrid/SearchResultGrid";
import EventCard from "../../../common/EventCard/EventCard";
import CityChangedAlertModal from "./CityChangedAlertModal/CityChangedAlertModal";
import CityStore from "../../../../stores/CityStore";

const recommendationTab = `/cabinet?tabId=${Tabs.recommendations}`;

function ViewEventPage() {

  const { hash } = useParams<{ hash: string }>();

  useEffect(() => {
    let params;
    // @ts-ignore
    if (`${browserHistory.location.state?.prevLocation}${browserHistory.location.state?.prevParams}` === recommendationTab) {
      params = {
        source: 'recommendations'
      }
    }
    EventViewStore.getEvent(hash, params);
    const cb = new ClipboardJS('.ClipboardJS');

    return () => {
      cb.destroy();
      EventViewStore.resetEvent();
    };
  }, [hash]);

  useEffect(() => {
    CityStore.setCitySwitcherDisabled(true);
    return () => {
      CityStore.setCitySwitcherDisabled(false);
    }
  }, []);

  useEffect(() => {
    if (EventViewStore.data && EventViewStore.data?.city.id !== UserStore.currentCity?.id) {
      ModalStore.showModal(<CityChangedAlertModal cityForChange={EventViewStore.data.city}/>);
      ModalStore.setCloseCallback(() => {
        browserHistory.push('/');
      })
    }
  }, [EventViewStore.data]);

  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => setShareOpen(false));

  const [isShareOpen, setShareOpen] = useState(false);

  const eventLink = `${window.location.href}`;

  const data = EventViewStore.data;
  const loading = EventViewStore.requestState === 'pending';
  const pageNotExist = EventViewStore.eventNotExist;

  if (pageNotExist) return <ErrorPage/>;

  const event = data?.event;
  const playersCount = data?.players.length || 0;

  const whoCanInvite = (() => {
    switch (event?.searchAccess) {
      case SearchAccess.private:
        return 'обладатели ссылки';
      case SearchAccess.vip:
        return 'группа "Хард"';
      default:
        return 'Все пользователи Sploteam';
    }
  })();

  const joinToGame = async (paymentOnPlace?: boolean) => {
    if (!UserStore.userData?.user) {
      authStore.setLoginCallback(async () => {
        await EventViewStore.getEvent(hash);
        if (EventViewStore.data && EventViewStore.data.event.joinEventAvailable) {
          await EventViewStore.join()
        }
      });
      ModalStore.showModal(<LoginForm />);
    }
    else {
      if (event?.gender && UserStore.userData.user.gender && event.gender !== Gender.any && event.gender !== UserStore.userData.user.gender) {
        ModalStore.showModal(
            <GenderAlertPopup
                type={'notMatch'}
                genderEvent={event.gender}
                genderUser={UserStore.userData.user.gender}
            />
        );
      }
      else if (event?.gender && event?.gender !== Gender.any && !UserStore.userData.user.gender) {
        ModalStore.showModal(
            <GenderAlertPopup
                type={'notSet'}
                genderEvent={event?.gender}
                genderUser={UserStore.userData.user.gender}
            />
        );
      }
      else {
        if (!paymentOnPlace) await EventViewStore.join();
        else EventViewStore.joinEventOfflinePay();
      }
    }
  };

  const coachClick = () => {
    if (event?.coach) {
      ModalStore.showModal(<CoachPopup data={event.coach}/>, cssCoachPopup.modal);
    }
  };

  return (
    <Page>
      {loading && <SpinnerWrapper/>}
      {data && event ?
          <>
            {event.status === EventStatus.passed && (
                <div className={`${ownCss.pageAlert} ${ownCss.success}`}>
                  {event.searchAccess === SearchAccess.training ? 'Тренировка успешно состоялась 👌😉' : 'Игра успешно состоялась 👌😉'}
                </div>
            )}
            {event.status === EventStatus.canceled && (
                <div className={`${ownCss.pageAlert} ${ownCss.error}`}>
                  Игра отменена 😪
                </div>
            )}
            <div className={commonCss.wrap}>
              <BackLink scrollToOld={true}/>
              <div className={ownCss.container}>
                <h1 className={ownCss.pageTitle}>
                  {data.sport.name}
                  {data.ground.gameFormat &&
                      `(${data.ground.gameFormat.name})`}
                </h1>
                <div className={ownCss.gameInfoRow}>
                  <div className={ownCss.gameInfoContainer}>
                    <div className={ownCss.gameInfoPlaceHead}>
                      <div className={ownCss.gameInfoPlaceTitle}>Место и время</div>
                      <div
                          ref={ref}
                          className={`dropdown ${ownCss.share}  ${
                              isShareOpen && ownCss.open
                          } for-pad for-desktop`}
                      >
                        <button
                            className={`${ownCss.shareButton}`}
                            onClick={() => setShareOpen(!isShareOpen)}
                        >
                          Поделиться
                        </button>
                        <div
                            className={`${ownCss.shareDropdown} ${
                                isShareOpen && ownCss.openDropdown
                            }`}
                        >
                          <p>Отправить ссылку</p>
                          <div className={ownCss.shareDropdown__links}>
                            <a
                                href={`http://vk.com/share.php?url=${eventLink}`}
                                target="_blank noreferrer"
                            >
                              <img src={require('./img/vk.svg').default} alt="vk" />
                            </a>
                            <a
                                href={`whatsapp://send?text=${eventLink}`}
                                target="_blank noreferrer"
                            >
                              <img
                                  src={require('./img/whatsapp.svg').default}
                                  alt="watsapp"
                              />
                            </a>
                            <a
                                href={`https://telegram.me/share/url?url=${eventLink}`}
                                target="_blank noreferrer"
                            >
                              <img
                                  src={require('./img/telegram.svg').default}
                                  alt="telegram"
                              />
                            </a>
                          </div>
                          <div className={ownCss.shareDropdown__link}>
                            {window.location.href}
                          </div>
                          <div
                              className={`${ownCss.shareDropdown__copy} ClipboardJS`}
                              data-clipboard-text={eventLink}
                              onClick={() => setShareOpen(false)}
                          >
                            Скопировать ссылку
                          </div>
                        </div>
                      </div>
                    </div>
                    <ul className={ownCss.list}>
                      <li>
                        <span>Арена: </span>

                        <NavLink
                            to={`/arenas/${data.arena.slug}`}
                            style={{ textDecoration: 'none' }}
                        >
                          <OrangeLink style={{ display: 'inline-block' }}>{data.arena.name}</OrangeLink>{' '}
                        </NavLink>

                        {data.arena.address}
                      </li>
                      <li>
                        <span>Дата: </span>
                        {datetime.isToday(event.startAt)
                            ? 'Сегодня'
                            : datetime.prettify(event.startAt, 'DD MMMM')}
                      </li>
                      <li>
                        <span>Время: </span>С {datetime.getTime(event.startAt)} до{' '}
                        {datetime.getTime(event.endAt)}
                      </li>
                    </ul>
                  </div>
                  <div className={ownCss.gameInfoContainer}>
                    <div className={ownCss.gameInfoPlaceHead}>
                      <div className={ownCss.gameInfoPlaceTitle}>Об игре</div>
                      {event.cancelEventAvailable && (
                          <div
                              className={ownCss.cancelGame}
                              onClick={() => {
                                ModalStore.showModal(<ConfirmExitEvent
                                    confirmEvent={
                                      async () => {
                                        await EventViewStore.cancelEvent();
                                        ModalStore.hideModal();
                                      }
                                    }
                                    type={'cancelEvent'}
                                    cancelEvent={ModalStore.hideModal.bind(ModalStore)}/>)
                              }}
                          >
                            Отменить игру
                            <span className={ownCss.emoji}>😱</span>
                          </div>
                      )}
                    </div>
                    <ul className={ownCss.list}>
                      <li>
                        <span>Кто может присоединиться: </span>
                        {whoCanInvite}
                      </li>
                      {event.coach && (
                          <li className={ownCss.coachItem}>
                            <span>Тренер:</span>
                            <CoachCard
                                data={event.coach}
                                className={ownCss.coachBlock}
                                onClick={coachClick}
                            />
                          </li>
                      )}
                      <li className={ownCss.dotted}></li>
                      {event.playerLevel && (
                          <li>
                            <span>Уровень игроков: </span>
                            {event.playerLevel.name}
                          </li>
                      )}
                      <li>
                        {!!event.freeSlotsCount && (
                            <>
                              <span>Осталось мест:</span>
                              <b
                                  className={
                                      data.ground.gameFormat &&
                                      ownCss.infoCountPlayers
                                  }
                                  data-tip={
                                      data.ground.gameFormat &&
                                      cancelHint(
                                          data.ground.gameFormat
                                              .playerQuantity - playersCount
                                      )
                                  }
                              >
                                {' '}
                                {event.freeSlotsCount} из {event.needPlayersCount}{' '}
                              </b>
                              {data.ground.gameFormat && (
                                  <ReactTooltip
                                      type="dark"
                                      effect="solid"
                                      clickable={true}
                                      className={ownCss.tooltip}
                                  />
                              )}
                            </>
                        )}

                        {!event.freeSlotsCount && <span> Мест не осталось </span>}
                      </li>
                      {!EventViewStore.isFullyPaid && (
                          <li>
                            <span>Стоимость участия:</span>
                            {data.event.cost} ₽ <span>с игрока</span>
                          </li>
                      )}
                      {EventViewStore.isFullyPaid && (
                          <li>
                            <span>Создатель оплатил полную стоимость:</span>
                            {data.event.cost} ₽
                          </li>
                      )}
                      {data.event.gender && (
                          <li>
                            <span>Пол участников:</span>
                            {gendersText[data.event.gender]}
                          </li>
                      )}
                    </ul>
                  </div>
                </div>

                {data.event.comment && (
                    <div className={ownCss.commentSect}>
                      <div className={ownCss.gameInfoContainer}>
                        <div className={ownCss.gameInfoPlaceHead}>
                          <div className={ownCss.gameInfoPlaceTitle}>Комментарий</div>
                        </div>
                        <p>{data.event.comment}</p>
                      </div>
                    </div>
                )}

                <div className={ownCss.membersSect}>
                  <div className={ownCss.membersSect__title}>
                    <b>
                      {playersCount}{' '}
                      {declOfNum(playersCount, ['игрок', 'игрока', 'игроков'])}
                    </b>{' '}
                    в игре
                  </div>
                  <div className={ownCss.members}>
                    {event.joinFriendToEventAvailable && <PartnerJoin />}
                    {event.joinEventAvailable && (
                        <>
                          <div className={ownCss.join}>
                            <button
                                className={ownCss.btn}
                                onClick={() => {
                                  joinToGame();
                                }}
                            >Присоединиться к {isTraining(event.searchAccess) ? 'тренировке' : 'игре'}</button>
                            {EventViewStore.data?.event.searchAccess !== SearchAccess.private ? (
                                <div className={ownCss.price}>
                                  Стоимость участия:
                                  <b> {data.event.cost} ₽</b>
                                </div>
                            ) : ''}
                          </div>
                          {event.allowOfflinePayments && (
                              <div className={`${ownCss.join} ${ownCss.paymentOnPlace}`}>
                                <button
                                    className={`${ownCss.btn} ${ownCss.orange}`}
                                    onClick={() => {
                                      joinToGame(true);
                                    }}
                                >Присоединиться к {isTraining(event.searchAccess) ? 'тренировке' : 'игре'}</button>
                                <div className={`${ownCss.price} ${ownCss.black}`}>
                                  Оплата на месте:
                                  <b> {data.event.cost} ₽</b>
                                </div>
                              </div>
                          )}
                        </>
                    ) }
                    {data.players.map((p, i) => (
                        <PlayerCard player={p} index={i} key={i} />
                    ))}
                  </div>
                </div>

                <Chat />

                {!!EventViewStore.data?.recommendations?.length && (
                    <div className={ownCss.recommendationsSect}>
                      <div className={ownCss.recommendationsSect__title}>Подобрали похожие события для вас:</div>
                      <div className={ownCss.recommendations}>
                        <SearchResultGrid items={EventViewStore.data.recommendations.map(event => <EventCard key={event.event.id} event={event}/>)}/>
                      </div>
                    </div>
                )}

              </div>
            </div>
          </> :
          null
      }
    </Page>
  );
}

export default observer(ViewEventPage);
