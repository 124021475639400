import { FormField } from 'components/Hoc/Form/Field';
import Form from 'components/Hoc/Form/Form';
import Button from 'components/UI/Button/Button';
import FormInputGroup from 'components/UI/DefaultFormUI/FormGroup/FormInputGroup';
import FormSelectGroup from 'components/UI/DefaultFormUI/FormGroup/FormSelectGroup';
import { SearchAccess } from 'enums/event';
import { PlayerLevelEnum } from 'enums/player';
import { observer } from 'mobx-react';
import PlayerLevel from 'models/entities/playerLevel';
import Sport from 'models/entities/sport';
import SportFormat from 'models/entities/sportFormat';
import ReactSelectOption from 'models/ui/form/reactSelectOption';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useParams } from 'react-router';
import EventCreateStore, { defaultSelectOption } from 'stores/EventCreateStore';
import ErrorPage from "../../Error/ErrorPage";
import SpinnerWrapper from "../../../UI/Loader/SpinnerWrapper/SpinnerWrapper";
import Page from "../../../Hoc/Page/Page";
import commonCss from 'css/Common.module.css';
import css from './EventCreatePage.module.scss';

export interface EventCreateFormValues {
  comment: string;
  playerLevel: ReactSelectOption<PlayerLevel>;
  searchAccess: ReactSelectOption<SearchAccess>;
  sport: ReactSelectOption<Sport>;
  sportFormat: ReactSelectOption<SportFormat>;
}

const EventCreatePage: React.FC = observer(() => {
  const { hash } = useParams<{ hash: string }>();

  useEffect(() => {
    EventCreateStore.getData(hash);
    return () => EventCreateStore.reset();
  }, [hash]);

  const data = EventCreateStore.eventData;
  const loading = EventCreateStore.requestState === 'pending';
  const pageNotExist = EventCreateStore.eventNotExist;

  const form = useForm();
  const { watch, setValue, control } = form;
  const searchAccess: ReactSelectOption<SearchAccess> = useWatch({
      control,
      name: 'searchAccess',
    }),
    sport: ReactSelectOption<Sport> = useWatch({
      control,
      name: 'sport',
    }),
    sportFormat: ReactSelectOption<SportFormat> = useWatch({
      control,
      name: 'sportFormat',
    }),
    playerLevel: ReactSelectOption<PlayerLevelEnum> = useWatch({
      control,
      name: 'playerLevel',
    });

  React.useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'searchAccess') {
        setValue('sport', EventCreateStore.sportOptions[0]);
        setValue('sportFormat', '');
        setValue('playerLevel', '');
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  if (pageNotExist) return <ErrorPage/>;

  const arena = data?.arena;
  const event = data?.event;

  const startAt = moment(event?.startAt),
    endAt = moment(event?.endAt);

  const cost =
    searchAccess?.value &&
    EventCreateStore.getCost(
      searchAccess.value,
      sport?.value?.id,
      sportFormat?.value?.id
    );
  const showSubmit =
    searchAccess?.value &&
    (searchAccess.value === SearchAccess.private ||
      (searchAccess.value === SearchAccess.vip && sportFormat?.value) ||
      (sportFormat?.value && playerLevel?.value));

  return (
    <Page>
      {loading && <SpinnerWrapper/>}
      {data ?
          <div className={commonCss.wrap}>
            <h1 className={commonCss.pageTitle}>Создание игры</h1>
            <div className={css.createGame}>
              <div className={css.createGame__info}>
                Аренда площадки в {arena?.name} ({arena?.address}) на{' '}
                {startAt.format('DD.MM.YYYY')} с {startAt.format('HH:mm')} до{' '}
                {endAt.format('HH:mm')}
              </div>
              <div className={css.createGame__form}>
                <Form
                    onSubmit={EventCreateStore.submitEventCreateForm}
                    formReturn={form}
                >
                  <FormField
                      Component={FormSelectGroup}
                      name="searchAccess"
                      label="Помочь найти команды для спаринга или игроков"
                      defaultValue={defaultSelectOption}
                      options={EventCreateStore.searchAccessOptions}
                      getHint={EventCreateStore.getSearchAccessHint}
                  />
                  <FormField
                      Component={FormSelectGroup}
                      name="sport"
                      label="Во что будем играть?"
                      rules={{ required: 'Выберите значение' }}
                      options={EventCreateStore.sportOptions}
                      hidden={!searchAccess?.value}
                  />
                  <FormField
                      Component={FormSelectGroup}
                      name="sportFormat"
                      label="Формат игры"
                      options={EventCreateStore.sportFormatOptions(sport?.value?.id)}
                      hidden={
                          !searchAccess?.value ||
                          searchAccess.value === SearchAccess.private
                      }
                  />
                  <FormField
                      Component={FormSelectGroup}
                      name="playerLevel"
                      label="Уровень игроков"
                      options={EventCreateStore.playerLevelsOptions}
                      getHint={EventCreateStore.getPlayerLevelHint}
                      hidden={
                          !searchAccess?.value ||
                          searchAccess.value === SearchAccess.private ||
                          searchAccess.value === SearchAccess.vip
                      }
                  />
                  <FormField
                      Component={FormInputGroup}
                      name="comment"
                      label="Информация для участников игры"
                      hidden={!searchAccess?.value}
                  />
                  <div className="create-game__form__boot event-cost">
                    <div className={css.createGame__form__price}>
                      Стоимость составит <b>{cost || '-'}</b> руб.
                    </div>

                    {showSubmit && (
                        <Button
                            type="submit"
                            buttonText="Оплатить"
                        />
                    )}
                  </div>
                </Form>
              </div>
            </div>
          </div> :
          null
      }
    </Page>
  );
});

export default EventCreatePage;
