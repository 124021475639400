import { defaultGame } from 'components/common/gameDefaults';
import { observer } from 'mobx-react';
import { onSelectChange } from 'models/entities/SelectOption';
import React from 'react';
import SearchPageStore from 'stores/SearchPageStore';
import {GrayRoundedSelect} from "../../../../UI/Select/GrayRoundedSelect";
import {WithChildren} from "../../../../../models/technical/withProps";

interface SportFilterProps extends WithChildren {}

const SportFilter:React.FC<SportFilterProps> = observer(({
    className
                                                         }) => {
    const game = SearchPageStore.getCurrentSportOrNone();
    const onChange:onSelectChange = (data) => {
        SearchPageStore.setSportFilter(data.id);
    }
    return game && SearchPageStore.events ? (
      <GrayRoundedSelect
        value={game}
        options={[defaultGame, ...SearchPageStore.events.sports.map(item => ({
            id: item.id,
            name: item.name
        }))]}
        onChange={onChange}
        className={className}
        withCrossIcon={true}
        notFlex={true}
      />
    ) : null;
});

export default SportFilter;
