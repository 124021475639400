import React from 'react';
import Footer from '../Hoc/Footer/Footer';
import Header from '../Hoc/Header/Header';

const LegalPage: React.FC = () => {
  return (
    <>
      <Header />
      <div className="wrap content-page">
        <h4>1. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</h4>
        1.1 <b>Авторизация</b> – процесс идентификации Пользователя, путем ввода
        логина и пароля, для доступа к Личному кабинету.
        <br />
        1.2 <b>Sploteam</b> – ООО «Сплотим» (ОГРН 1197847050444, ИНН/КПП
        7811722553/781101001, адрес: 193091, г. Санкт-Петербург, Октябрьская
        наб., д. 6, лит. В, пом. 11 (86,87), которое обладает всеми необходимыми
        правами в отношении Сервиса, включая право на использование доменного
        имени Сервиса, и осуществляющее администрирование Сервиса.
        <br />
        1.3 <b>База данных</b> – совокупность сведений о Площадках, размещенная
        в Сервисе.
        <br />
        1.4 <b>Блокирование Личного кабинета</b> - наложение запрета на
        осуществление действий в Сервисе.
        <br />
        1.5 <b>Заказ</b> – совершение Пользователем действий по бронированию
        Спортивных объектов.
        <br />
        1.6 <b>Личный кабинет</b> - личный раздел Пользователя в Сервисе, доступ
        в который осуществляется с помощью Учетной записи.
        <br />
        1.7 <b>Мошеннические действия </b> - действия, совершенные с корыстной
        целью путем обмана и (или) злоупотребление доверием, и направленные на
        противоправное изъятие в свою пользу или других лиц имущества (денежных
        средств).
        <br />
        1.8 <b>Пользователь</b> – физическое лицо, прошедшее регистрацию в
        Сервисе.
        <br />
        1.9 <b>Предложение</b> – информация, представленная Площадкой, о
        Спортивном объекте (описание, свободное время для бронирования и пр.), а
        также о оказываемых дополнительных услугах.
        <br />
        1.10 <b>Сервис</b> – Интернет-сервис, расположенный в сети Интернет по
        адресу https://sploteam.com.&nbsp;
        <br />
        Также представляет собой совокупность объектов интеллектуальной
        собственности Sploteam, а именно программное обеспечение, базу данных,
        дизайн (графическое оформление), а также информацию, размещенную
        Sploteam .
        <br />
        1.11 <b>Спортивный объект</b> – пространство, представляемое Площадкой
        для проведения спортивных мероприятий (футбольное поле, теннисный корт,
        ледовый каток и т.д.).
        <br />
        1.12 <b>Тариф</b> — объем предоставляемых прав и услуг Пользователю.
        <br />
        1.13 <b>Политика конфиденциальности</b> - организационные и технические
        меры, принимаемые Sploteam, для защиты персональных данных от
        неправомерного доступа, уничтожения, изменения, блокирования,
        копирования, распространения, а также от иных неправомерных действий с
        ней третьих лиц.
        <br />
        11.14 <b>Соглашение</b> – настоящее Пользовательское соглашение,
        определяющие порядок и условие использование Сервисом.
        <br />
        11.15 <b>Учетная запись</b> - связка логина и пароля.
        <br />
        11.16 Любые иные термины, которые используются в Соглашении, но не
        вошедшие свое отражение в настоящем разделе толкуются с их буквальном
        смыслом, который вытекает из содержания Соглашения, либо из сложившейся
        в сети Интернет практики.
        <br />
        <h4>2. ПРЕДМЕТ СОГЛАШЕНИЯ</h4>
        2.1 По настоящему Соглашению Sploteam предоставляет Пользователю на
        условиях простой неисключительной лицензии право использования Сервиса.
        <br />
        2.2 Сервис предназначен для Заказа Пользователем услуг по аренде
        Спортивных объектов, предлагаемых Площадками в Сервисе.
        <br />
        2.3 При этом заключение Пользователем с Площадкой договоров аренды
        (проката, оказание услуг и пр.) осуществляется непосредственно на
        Сервисе.
        <br />
        2.4 В рамках заключенного на Сервисе договора аренды (проката, оказание
        услуг и пр.) Пользователь вступает в прямые договорные отношения с
        соответствующей Площадкой.
        <br />
        2.5 Существенные условия заключаемого Пользователем с Площадкой договора
        аренды (проката, оказание услуг и пр.) указываются в опубликованном на
        Сервисе блоке информации, предоставленный Площадкой в форме оформления
        Предложения на Сервисе.
        <br />
        2.6 Пользователь может использовать Сервис с базовым или с расширенным
        набором функциями.
        <br />
        2.7 Базовые функции Сервиса предоставляется Пользователю безвозмездно.
        <br />
        2.8 Расширенные функции Сервиса могут предоставляется Пользователю за
        вознаграждение согласно условиям выбранного Тарифа в Личном кабинете.
        <br />
        2.9 В зависимости от региона Пользователя все или некоторые функции
        Сервиса могут быть недоступны или ограничены.
        <br />
        2.10 Все существующие на данный момент функции Сервиса, а также любое
        развитие их и/или добавление новых является предметом Соглашения.
        <br />
        <h4>3. РЕГИСТРАЦИЯ И АВТОРИЗАЦИЯ</h4>
        3.1 Для возможности использования Сервисом Пользователю необходимо
        пройти регистрацию одним из способов:
        <br />
        * Совершить ряд последовательных действий по заполнению формы
        регистрации, а именно указать e-mail и имя, сформировать пароль, и
        нажать на кнопку «Создать аккаунт».
        <br />
        Пользователь самостоятельно формирует пароль для доступа к Личному
        кабинету. Тем не менее Sploteam вправе предъявлять требования к паролю
        при его формировании (длина, допустимые символы и т.д).
        <br />
        * Воспользоваться для регистрации учетными записями социальных сервисов
        Вконтакте, Facebook или прочих.
        <br />
        3.2 Стороны Соглашения гарантируют, что обладают необходимой
        правоспособностью и дееспособностью для заключения и исполнения
        настоящего Соглашения.
        <br />
        3.3 Совершение Пользователем одного из действий, указанного в п. 3.1.
        Соглашения, означает его принятие.
        <br />
        3.4 Учетная запись Пользователя является необходимой и достаточной
        информацией для доступа к Личному кабинету.
        <br />
        <h4>4. УСЛОВИЯ ИСПОЛЬЗОВАНИЯ СЕРВИСА</h4>
        <br />
        4.1 После регистрации Пользователь вправе использовать Сервис
        безвозмездно только с базовыми функциями.
        <br />
        4.2 Информация в Сервисе об услугах, оказываемых Площадками,
        предоставляется непосредственно самими Площадками.
        <br />
        4.3 В состав базовых функций Сервиса для Пользователя входит:
        <br />
        * Поиск в базе данных Площадок по заданным параметрам;
        <br />
        * Возможность ознакомиться с предложениями Площадок;
        <br />
        * Бронирование выбранных Площадок;
        <br />
        * Присоединение к игре на уже забронированных Площадках.
        <br />
        * И прочие функции доступные Пользователю на безвозмездной основе;
        <br />
        4.4 Sploteam по желанию Пользователя предоставляет последнему за
        вознаграждение неисключительные права на использование Сервиса с
        расширенными функциями.
        <br />
        4.5 Состав расширенных функций Сервиса для Пользователя определяется в
        соответствии с Тарифами, изложенными в Сервисе.
        <br />
        4.6 Стоимость вознаграждения за расширенные функции Сервиса может
        изменяться по одностороннему решению Sploteam. Стоимость оплаченного
        периода при этом изменению не подлежит.
        <br />
        4.7 Стороны договорились, что, если прекращение или расторжение
        Соглашения произошло ранее истечения срока использования расширенными
        функциями Сервиса, в течение которого использование расширенных функций
        Сервиса было оплачено, сумма произведенной Пользователем оплаты
        вознаграждения не подлежит возврату.
        <br />
        4.8 Вознаграждение за предоставление неисключительной лицензии не
        облагается НДС на основании п.п.26 п.2 ст.149 Налогового Кодекса
        Российской Федерации.
        <br />
        4.9 Sploteam вправе:
        <br />
        * Изменять в одностороннем порядке стоимость Тарифов.
        <br />
        * Принимать любые разумные меры по выявлению и пресечению мошеннических
        действий с использованием Сервиса.
        <br />
        * Запросить документы (копии), удостоверяющие личность Пользователя.
        <br />
        * По своему собственному усмотрению изменять (модерировать) или удалять
        любую публикуемую Пользователем информацию, в том числе информацию
        (материалы), нарушающую(ие) запреты, установленные разделом 6 Соглашения
        (а также любые иные запреты и требования, содержащиеся в действующем
        законодательстве РФ), включая личные сообщения и комментарии,
        приостанавливать, ограничивать или прекращать доступ к Сервису в любое
        время по любой причине или без объяснения причин, с предварительным
        уведомлением или без такового, не отвечая за любой вред, который может
        быть причинен Пользователю таким действием.
        <br />
        <h4>5. ОФОРМЛЕНИЕ ЗАКАЗА И ОПЛАТА УСЛУГ ПЛОЩАДОК</h4>
        5.1 Заключение Пользователем договора по использованию Спортивного
        объекта, осуществляется в момент оплаты Пользователем Заказа.
        <br />
        5.2 Сервис, действуя по поручению соответствующей Площадки, с
        привлечением уполномоченного оператора по приему платежей, или оператора
        электронных денежных средств, или иных участников расчетов,
        информационно-технологического взаимодействия, и является получателем
        платежа в качестве агента Площадки.&nbsp;
        <br />
        5.3 Выбор соответствующей формы оплаты производится Пользователем в
        Сервисе.
        <br />
        5.4 Сервис не имеет доступа к указанным Пользователем данным банковской
        карты и не несет ответственности за сохранность и конфиденциальность
        передаваемых данных при проведении безналичной оплаты. Безналичная
        оплата осуществляется Пользователем с участием уполномоченного оператора
        по приему платежей, или оператора электронных денежных средств, или иных
        участников расчетов, информационно-технологического взаимодействия, и
        регулируется правилами международных платежных систем, банков (в том
        числе банка-эмитента банковской карты) и других участников расчетов.
        <br />
        <br />
        <h4>6. ОБЯЗАННОСТИ ПОЛЬЗОВАТЕЛЯ</h4>6.1 Пользователь обязан:
        <br />
        6.1.1 Ознакомиться, перед выбором Площадки, с её характеристиками, а
        именно с:
        <br />
        * наличием душевых кабин и раздевалок;
        <br />
        * с правилами, выбранной Площадки;
        <br />
        * наличием камер хранения для ценных вещей
        <br />
        * покрытием игровых зон;
        <br />
        * наличием свободного времени бронирования у выбранной Площадки;
        <br />
        * наличием спортивного инвентаря, у выбранной Площадки;
        <br />
        * прочим оснащением Площадок.
        <br />
        6.1.2 Не передавать свои логин и пароль третьим лицам и самостоятельно
        несет ответственность за сохранность логина и пароля.
        <br />
        6.1.3 Предоставляет правдивую, точную и полную информацию о себе в
        Личном кабинете и должен поддерживать эту информацию в актуальном
        состоянии.
        <br />
        6.1.4 Немедленно уведомить Sploteam о любом случае несанкционированного
        (не разрешенного Пользователем) доступа к Сервису с использованием его
        Учетной записи.
        <br />
        6.1.5 при использовании Сервиса не нарушать законодательство Российской
        Федерации и международные нормы, а также права и интересы третьих лиц.
        <br />
        6.1.6 В случае поступления претензий от третьих лиц, связанных с
        размещением Пользователем материалов, Пользователь самостоятельно и за
        свой счет урегулирует указанные претензии.
        <br />
        6.1.7 Самостоятельно обеспечить себя спортивной формой и необходимым
        спортивным инвентарем, если такой отсутствует у Площадки.
        <br />
        <br />
        6.2 Пользователь осознает, что:
        <br />
        6.2.1 Любое действие, совершенное из Личного кабинета с использованием
        его Учетной записи, считается действием, совершенным самим
        Пользователем.
        <br />
        6.2.2 Все претензии по поводу качества услуг, оказанных Площадкой,
        предъявляются непосредственно Площадке.
        <br />
        6.2.3 Несет личную ответственность за возможные проблемы, связанные с
        несовместимостью и конфликтами Сервиса, с другим программным
        обеспечением, которое использует Пользователь.
        <br />
        6.2.4 Полностью принимает на себя ответственность за состояние своего
        здоровья и состояние здоровья своих детей, посещающих Площадки вместе с
        ним. Sploteam не несет ответственность за вред, связанный с любым
        ухудшением здоровья Пользователя, и травмы, полученные в результате
        любых самостоятельных занятий, в том числе самостоятельных практик.
        <br />
        6.2.5 Используя Сервис, он может увидеть материалы, которые могут быть
        расценены им как оскорбительные, недостоверные или спорные и при этом
        понимает, что ответственность за размещение таких материалов несет
        разместивший их соответствующий пользователь, а не Sploteam.
        <br />
        6.2.6 Он несет личную ответственность за любую информацию и за любые
        материалы, которые он размещает на сайте, и за его (Заказчика)
        взаимодействия с другими Заказчиками.
        <br />
        6.2.7 Кроме случаев, установленных настоящим Соглашением, а также
        действующим законодательством РФ, никакие материалы не могут быть
        скопированы (воспроизведены), переработаны, распространены, отображены,
        опубликованы, скачаны, переданы, проданы или иным способом использованы
        целиком или по частям без предварительного разрешения Sploteam, либо
        соответствующего правообладателя, за исключением случаев, когда
        правообладатель явным образом выразил свое согласие на свободное
        использование материала.
        <br />
        <br />
        6.3 Пользователю запрещается:
        <br />
        6.3.1 Указывать неправильные или недействительные контактные данные;
        <br />
        6.3.2 Без согласования с Sploteam предлагать какие-либо услуги.
        <br />
        6.3.3 нарушать нормальную работу Сервиса.
        <br />
        6.3.4 при блокировании, удалении и/или приостановлении доступа к Личному
        кабинету создавать повторно Учетную запись.
        <br />
        6.3.5 Использовать Сервис с целью:
        <br />
        * Загрузки, отправки, передачи или любого другого способа опубликования
        материалов, в том числе, указывая на место его нахождения, путем
        размещения ссылки, которые являются незаконными, вредоносными,
        угрожающими, оскорбляющими нравственность, клеветническими, нарушающими
        авторские и иные права интеллектуальной собственности, пропагандирующими
        ненависть и (или) дискриминацию людей по расовому, этническому,
        половому, социальному признакам, а также нарушающими принятые нормы и
        этику общения в сети Интернет, либо затрудняющие работу других
        Заказчиков с Сервисами Сайта;&nbsp; 
        <br />
        * Нарушения прав несовершеннолетних лиц и (или) причинения им вреда в
        любой форме, в том числе морального;&nbsp; 
        <br />
        * Ущемления прав различных меньшинств;&nbsp;
        <br />
        * Выдачи себя за другого человека или представителя организации и (ил)и
        сообщества, в том числе за Sploteamили ее работников, а также введения в
        заблуждение;&nbsp;
        <br />
        * Загрузки, отправки, передачи или любого другого способа опубликования
        материалов, которые Пользователь не имеет права делать доступными по
        закону или согласно каким-либо соглашениям с третьими лицами;&nbsp;
        <br />
        * Загрузки, отправки, передачи или любого другого способа опубликования
        материалов, которые нарушают любые, права третьих лиц, в том числе,
        право на товарные знаки (знаки обслуживания), коммерческую тайну, и
        (или) для нарушения любых иных прав интеллектуальной собственности
        третьих лиц;&nbsp;
        <br />
        * Загрузки, отправки, передачи или любого другого способа опубликования
        не разрешенной специальным образом рекламной информации, в том числе
        массовых, несанкционированных и (или) не ожидаемых получателями,
        незапрошенных рассылок рекламного характера, включая отправления, с
        большим количеством повторений по одному почтовому адресу («Спам»), а
        также неоднократную посылку писем одному адресату, не согласованную с
        ним;&nbsp;
        <br />
        * Загрузки, отправки, передачи или любого другого способа опубликования
        каких-либо материалов, содержащих вирусы или другие компьютерные коды,
        файлы или программы, предназначенные для нарушения, уничтожения либо
        ограничения функциональности любого компьютерного или
        телекоммуникационного оборудования или программ, для осуществления
        несанкционированного доступа к компьютерным системам, оборудованию либо
        данным третьих лиц, а также серийные номера к коммерческим программным
        продуктам и программы для их генерации, логины, пароли и прочие средства
        для получения несанкционированного доступа к платным ресурсам в
        Интернете, а также размещения ссылок на вышеуказанную информацию;&nbsp;
        <br />
        * Сбора и хранения персональных данных третьих лиц;&nbsp;
        <br />
        * Отправки в чей-либо адрес электронных писем, а также размещения в
        Сервисе текста, фото и видеоматериалов, содержащих грубые, непристойные
        или оскорбительные выражения и предложения;&nbsp;
        <br />
        * Отправки электронных писем, содержащих текстовые, фото- и
        видеоматериалы порнографического характера, а также размещения указанных
        материалов в Сервисах Сайта;&nbsp;
        <br />
        * Нарушения нормальной работы Сайта путем использования pop-up окон
        («всплывающих» окон);
        <br />
        * Размещения ссылок на ресурсы сети Интернет, содержание которых
        противоречит действующему законодательству РФ.&nbsp;
        <br />
        * Сбора информации и (или) взаимодействия с Сервисом путем использования
        автоматизированных скриптов (программ)
        <br />
        6.3.6 Любого использования Сервиса, кроме разрешенного настоящим
        Соглашением или в случае явно выраженного согласия Sploteam на такое
        использование, без предварительного письменного разрешения
        Правообладателя, категорически запрещено.
        <br />
        <h4>7. НАРУШЕНИЕ СОГЛАШЕНИЯ</h4>7.1 В случае нарушения Пользователем
        данного Соглашения Sploteam может заблокировать и (или) аннулировать
        Учетную запись Пользователя, или ограничить функциональные возможности
        использования Сервиса.
        <br />
        <h4>8. ПОЛИТИКА Е-МЕЙЛ&nbsp; РАССЫЛОК</h4>8.1 Пользуясь Сервисом,
        Пользователь дает свое согласие на получение сообщений рекламного
        характера.&nbsp; 
        <br />
        8.2 Пользователь вправе отказаться от получения сообщений рекламного
        характера путем использования соответствующего функционала Сервиса.
        <br />
        <h4>9. ОГРАНИЧЕНИЕ ОТВЕТСТВЕННОСТИ</h4>9.1 Сервис представляется «как
        есть» (as is), т.е. означает, что за проблемы, возникающие в процессе
        установки, обновления, поддержки и эксплуатации Сервиса (в т. ч.
        проблемы совместимости с другими программными продуктами (пакетами,
        драйверами и др.), несоответствия результатов использования Сервисом
        ожиданиям Пользователя и т. п.), Sploteam ответственности не несет.
        <br />
        9.2 Sploteam не несет ответственность в любом случае:
        <br />
        * за любые действия и/или бездействия поставщиков услуг, сервисов,
        сетей, программного обеспечения или оборудования.
        <br />
        * за любые убытки, включая упущенную выгоду, моральный и иной вред,
        причиненные Пользователю или третьим лицам в результате использования
        ими Сервисом или в связи с функционированием Сервиса, в том числе, за
        убытки, связанные с принятием каких-либо решений и действий, основанных
        на информации, размещенной Площадками в Сервисе.
        <br />
        * по сделкам между Пользователями Площадкой, заключенным в связи
        использованием Сервиса.
        <br />
        <br />
        9.3 Sploteam не гарантирует отсутствия ошибок и сбоев в работе Сервиса в
        отношении предоставления возможности безналичной оплаты.
        <br />
        <h4>10. ПЕРСОНАЛЬНЫЕ ДАННЫЕ</h4>10.1 Персональные данные Пользователя и
        (или) его представителей, которые он указывает в Личном кабинете,
        хранятся и обрабатываются Sploteam в соответствии с условиями Политики
        конфиденциальности (https://sploteam.com/policy/), которая изложена в
        отдельном документе.
        <br />
        <h4>11. ИНТЕЛЛЕКТУАЛЬНАЯ СОБСТВЕННОСТЬ</h4>
        <br />
        11.1 Обладателем исключительных прав на Сервис, включая, но не
        ограничиваясь на доменное имя, размещенный логотип на Сервисе, базы
        данных, все технические разработки, позволяющие осуществлять
        использование Сервиса, является Sploteam.
        <br />
        11.2 Пользователь не вправе использовать Сервис, не предусмотренными
        Соглашением способами.
        <br />
        11. 3 В целях исполнения Соглашения, проведения конкурсов, акций,
        анонсирования и информирования Пользователя и иных подобных мероприятий,
        осуществления законных прав и интересов Sploteam и обеспечения
        функционирования Сервиса, Пользователь предоставляет Sploteam
        действующее повсеместно, бессрочное право использовать фотографии,
        тексты отзывов, которые он оставляет публично в сети Интернет) любым
        способом на всех известных или неизвестных информационных носителях в
        течение всего срока действия исключительного права, а также передавать
        такое право третьим лицам, как с указанием имени автора, так и без
        такового.
        <br />
        <h4>12. ИЗМЕНЕНИЕ СОГЛАШЕНИЯ </h4>
        12.1 Соглашение может быть изменено Sploteam без какого-либо
        специального уведомления, новая редакция Соглашения вступает в силу с
        момента ее размещения в Сервисе, если иное не предусмотрено новой
        редакцией Соглашения. Действующая редакция Соглашения всегда находится
        на странице по адресу https://sploteam.com/legal/.
        <br />
        <h4>13. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ </h4>
        13.1 Соглашение считается заключенным в момент регистрации Пользователя
        в Сервисе и действует до момента удаления Личного кабинета.
        <br />
        13.2 Если по каким-либо причинам одно или несколько положений настоящего
        Соглашения будут признаны недействительными или не имеющими юридической
        силы, это не оказывает влияния на действительность или применимость
        остальных положений Соглашения.
        <br />
        13.3 Настоящее соглашение составлено на русском языке и в некоторых
        случаях может быть предоставлено Пользователю для ознакомления на другом
        языке. В случае расхождения русскоязычной версии Соглашения и версии
        Соглашения на ином языке, применяются положения русскоязычной версии
        настоящего Соглашения.
      </div>
      <Footer />
    </>
  );
};

export default LegalPage;
