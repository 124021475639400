import { FormField } from 'components/Hoc/Form/Field';
import Form from 'components/Hoc/Form/Form';
import Button from 'components/UI/Button/Button';
import PasswordRecoveryRequest from 'models/requests/passwordRecovery';
import React from 'react';
import AuthorizationStore from 'stores/AuthorizationStore';
import Footer from '../Hoc/Footer/Footer';
import Header from '../Hoc/Header/Header';
import { FormInput } from '../UI/FormWithLabelsUI/FormInput/FormInput';
import { useForm } from 'react-hook-form';

const PasswordRecoveryPage: React.FC = () => {
  const form = useForm();
  return (
    <>
      <Header />
      <div className="wrap content-page">
        <div
          className="auth"
          style={{
            maxWidth: '500px',
            paddingTop: '10px',
            margin: '0 auto',
          }}
        >
          <div style={{ paddingBottom: '10px' }}>
            Отлично, теперь придумайте новый пароль для завершения
            восстановления
          </div>
          <Form
            onSubmit={async (data: PasswordRecoveryRequest) => {
              await AuthorizationStore.doChangePassword(data);
            }}
            formReturn={form}
          >
            <FormField
              Component={FormInput}
              label="Придумайте новый пароль"
              type="password"
              name="password"
              rules={{
                required: 'Выберите значение',
                minLength: {
                  value: 8,
                  message: 'Пароль должен быть не менее 8 символов',
                },
                validate: (value) => {
                  const isAllowed =
                    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])[a-zA-Z0-9\w\W]{8,32}$/.test(
                      value
                    );
                  if (!isAllowed) {
                    return 'Пароль не соответствует требованиям';
                  }
                  if (value !== form.watch('repeatPassword')) {
                    return 'Пароли не совпадают';
                  }
                  return undefined;
                },
              }}
            />
            <FormField
              Component={FormInput}
              label="Повторите, чтобы не ошибиться"
              type="password"
              name="repeatPassword"
              rules={{
                required: 'Выберите значение',
                minLength: {
                  value: 8,
                  message: 'Пароль должен быть не менее 8 символов',
                },
                validate: async (value) => {
                  if (value !== form.watch('password')) {
                    return 'Пароли не совпадают';
                  }
                  await form.trigger('password');
                  return undefined;
                },
              }}
            />
            <div style={{ color: '#6b7683' }}>
              Требования к паролю:
              <ul>
                <li>Минимум одна латинская буква (a-z)</li>
                <li>Минимум одна латинская заглавная буква (A-Z)</li>
                <li>Минимум одна цифра (0-9)</li>
                <li>Быть не менее 8 символов</li>
                <li>Новый пароль не совпадает со старым</li>
              </ul>
            </div>
            <Button buttonText="Продолжить" type="submit" />
          </Form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PasswordRecoveryPage;
