import { FormField } from 'components/Hoc/Form/Field';
import Form from 'components/Hoc/Form/Form';
import Button from 'components/UI/Button/Button';
import { BaseInput } from 'components/UI/FormWithLabelsUI/FormInput/FormInput';
import defaultCss from 'components/UI/FormWithLabelsUI/FormInput/FormInput.module.css';
import css from './EventPayPage.module.scss';
import { observer } from 'mobx-react';
import FormInputProps from 'models/ui/form/formInputProps';
import React from 'react';
import { useForm } from 'react-hook-form';
import EventPayStore from 'stores/EventPayStore';

function VoucherInput(props: FormInputProps) {
  return (
    <div
      className={`${defaultCss.formInput} ${
        EventPayStore.voucherIsApplied ? defaultCss.green : ''
      } ${props.className || ''}`}
    >
      <BaseInput {...props} disabled={EventPayStore.voucherIsApplied} />
      {EventPayStore.voucherIsApplied && (
        <span
          className={defaultCss.formInputClear}
          onClick={async () => {
            await EventPayStore.cancelVoucher();
            props.setValue(props.name, '');
          }}
        />
      )}
    </div>
  );
}

function VoucherForm() {
  const voucherForm = useForm<any>({
    defaultValues: { voucher: EventPayStore.voucher?.code || '' },
  });
  const voucher = voucherForm.watch('voucher', '');
  return (
    <Form onSubmit={EventPayStore.applyVoucher} formReturn={voucherForm}>
      <FormField
        name="voucher"
        type="text"
        label="Промокод"
        Component={VoucherInput}
      />
      {voucher && !EventPayStore.voucherIsApplied && (
        <Button type="submit" buttonText="Применить" />
      )}
      {EventPayStore.voucher && (
        <div>
          <div className={css.voucherDiscount}>
            Скидка {EventPayStore.voucher.discountAmount}%
          </div>
          <p className={css.summDiscount}>
            Стоимость с учетом скидки{' '}
            <strong>{EventPayStore.voucher.newPrice} ₽</strong>
          </p>
        </div>
      )}
    </Form>
  );
}

export default observer(VoucherForm);
