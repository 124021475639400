import { WithChildren } from 'models/technical/withProps';
import styles from './OrangeLink.module.css';

interface Props extends WithChildren {
  onClick?: () => void;
}

function OrangeLink({ children, className, onClick, style }: Props) {
  return (
    <div
      className={`${styles.orangeLink} ${className}`}
      style={style}
      onClick={onClick}
    >
      {children}
    </div>
  );
}

export default OrangeLink;