import React from 'react';
import styles from './ControlLabel.module.css';
import {WithChildren} from "../../../../models/technical/withProps";

interface ControlLabelProps extends WithChildren {}

const ControlLabel:React.FC<ControlLabelProps> = ({ children }) => (
  <label className={styles.controlLabel}>{children}</label>
);

export default ControlLabel;
