import React, { ReactNode } from 'react';
import styles from './SearchResultGrid.module.scss';
import {WithChildren} from "../../../../../models/technical/withProps";
import classNames from "classnames";

interface Props extends WithChildren {
  items: ReactNode[];
}

export const SearchResultGrid:React.FC<Props> = (props) => {
  return (
    <div className={classNames(
        styles.grid,
        props.className
    )}>
      {props.items.map((item, index) => (
        <div className={styles.item} key={index}>{item}</div>
      ))}
    </div>
  );
};
