import React, {useEffect} from 'react';
import FAQSection from '../FAQSection/FAQSection';
import commonCss from '../../../../css/Common.module.css';
import BackLink from '../../Profile/LKLayout/BackLink/BackLink';
import Page from "../../../Hoc/Page/Page";
import FAQStore from "../../../../stores/FAQStore";
import SpinnerWrapper from "../../../UI/Loader/SpinnerWrapper/SpinnerWrapper";
import {observer} from "mobx-react";

const FAQPage:React.FC = () => {

    useEffect(() => {
        FAQStore.loadItems();
        return () => {
            FAQStore.reset();
        }
    }, []);

    const loading = FAQStore.requestState === 'pending';

    const items = FAQStore.items;
    const selectedItem = FAQStore.selectedItem;

    return (
        <Page>
            {loading && <SpinnerWrapper/>}
            {items && selectedItem ?
                <div className={commonCss.wrap}>
                    <BackLink />
                    <FAQSection
                        items={items}
                        selectedItem={selectedItem}
                    />
                </div> : null
            }
        </Page>
    );
};

export default observer(FAQPage);