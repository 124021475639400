import { FormField } from 'components/Hoc/Form/Field';
import Form from 'components/Hoc/Form/Form';
import { FormInput } from 'components/UI/FormWithLabelsUI/FormInput/FormInput';
import SignUpRequest from 'models/requests/signup';
import React from 'react';
import { useForm } from 'react-hook-form';
import AuthorizationStore from 'stores/AuthorizationStore';
import Button from '../../../UI/Button/Button';
import { NavLink } from 'react-router-dom';
import { AsYouType } from 'libphonenumber-js';

interface SignUpFormValues {
  name: string,
  email: string,
  phone: string,
  password: string
}

function normailize({
                      name,
                      email,
                      phone,
                      password
                    }: SignUpFormValues): SignUpRequest {
  const phoneNormalizer = new AsYouType('RU');
  phoneNormalizer.input(phone);
  return {
    name,
    email,
    phone: (phoneNormalizer.getNumber()?.number as string) || '',
    password
  };
}

const SignUp = () => {
  const form = useForm();
  return (
    <div className="auth">
      <Form
        formReturn={form}
        onSubmit={async (data: SignUpFormValues) => {
          await AuthorizationStore.doSignUp(normailize(data));
        }}
      >
        <FormField Component={FormInput} label="Ваше имя" name="name" rules={{required: true}} />
        <FormField Component={FormInput} label="Email" name="email" rules={{required: true}} />
        <FormField Component={FormInput} label="Номер телефона" name="phone" />
        <FormField
          Component={FormInput}
          label="Придумайте пароль"
          type="password"
          name="password"
          rules={{required: true}}
        />
        <div style={{ color: '#6b7683', fontSize: '14px' }}>
          Требования к паролю:
          <ul>
            <li>Минимум одна латинская буква (a-z)</li>
            <li>Минимум одна латинская заглавная буква (A-Z)</li>
            <li>Минимум одна цифра (0-9)</li>
            <li>Быть не менее 8 символов</li>
            <li>Новый пароль не совпадает со старым</li>
          </ul>
        </div>
        <div className="auth__agreement-message">
          Продолжая вы соглашаетесь с{' '}
          <NavLink to="/legal" target="_blank">
            условиями предоставления сервиса
          </NavLink>{' '}
          и{' '}
          <NavLink to="/policy" target="_blank">
            политикой конфиданциальности
          </NavLink>
        </div>
        <Button buttonText="Создать аккаунт" type="submit" />
      </Form>
    </div>
  );
};

export default SignUp;
