import React, {useState} from 'react';
import { useForm } from 'react-hook-form';
import Footer from '../Hoc/Footer/Footer';
import Header from '../Hoc/Header/Header';
import api from '../../api';
import ModalStore from '../../stores/ModalStore';
import SuccessCooperationModal from './Home/Cooperation/SuccessCooperationModal';
import { FormField } from '../Hoc/Form/Field';
import { FormInput } from '../UI/FormWithLabelsUI/FormInput/FormInput';
import Button from '../UI/Button/Button';
import Form from '../Hoc/Form/Form';
import './ContentPage.css';
import SupportRequest from '../../models/requests/support';
import UserStore from '../../stores/UserStore';
import { FormTextarea } from 'components/UI/FormWithLabelsUI/FormTextarea/FormTextarea';

const supportSuccessHint =
  'Ваша заявка успешно отправлена и скоро будет рассмотрена. Ожидайте ответное письмо от команды поддержки.';

const SupportPage: React.FC = () => {

  const [success, setSuccess] = useState<boolean>(false);

  const form = useForm<SupportRequest>({
    defaultValues: {
      email: UserStore.userData?.user?.email || '',
    }
  });
  return (
    <>
      <Header />
      <div className="wrap content-page">
        <h1 className="page-title">Поддержка</h1>
        <div className="static-page__content">
          <div className="editor-content">
            <p>
              Для того чтобы оставить отзыв о работе сервиса или получить
              поддержку вопользуйтесь формой обратной связи.
            </p>
            <p>
              Если у вас возникла проблема с конкретной игрой, то приложите
              пожалуйста ссылку на эту игру. С помощью этой ссылки мы сможем
              решить проблему гораздо быстрее.
            </p>
            <h2>Форма обратной связи</h2>
            {!success ? <Form<SupportRequest>
                formReturn={form}
                onSubmit={async (data: SupportRequest) => {
                  await api.home.sendSupport(data);
                  ModalStore.showModal(
                      <SuccessCooperationModal text={supportSuccessHint} />
                  );
                  setSuccess(true);
                }}
                className="support-form"
            >
              <FormField
                  name="email"
                  type="text"
                  label="E-mail"
                  Component={FormInput}
              />
              <FormField
                  name="subject"
                  type="text"
                  label="Тема сообщения"
                  Component={FormInput}
              />
              <FormField
                  name="message"
                  label="Текст обращения"
                  Component={FormTextarea}
              />
              <Button type="submit" buttonText="Отправить заявку" />
            </Form> : supportSuccessHint}

          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SupportPage;
