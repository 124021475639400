import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import HomeStore from '../../../stores/HomeStore';
import './HomePage.scss';
import Cooperation from './Cooperation/Cooperation';
import AreaSlider from './AreaSlider';
import UserStore from '../../../stores/UserStore';
import VkPopup from '../../common/VkPopup/VkPopup';
import { FAQButton } from './ReferenceButton/FAQButton';
import CampsButton from "./CampsButton";
import cooperationImg from './img/sploteam-cooperation@2x.png'
import EventCard from "../../common/EventCard/EventCard";
import {SearchResultGrid} from "../Search/SearchResults/SearchResultGrid/SearchResultGrid";
import Page from "../../Hoc/Page/Page";

const HomePage: React.FC = observer(() => {
  useEffect(() => {
    HomeStore.getHomeData();
  }, []);
  return HomeStore.homeData ? (
    <Page>

      <FAQButton />

      <section className="all-games">
        <div className="wrap">
          <h1 className="all-games__title">
            Играй, тренируйся
            <br /> и улучшай свои навыки <br />
            <span className="color-orange">в командных видах спорта</span>
          </h1>
          <div className="games-list">
            <NavLink
              to="/search/?type=2"
              className="games-list__item games-list__item_volleyball"
            >
              Волейбол <div className="games-list__sm">пляжный</div>
            </NavLink>
            <NavLink
              to="/search/?type=10"
              className="games-list__item games-list__item_football"
            >
              Футбол
            </NavLink>
            <NavLink
              to="/search/?type=7"
              className="games-list__item games-list__item_basketball"
            >
              Баскетбол
            </NavLink>
            <NavLink
              to="/search/"
              className="games-list__item games-list__item_more-games"
            >
              Ещё игры
            </NavLink>
          </div>
          <CampsButton className={'campButton'}/>
          <div className="all-games-img"></div>
        </div>
      </section>

      <section className="coming-games">
        <div className="wrap">
          <h2 className="f-bold text-center coming-games__title">
            Ищешь с кем поиграть{' '}
            <span className="color-orange">прямо сейчас?</span>
          </h2>
          <div className="coming-games__text f-bold text-center">
            Тогда присоединяйся на одну из ближайших игр!
          </div>
          {HomeStore.homeData?.events && <SearchResultGrid className={'coming-games__list'} items={HomeStore.homeData?.events.map(event =>
              <EventCard
                  key={event.event.hash}
                  event={event}
              />
          )}/>}
          <div className="text-center">
            <NavLink to="/search/" className="button button_main-page">
              Показать больше игр
            </NavLink>
          </div>
        </div>
      </section>

      <section className="create-game">
        <div className="wrap">
          <h2 className="f-bold text-center">
            <span className="color-orange">Создавай игры</span> и находи себе
            соперников
          </h2>
          <div className="create-game__list">
            <div className="create-game__item">
              <div className="create-game__img create-game__ball">
                <div className="create-game__counter">1</div>
              </div>
              Выбери вид спорта,
              <br /> дату и время для игры
            </div>
            <div className="create-game__item">
              <div className="create-game__img create-game__donate">
                <div className="create-game__counter">2</div>
              </div>
              Оплачивай только
              <br /> свою часть аренды
            </div>
            <div className="create-game__item">
              <div className="create-game__img create-game__team">
                <div className="create-game__counter">3</div>
              </div>
              Sploteam найдет тебе
              <br /> команду из игроков твоего уровня
            </div>
          </div>
          <div className="text-center">
            <a href="/search/?tabId=1" className="button button_main-page">
              Создай свою игру
            </a>
          </div>
        </div>
      </section>

      <section className="rent-area">
        <div className="wrap">
          <h2 className="f-bold text-center">Площадки в Sploteam</h2>
          <AreaSlider arenas={HomeStore.homeData.arenas} />
          <div className="text-center">
            <a href="/search/?tab=rental" className="button button_main-page">
              Арендовать площадку
            </a>
          </div>
        </div>
      </section>

      <section className="cooperation">
        <div className="wrap">
          <Cooperation />
          <img src={cooperationImg} alt="Приглашаем к сотрудничеству" className='cooperationImg'/>
        </div>
      </section>
      {UserStore.userData?.config.vkSocialPopup && <VkPopup />}
    </Page>
  ) : null;
});

export default HomePage;
