import css from './YearSwitcher.module.scss';
import CampsSearchPageStore from "../../../../stores/CampsSearchPageStore";
import {Year} from "../../../../stores/CampsSearchPageStore";
import {observer} from "mobx-react";
import {WithChildren} from "../../../../models/technical/withProps";

const yearsArr:Year[] = [
    2022,
    2023,
    2024
]

interface YearSwitcherProps extends WithChildren {}

const YearSwitcher:React.FC<YearSwitcherProps> = ({
    className
                                                  }) => {

    const active = CampsSearchPageStore.year;
    const onClick = (date: Year) => {
        CampsSearchPageStore.setYearFilter(date);
    }

    return (
        <div className={`${css.container} ${className}`}>
            {yearsArr.map(year =>
                <div
                    key={year}
                    onClick={() => {
                        onClick(year);
                    }}
                    className={`${css.year} ${active === year ? css.active : ''}`}
                >{year}</div>
            )}
        </div>
    );
};

export default observer(YearSwitcher);