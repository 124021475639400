import css from './EventCard.module.scss';
import EventInfo from "../../../models/entities/eventInfo";
import {Card} from "../../UI/Card/Card";
import {SearchAccess} from "../../../enums/event";
import classNames from "classnames";
import {getEventDate} from "../../../moment/utils";
import {NavLink} from "react-router-dom";
import React from "react";
import {getPlayerSign} from "../../../utils/functions";
import linearGradient from "../../../css/linearGradient.module.css";
import {arrGradients} from "../../../utils/const";
import TeamMember from "../TeamMember/TeamMember";
import MoreMembers from "../TeamMember/MoreMembers/MoreMembers";
import {declOfNum} from "../../../utils/translations";
import membersIcon from './members_24px.png';
import UserStore from "../../../stores/UserStore";
import browserHistory from "../../../browserHistory";

interface EventCardProps {
    event: EventInfo;
}

const EventCard:React.FC<EventCardProps> = ({
    event
                                          }) => {

    const isTraining = [
        SearchAccess.training,
        SearchAccess.externalTraining
    ].includes(event.event.searchAccess);

    const eventType = isTraining ? 'Тренировка' : 'Игра';

    const isVip = event.event.searchAccess === SearchAccess.vip;

    const userId = UserStore.userData?.user?.id;
    let players = event.players;

    if (userId) {
        const index = event.players.map(player => player.user.id).indexOf(userId);
        if (index > 0) players.unshift(...players.splice(index, 1));
    }

    const eventClick = () => {
        browserHistory.push({
            pathname: `/event/view/${event.event.hash}/`,
            state: {
                prevLocation: browserHistory.location.pathname,
                prevParams: browserHistory.location.search
            }
        })
    }

    return (
        <Card className={classNames(
            event.event.gender && css[event.event.gender],
                isVip && css.hard
        )}>
            <div className={css.eventTypeRow}>
                <div className={css.eventTypeRow__type}>{eventType}</div>
                <div className={css.eventTypeRow__arena}>"{event.arena.name}"</div>
            </div>
            <div className={css.container}>
                <div className={classNames(
                    css.contentItem,
                    css.mainContent
                )}>
                    <div className={css.mainContent__textSide}>
                        <div className={css.mainContent__firstRow}>
                            <div className={css.date}>{getEventDate(event.event.startAt)}</div>
                            <div className={css.price}>{event.event.cost} ₽</div>
                        </div>
                        <div className={css.sport}>{event.sport?.name}</div>
                        {event.event.playerLevel?.name && <div className={css.level}>Уровень - {event.event.playerLevel?.name}</div>}
                    </div>
                    <div className={css.mainContent__arenaLogoSide}>
                        {event.arena.slug ? (
                            <NavLink
                                to={`/arenas/${event.arena.slug}/`}
                                className={css.arenaLogo}
                            >
                                <img src={event.arena.logo} alt="logo" />
                            </NavLink>
                        ) : (
                            <div className={css.arenaLogo}>
                                <img src={event.arena.logo} alt="logo" />
                            </div>
                        )}
                    </div>
                </div>
                {!isTraining ? (
                    <div className={classNames(
                        css.contentItem,
                        css.formatRow
                    )}>
                        {event.ground?.gameFormat?.name && <div className={css.format}>Формат - {event.ground?.gameFormat?.name}</div>}
                        <div className={css.limits}>
                            от {event.event.minPlayersCount} до {event.event.maxPlayersCount}
                            <img src={membersIcon} alt="Участники" className={css.limits__icon}/>
                        </div>
                    </div>
                ) : event.event.coach && (
                    <div className={classNames(
                        css.contentItem,
                        css.coachRow
                    )}>

                        <div className={css.coachName}>Тренер - {event.event.coach.name}</div>
                        <div className={classNames(
                            css.coachPhoto,
                            !event.event.coach.photo && linearGradient[arrGradients[event.event.coach.name.length % arrGradients.length]]
                        )}>
                            {event.event.coach.photo ?
                                <img src={event.event.coach.photo} alt={event.event.coach.name}/> :
                                getPlayerSign(event.event.coach.name)
                            }
                        </div>
                    </div>
                )}
                {event.event.comment && (
                    <div className={classNames(
                        css.contentItem,
                        css.commentRow
                    )}>
                        <div className={css.comment}>{event.event.comment}</div>
                    </div>
                )}
            </div>
            <div className={css.bottomRow} onClick={eventClick}>
                <div className={css.members}>
                    {players.slice(0, 3).map((p, index) => (
                        <TeamMember player={p} index={index} key={p.id} className={css.member} />
                    ))}
                    {players.length > 3 && (
                        <MoreMembers playersCount={players.length - 3} className={css.member} />
                    )}
                </div>
                {event.event.status === 'passed' ? <span>Игра состоялась</span> : (
                    <div className={css.freeSlots}>
                        <span className={css.freeSlots__text}>{event.event.freeSlotsCount ? 'Осталось' : 'Мест не осталось'}</span>
                        {!!event.event.freeSlotsCount && (
                            <span className={css.freeSlots__value}>{event.event.freeSlotsCount} {declOfNum(event.event.freeSlotsCount, [
                                'место',
                                'места',
                                'мест',
                            ])}</span>
                        )}
                    </div>
                )}
            </div>
        </Card>
    );
};

export default EventCard;