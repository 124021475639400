import css from './Chat.module.scss';
import { observer } from 'mobx-react';
import { Fragment, useEffect, useRef, useState } from "react";
import { useParams } from 'react-router';
import EventViewStore from '../../../../../stores/EventViewStore';
import ChatMessage from './ChatMessage';
import moment from 'moment';
import ChatForm from './ChatForm';

function Chat() {

  const { hash } = useParams<{ hash: string }>();
  const getChat = async () => { await EventViewStore.getChat(); };
  const [firstEl, setFirstEl] = useState(EventViewStore.chatMessages.length > 0 && EventViewStore.chatMessages[0].id);
  const chatBody = useRef<HTMLDivElement>(null);
  const mob = window.innerWidth <= 767;
  const offset = !mob ? 60 : 40;

  useEffect(() => {
    if (EventViewStore.waitScroll) {
      scrollToLastEl();
      EventViewStore.setWaitScroll(false);
    }
    else {
      scrollToBottom();
    }
  }, [EventViewStore.groupedMessages]);

  const scrollToLastEl = () => {
    const firstElNode = document.getElementById(`${firstEl}`);
    if (firstElNode) {
      if (chatBody.current) {
        chatBody.current.scrollTop = firstElNode.offsetTop - offset;
      }
    }
  };

  const getNextPage = () => {
    setFirstEl(EventViewStore.chatMessages[0].id);
    if (EventViewStore.getNextPageLink) EventViewStore.getNextPage(EventViewStore.getNextPageLink);
  };

  const checkChatScrollToTop = (e: Event) => {
    if (chatBody.current && chatBody.current.scrollTop === 0 && EventViewStore.getNextPageLink) {
      getNextPage();
    }
  };

  const scrollToBottom = () => {
    if (chatBody.current) {
      if (chatBody.current) chatBody.current.scrollTop = chatBody.current.scrollHeight;
    }
  };

  useEffect(() => {
    EventViewStore.initChat().then(() => {
      if (chatBody.current) {
        scrollToBottom();
        chatBody.current.addEventListener('scroll', checkChatScrollToTop);
      }
    });

    let intervalPaused = false;
    const intervalPausedSetTrue = () => { intervalPaused = true; };
    const intervalPausedSetFalse = () => { intervalPaused = false; };

    const intervalGetChat = setInterval(() => {
      if (!intervalPaused) getChat();
    }, 3000);

    window.addEventListener('focus', intervalPausedSetFalse);
    window.addEventListener('blur', intervalPausedSetTrue);

    return () => {
      clearInterval(intervalGetChat);
      EventViewStore.clearChat();
      window.removeEventListener('focus', intervalPausedSetFalse);
      window.removeEventListener('blur', intervalPausedSetTrue);
      if (chatBody.current) chatBody.current.removeEventListener('scroll', checkChatScrollToTop);
    };
  }, [hash]);

  return (
    <div className={css.chat}>
      <div className={css.chat__head}>
        <div className={css.chat__head__title}>Чат</div>
      </div>
      <div className={css.chat__body} ref={chatBody}>
        {EventViewStore.groupedMessages.map((gm, gmIndex) => (
          <Fragment key={`${gm.firstMessageDate}_${gmIndex}`}>
            <div className={css.chat__body__date}>
              {moment(gm.firstMessageDate).format('D MMMM')}
            </div>
            <div className={"messages"}>
            {gm.chatMessages.map((cm, index) => (
              <ChatMessage chatMessage={cm} key={cm.id} />
            ))}
            </div>
          </Fragment>
        ))}
      </div>
      <ChatForm sendMessageCallback={scrollToBottom}/>
    </div>
  );
}

export default observer(Chat);
