import { onSelectChange, SelectOption } from 'models/entities/SelectOption';
import { CssModulesStyles } from 'models/technical/cssModules';
import React, { FunctionComponent } from 'react';

interface SortDropdownProps {
  onChange: onSelectChange;
  value?: SelectOption<any>;
  options?: SelectOption<any>[];
  styles: CssModulesStyles;
}

const SelectDropdown: FunctionComponent<SortDropdownProps> = ({
  onChange,
  value,
  options,
  styles,
}) => {
  return (
    <div className={styles.dropdown}>
      {options &&
        options.map((option) => (
          <div
            key={option.id}
            onClick={() => onChange(option)}
            className={`${styles.dropdownItem} ${
              value?.id === option.id ? styles.itemSelected : ''
            }`}
            role="button"
            aria-hidden
          >
            {option.customContent || option.name}
          </div>
        ))}
    </div>
  );
};

export default SelectDropdown;
