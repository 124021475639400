import React, {SyntheticEvent} from 'react';
import defaultStyles from "./Checkbox.module.scss";
import CheckboxProps from "../../../models/ui/form/checkboxProps";
import classNames from "classnames";

const Checkbox: React.FC<CheckboxProps> = (props) => {
    const styles = props.styles || defaultStyles;

    const onClick = (e: SyntheticEvent) => {
        if (props.stopClickPropagation) {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
        }
    }

    const onChange = (e: SyntheticEvent) => {
        const checkboxData = {
            label: props.label,
            value: props.value,
        };
        props.onChange(checkboxData, !props.checked);
    };

    const inputClassName = `${styles.input} ${props.ambiguous ? styles.ambiguous : ""}`;

    return (
        <label
            key={props.value}
            className={classNames(
                styles.label,
                props.stylesModifier && styles[props.stylesModifier],
                props.disabled && styles.disabled
            )}
            onClick={onClick}
        >
            <input
                className={inputClassName}
                type="checkbox"
                value={props.value}
                checked={!!props.checked}
                onChange={onChange}
            />
            <span className={styles.checkbox}></span>
            {props.label &&
                <span className={styles.labelText}>{props.label}</span>
            }
        </label>
    );
};

export default Checkbox;