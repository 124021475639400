import React from 'react';

interface Props {
  text?: string;
}

const SuccessCooperationModal: React.FC<Props> = ({ text }) => {
  return (
    <p className="text-feedback">
      {text ||
        'Заявка отправлена. В скором времени с Вами свяжется Ваш персональный менеджер.'}
    </p>
  );
};

export default SuccessCooperationModal;
