export const formErrorCode = 'validation_failed';

const multipleAccountsText =
  'На данную почту зарегистрировано несколько аккаунтов. Пожалуйста, обратитесь в поддержку.';

export const formErrorTypeToMessageMap: Record<string, string> = {
  account_already_registered: 'Аккаунт уже зарегистрирован',
  account_already_confirmed: 'Аккаунт уже подтвержден',
  account_not_confirmed: 'Аккаунт не подтвержден',
  account_not_found: 'Такой логин или пароль не найдены',
  event_creation_error: 'Ошибка создания события',
  event_not_found: 'Событие не найдено',
  expired_email_token: 'Ссылка устарела',
  incorrect_email_token: 'Ссылка испорчена, попробуйте еще раз',
  invalid_email_token: 'Ссылка испорчена, попробуйте еще раз',
  invalid: 'Неверное значение',
  legacy_account_not_unique: multipleAccountsText,
  legacy_has_another_confirmed_account: multipleAccountsText,
  missing: 'Поле отсутствует',
  not_enough_amount: 'Недостаточно средств на личном счете',
  password_duplicated: 'Пароль совпадает со старым',
  password_incorrect: 'Неверный пароль',
  password_mismatch: 'Пароли не совпадают',
  payment_unavailable: 'Оплата недоступна',
  required: 'Введите значение',
  reset_password_token_not_set: 'Время действия ссылки истекло',
  voucher_apply_error: 'Не удалось примернить промокод',
  voucher_not_found: 'Промокод не найден',
  confirm_account_token_not_set: 'Сессия для подтвержденя аккаунта не найдена',
  order_not_found: 'Заказ не найден',
  arena_booking_request_error: 'Ошибка бронирования',
  event_canceled: 'Событие отменено',
  old_password_incorrect: 'Старый пароль неверен'
};

export const apiErrorTypeToMessageMap: Record<string, string> = {
  incorrect_city: 'Неверный город',
  schedule_is_busy: 'Данное время уже занято, выберите другое время',
  event_in_the_past: 'Информация устарела, попробуйте еще раз',
  ground_not_found: 'Площадка не найдена',
  order_not_found: 'Заказ не найден',
  event_canceled: 'Событие отменено',
  event_creation_error: 'К сожалению не удалось создать игру',
  event_join_error: 'К сожалению не удалось присоединиться к игре',
  event_exit_error: 'К сожалению не удалось выйти из игры',
  too_late_for_join: 'Осталось слишком мало времени до начала игры, чтобы вы могли присоединиться',
  duplicate_player_email: 'Вы уже участвуете в игре',
  camp_not_found: 'Кемп не найден',
  arena_not_found: 'Арена не найдена',
  event_not_found: 'Событие не найдено'
};

export const quizErrors: Record<string, string> = {
  quiz_not_completed: 'Ответьте, пожалуйста, на все вопросы',
  quiz_not_found: 'Данный опросник не найден'
};