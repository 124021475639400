import css from './CampCard.module.scss';
import {Card} from "../../../UI/Card/Card";
import campIcon from './Imgs/camp-icon.svg';
import {CampEvent} from "../../../../models/entities/camp";
import moment from "moment";
import 'css/flags.css';
import {Link} from 'react-router-dom';

interface CampCardProps {
    data: CampEvent
}

export const getFormattedDate = (date: string) => moment(date, 'YYYY-MM-DD').format('D MMMM');

const CampCard:React.FC<CampCardProps> = ({ data }) => {

    const getSports = () => data.sports.map(sport => sport.name).join(', ');

    const getDates = () => `${getFormattedDate(data.dateFrom)} - ${getFormattedDate(data.dateTo)}`;

    return (
        <Card>
            <div className={css.container}>
                <div className={`${css.container__item} ${css.header}`}>
                    <div className={css.header__info}>
                        <div className={css.date}>{getDates()}</div>
                        <div className={css.campTitle}>{data.name}</div>
                    </div>
                    <div className={css.logo}>
                        <div className={css.logo__img} style={{backgroundImage: `url(${data.organizer.logo ? data.organizer.logo : '/camp-icon.svg'})`}}></div>
                    </div>
                </div>
                <div className={`${css.container__item} ${css.content}`}>
                    {!!data.sports.length && <div className={css.sport}>{getSports()}</div>}
                    <div className={css.organizer}>{data.organizer.name}</div>
                </div>
                <div className={`${css.container__item} ${css.location}`}>
                    <div className={css.location}>{data.location.name}</div>
                </div>
            </div>
            <Link to={`/camps/${data.slug}/`} className={css.linkWrapper}>
                <div className={css.footer}>
                    <div className={`${css.flag} flag:${data.country.code}`}></div>
                    <div className={css.city}>{data.country.name}</div>
                    <div className={css.moreButton}>Подробнее</div>
                </div>
            </Link>
        </Card>
    );
}

export default CampCard;