import React from "react";
import styles from "./Paginator.module.scss";
import {PageButton} from "./PageButton";

interface Props {
    startCountingPagesFromOne?: boolean, // pass 'true' if the number of your first page is 1
    currentPage: number,
    numberOfPages: number,
    displayedRange?: number,
    onChange: (page: number) => void,
}

const minDisplayedRange = 3;

export const Paginator: React.FC<Props> = (props) => {
    if (props.numberOfPages === 1) {
        return null;
    }

    const currentPage = props.startCountingPagesFromOne
        ? props.currentPage - 1
        : props.currentPage;

    // @ts-ignore
    if (props.displayedRange < minDisplayedRange) {
        throw new Error(`Displayed range is lesser than ${minDisplayedRange}.`);
    }
    if (currentPage < 0 || currentPage >= props.numberOfPages) {
        throw new Error(`Invalid currentPage value: ${currentPage}.`);
    }

    const displayedRange = props.displayedRange || minDisplayedRange;

    const onChange = (page: number) => {
        if (props.startCountingPagesFromOne) {
            page++;
        }
        props.onChange(page);
    };

    const pageButtons = () => {
        const buttons: JSX.Element[] = [];

        const isRangeHasBreak = currentPage < props.numberOfPages - displayedRange;

        const firstDisplayedPage = (() => {
            if (isRangeHasBreak) {
                // selected button is placed at the center between the first and the penult button (if possible)
                const pagesBeforeSelected = Math.floor((displayedRange - 1) / 2) - 1;

                return Math.max(0, currentPage - pagesBeforeSelected);
            }

            return Math.max(0, props.numberOfPages - displayedRange);
        })();

        for (let i = 0; i < displayedRange; i++) {
            const page = firstDisplayedPage + i;
            const isPenultButton = displayedRange - i === 2;
            const isLastButton = displayedRange - i === 1;

            buttons.push(
                <PageButton
                    key={page}
                    page={isLastButton ? props.numberOfPages - 1 : page}
                    isBreakButton={isRangeHasBreak && isPenultButton}
                    selected={page === currentPage}
                    onClick={onChange}
                />
            );

            if (page === props.numberOfPages - 1) {
                break;
            }
        }

        return buttons;
    };

    const isFirstPage = currentPage === 0;
    const isLastPage = currentPage === props.numberOfPages - 1;
    const onPrevBtnClick = () => onChange(currentPage - 1);
    const onNextBtnClick = () => onChange(currentPage + 1);

    return (
        <div className={styles.paginator}>
            <button
                className={styles.arrowButton}
                disabled={isFirstPage}
                onClick={onPrevBtnClick}
            >
                <span className={styles.arrowButtonText}>Назад</span>
            </button>
            <div className={styles.pageButtons}>
                {pageButtons()}
            </div>
            <button
                className={`${styles.arrowButton} ${styles.right}`}
                disabled={isLastPage}
                onClick={onNextBtnClick}
            >
                <span className={styles.arrowButtonText}>Вперед</span>
            </button>
        </div>
    )
}