import Player from 'models/entities/player';
import {SearchAccess} from "../enums/event";
import {Timezone} from "../models/entities/city";

export function splitByChunks(array: any[], perChunk = 2) {
  return array.reduce((all, one, i) => {
    const ch = Math.floor(i / perChunk);
    all[ch] = [].concat(all[ch] || [], one);
    return all;
  }, []);
}

export function getPlayerSign(name: Player['name']) {
  return name
    .split(' ')
    .map((s) => s.substr(0, 1).toUpperCase())
    .join('');
}

const mouseClickEvents = ['mousedown', 'click', 'mouseup'];

export function simulateMouseClick(element: any) {
  mouseClickEvents.forEach((mouseEventType) =>
    element.dispatchEvent(
      new MouseEvent(mouseEventType, {
        view: window,
        bubbles: true,
        cancelable: true,
        buttons: 1,
      })
    )
  );
}

export function isTraining(searchAccess: SearchAccess):boolean {
    return [
        SearchAccess.training,
        SearchAccess.externalTraining
    ].includes(searchAccess);
}

export const getTimezoneStr = (timezone: Timezone):string => timezone && timezone > 0 ? `+${timezone}` : `${timezone}`;

export const disabledErrorHandler = {
  'disabledErrorHandler': true
};
