import Button from 'components/UI/Button/Button';
import AlignRight from 'components/UI/CSS/AlignRight/AlignRight';
import ControlHint from 'components/UI/DefaultFormUI/ControlHint/ControlHint';
import { observer } from 'mobx-react';
import EventPeriod, { PeriodData } from 'models/entities/eventPeriod';
import moment, { Moment } from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import SearchPageStore from 'stores/SearchPageStore';
import { SelectOption } from 'models/entities/SelectOption';
import Select from '../../../UI/Select/Select';
import defaultSelectStyles from '../../../UI/Select/Select.module.css';
import createEventSelectStyles from 'components/Pages/Search/SearchResults/CreateSelect.module.css';
import './CreateGame.css';
import Spinner from "../../../UI/Loader/Loader";

let styles = {};
Object.assign(styles, defaultSelectStyles, createEventSelectStyles);

const CreateGame: React.FC<{ period: EventPeriod; index: number }> = observer(
  ({ period, index }) => {

    const [currentPeriodData, setCurrentPeriodData] = useState<PeriodData | null>(null);

    const isSamePeriod = SearchPageStore.periodDataIndex === index;

    useEffect(() => {
      if (!isSamePeriod) {
        setCurrentPeriodData(null);
      }
    }, [isSamePeriod]);

    const selectPeriod = (pd: PeriodData) => {
      setCurrentPeriodData(pd);
      SearchPageStore.setCurrentPeriodData(index);
    };

    return (
      <div className="create-event">
        <div className="create-event__playground">
          <span className="create-event__playground-name">
            {period.arena.name}
          </span>
          <span className="create-event__playground-address">
            ({period.arena.address})
          </span>
        </div>
        <div className="create-event__prices">
          {period.periodData.map((pd) => (
            <div
              className="create-event__price"
              onClick={() => selectPeriod(pd)}
              key={`${pd.startTime}_${pd.endTime}_${pd.minPrice}_${pd.groundId}`}
            >
              <div className="create-event__time">
                {moment(pd.startTime).format('HH:mm')}
                {' - '}
                {moment(pd.endTime).format('HH:mm')}
              </div>
              <div className="create-event__price-start">
                от {pd.minPrice} ₽/час
              </div>
            </div>
          ))}
        </div>
        {currentPeriodData && (
          <CreateGameForm
            pd={currentPeriodData}
            key={currentPeriodData.startTime}
          />
        )}
      </div>
    );
  }
);

const hasTimeHints = [
  'Можно будет создать игру с поиском партнеров для игры',
  'Можно будет создать игру с поиском команд для спарринга',
];

const noTimeHints = [
  'Поиск партнеров для игры доступен за 24 часа(ов)',
  'Поиск партнеров для спарринга доступен за 24 часа(ов)',
];

const CreateGameForm: React.FC<{ pd: PeriodData }> = observer(({ pd }) => {
  const [firstEventDurations, firstEventStartDates] = useMemo(
    () => [
      SearchPageStore.getEventDurations(pd),
      SearchPageStore.getStartDates(pd),
    ],
    [pd]
  );
  const [eventDuration, setEventDuration] = useState(firstEventDurations[0]);
  const [startDate, setStartDate] = useState(firstEventStartDates[0]);
  const eventDurations = SearchPageStore.getEventDurations(pd, startDate.value);
  const eventStartDates = SearchPageStore.getStartDates(pd, eventDuration.id);
  const selectDuration = (sort: SelectOption) => setEventDuration(sort);
  const selectPeriod = (sort: SelectOption & { value: Moment }) =>
    setStartDate(sort);
  const hints = SearchPageStore.check24HoursLimits(startDate.value)
    ? hasTimeHints
    : noTimeHints;

  return (
    <div className="create-event__options">
      <Select
        value={eventDuration}
        onChange={selectDuration}
        options={eventDurations}
        className="select_create-event"
        styles={styles}
      />
      <Select
        value={startDate}
        // @ts-ignore
        onChange={selectPeriod}
        options={eventStartDates}
        className="select_create-event"
        styles={styles}
      />
      <ControlHint>
        <span>{hints[0]}</span>
        <br />
        <span>{hints[1]}</span>
      </ControlHint>

      {startDate && eventDuration && (
        <AlignRight>
          <Button
            onClick={() =>
              SearchPageStore.prepareEvent(
                pd,
                startDate.value,
                eventDuration.id
              )
            }
            style={{ width: 'auto' }}
            buttonText="Создать игру"
          />
        </AlignRight>
      )}
    </div>
  );
});

const CreateGameSection: React.FC = observer(() => {

    const loading = SearchPageStore.requestState === 'pending';

    if (loading) return <Spinner/>

  return SearchPageStore.events &&
    SearchPageStore.events.availablePeriods.length ? (
    <>
      {SearchPageStore.events.availablePeriods.map((p, index) => (
        <CreateGame
          period={p}
          key={`${index}_${p.arena.id}_${p.periodData[0].startTime}`}
          index={index}
        />
      ))}
    </>
  ) : (
    <div className="search__no-results">
      Нет доступных вариантов. Попробуйте изменить дату или фильтр.
    </div>
  );
});

export default CreateGameSection;
