import css from './CampPage.module.scss';
import commonCss from 'css/Common.module.css';
import React, {useEffect} from "react";
import Menu, {listSections} from "./Menu/Menu";
import {useParams} from "react-router";
import CampPageStore from "../../../../stores/CampPageStore";
import {getFormattedDate} from "../CampCard/CampCard";
import {observer} from "mobx-react";
import {Swiper, SwiperSlide} from 'swiper/react';
import Slider, {Slide} from "./Slider/Slider";
import SpinnerWrapper from "../../../UI/Loader/SpinnerWrapper/SpinnerWrapper";
import {Sections} from "./model";
import {Element} from "react-scroll";
import useNotEmptySections from "./useNotEmptySections";
import {VkPixelPush} from "../../../../utils/vkPixel";
import ErrorPage from "../../Error/ErrorPage";
import BackLink from "../../Profile/LKLayout/BackLink/BackLink";
import Page from "../../../Hoc/Page/Page";

const getDates = (dateFrom: string, dateTo: string) => `${getFormattedDate(dateFrom)} - ${getFormattedDate(dateTo)}`;

const CampPage:React.FC = () => {

    const notEmptySections = useNotEmptySections();

    const { slug } = useParams<{ slug: string }>();
    useEffect(() => {
        CampPageStore.getEvent({
            slug
        });
        return () => {
            CampPageStore.resetEventData();
        };
    }, [slug]);

    useEffect(() => {
        VkPixelPush('capm_page_opend');
    }, []);

    const data = CampPageStore.data;
    const loading = CampPageStore.requestState === 'pending';
    const pageNotExist = CampPageStore.campNotExist;

    if (pageNotExist) return <ErrorPage/>;

    const getSports = () => data?.sports.map(sport => sport.name).join(', ');

    const getCoaches = () => data?.coaches.map(coach => {
        const obj:Slide = {};
        if (coach.photo) obj.img = coach.photo;
        if (coach.name) obj.title = coach.name;
        if (coach.description) obj.description = coach.description;
        return obj;
    });

    return (
        <Page>
            {loading && <SpinnerWrapper/>}
            {data ?
                <>
                    <div className={`${commonCss.wrap} ${css.page}`}>
                        <BackLink />
                        {!!data.photos.length && (
                            <Swiper
                                navigation={true}
                                pagination={{ clickable: true }}
                                className={css.mainSlider}
                            >
                                {data.photos.map((photo, index) => (
                                    <SwiperSlide key={`${index}_${photo}`} className={css.mainSlider__slide}>
                                        <img src={photo} className={commonCss.stretchedImg}/>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        )}
                        <div className={css.container}>
                            <div className={css.content}>
                                {notEmptySections[Sections.description] && (
                                    <Element name={Sections.description} className={`${css.sect} ${css.sectMain}`}>
                                        <div className={css.sectMain__topSide}>
                                            <h1 className={css.mainTitle}>{data?.name}</h1>
                                            <div className={css.locationShort}>{data?.location.name}</div>
                                            {!!data.sports.length && <div className={css.sports}>{getSports()}</div>}
                                        </div>
                                        <div className={css.sectMain__bottomSide}>
                                            {data.description && <div>{data?.description}</div>}
                                            <div className={css.datesBlock}>
                                                <div className={css.datesBlock__item}>
                                                    <div className={css.datesBlock__itemTitle}>Дата проведения:</div>
                                                    <div className={css.datesBlock__itemValue}>{getDates(data.dateFrom, data.dateTo)}</div>
                                                </div>
                                                <div className={css.datesBlock__item}>
                                                    <div className={css.datesBlock__itemTitle}>Организатор:</div>
                                                    <div className={css.datesBlock__itemValue}>{data.organizer.name}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </Element>
                                )}
                                {notEmptySections[Sections.conditions] && (
                                    <Element name={Sections.conditions} className={`${css.sect}`}>
                                        <h2 className={css.sectTitle}>{listSections[Sections.conditions]}</h2>
                                        <div className={css.accommodations}>
                                            {data.accommodations.map(item =>
                                                <div className={css.accommodation} key={`${item.name}_${item.photo}`}>
                                                    <div className={css.photo}>
                                                        <img src={item.photo} alt="Проживание" className={commonCss.stretchedImg}/>
                                                    </div>
                                                    <div className={css.accommodation__content}>
                                                        {item.name && (
                                                            <div className={css.accommodation__item}>
                                                                <div className={css.accommodation__itemTitle}>Название</div>
                                                                <div className={css.accommodation__itemDesc}>{item.name}</div>
                                                            </div>
                                                        )}
                                                        {item.address && (
                                                            <div className={css.accommodation__item}>
                                                                <div className={css.accommodation__itemTitle}>Адресс:</div>
                                                                <div className={css.accommodation__itemDesc}>{item.address}</div>
                                                            </div>
                                                        )}
                                                        {!!item.facilities.length && (
                                                            <div className={css.accommodation__item}>
                                                                <div className={css.accommodation__itemTitle}>Условия проживания:</div>
                                                                <ul className={css.list}>
                                                                    {item.facilities.map(item =>
                                                                        <div key={item} className={css.list__item}>{item}</div>
                                                                    )}
                                                                </ul>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </Element>
                                )}
                                {notEmptySections[Sections.schedules] && (
                                    <Element name={Sections.schedules} className={`${css.sect}`}>
                                        <h2 className={css.sectTitle}>{listSections[Sections.schedules]}</h2>
                                        <div>
                                            {data.schedules.map(item =>
                                                <div className={css.schedule} key={`${item.dateFrom}_${item.dateTo}`}>
                                                    <div className={css.schedule__date}>{getDates(item.dateFrom, item.dateTo)}</div>
                                                    <div className={css.schedule__desc}>{item.description}</div>
                                                </div>
                                            )}
                                        </div>
                                    </Element>
                                )}
                                {notEmptySections[Sections.coaches] && !!getCoaches()?.length && (
                                    <Element name={Sections.coaches} className={`${css.sect}`}>
                                        <h2 className={css.sectTitle}>{listSections[Sections.coaches]}</h2>
                                        <Slider
                                            slides={getCoaches() as Slide[]}
                                        />
                                    </Element>
                                )}
                                {notEmptySections[Sections.price] && (
                                    <Element name={Sections.price} className={`${css.sect}`}>
                                        <h2 className={css.sectTitle}>{listSections[Sections.price]}</h2>
                                        <div>{data.price}</div>
                                    </Element>
                                )}
                                {notEmptySections[Sections.services] && (
                                    <Element name={Sections.services} className={`${css.sect}`}>
                                        <h2 className={css.sectTitle}>{listSections[Sections.services]}</h2>
                                        <div>{data.service}</div>
                                    </Element>
                                )}
                                {notEmptySections[Sections.location] && (
                                    <Element name={Sections.location} className={`${css.sect}`}>
                                        <h2 className={css.sectTitle}>{listSections[Sections.location]}</h2>
                                        <div>{data.place}</div>
                                        {data.mapUrl && (
                                            <iframe
                                                src={data.mapUrl}
                                                title={data.name}
                                                width="100%"
                                                height="300"
                                                frameBorder="0"
                                                className={css.map}
                                            />
                                        )}
                                    </Element>
                                )}
                            </div>
                            <Menu/>
                        </div>
                    </div>
                </> :
                null}
        </Page>
    );
};

export default observer(CampPage);