import React, {SyntheticEvent} from "react";
import styles from "./Widget.module.scss";

interface Props {
    iconSrc: string;
    iconWidth?: number;
    iconHeight?: number;
    label?: string | number;
    onClick?: (e: SyntheticEvent) => void;
}

export const Widget: React.FC<Props> = (props) => {
    return (
        <div
            className={styles.widget}
            onClick={props.onClick}
        >
            <img
                className={styles.icon}
                src={props.iconSrc}
                width={props.iconWidth}
                height={props.iconHeight}
                alt=""
            />
            {(props.label || null) &&
                <div className={styles.label}>
                    {props.label}
                </div>
            }
        </div>
    )
}