import lkStyle from 'components/Pages/Profile/LKLayout/LKLayout.module.css';
import AuthorizationStore from 'stores/AuthorizationStore';

interface Props {
  mobile?: boolean;
}

function ExitButton({ mobile }: Props) {
  return (
    <div
      className={`${lkStyle.exit} ${mobile ? lkStyle.mobile : ''}`}
      onClick={() => AuthorizationStore.doLogout()}
    >
      Выход
    </div>
  );
}

export default ExitButton;
