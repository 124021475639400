import css from './Loader.module.scss';
import spinner from 'imgs/spinner.gif';
import {WithChildren} from "../../../models/technical/withProps";
import classNames from "classnames";

interface SpinnerProps extends WithChildren {
    text?: string
}

const Spinner:React.FC<SpinnerProps> = ({
    text = 'Загрузка',
    className
                                        }) => {
    return (
        <div className={classNames(
            css.wrapper,
            className
        )}>
            <div className={css.text}>{text}</div>
            <img
                src={spinner}
                alt={text}
                className={css.img}
            />
        </div>
    );
}

export default Spinner;