import PasswordRecoveryRequest from '../models/requests/passwordRecovery';
import DropPasswordRequest from '../models/requests/dropPassword';
import LoginRequest from '../models/requests/login';
import SignUpRequest from '../models/requests/signup';
import MailExistResponse from '../models/responses/mailExists';
import backendApi from 'api/config';

class AuthAPI {
  // TODO добавить метод начала подтверждения почты initiate

  async getMailExist(): Promise<MailExistResponse> {
    const r = await backendApi.get('accounts/exists');
    return r.data;
  }

  async sendLogin(data: LoginRequest): Promise<boolean> {
    const r = await backendApi.post('/accounts/login', data);
    return r.status === 200;
  }

  async sendChangePassword(data: PasswordRecoveryRequest): Promise<boolean> {
    const r = await backendApi.post('/accounts/reset_password_complete', data);
    return r.status === 200;
  }

  async sendDropPassword(data: DropPasswordRequest): Promise<boolean> {
    const r = await backendApi.post('/accounts/reset_password_initiate', data);
    return r.status === 200;
  }

  async sendSignup(data: SignUpRequest): Promise<boolean> {
    const r = await backendApi.post('/accounts/signup', data);
    return r.status === 200;
  }

  async sendLogout(): Promise<boolean> {
    const r = await backendApi.post('/accounts/logout');
    return r.status === 200;
  }

  async sendEmail(): Promise<{ delay: number }> {
    const r = await backendApi.post('/accounts/confirm_initiate');
    return r.data;
  }
}

const authAPI = new AuthAPI();
export default authAPI;
