import css from './PartnerJoinInput.module.css';
import FormInputProps from '../../../models/ui/form/formInputProps';

function PJInput(props: FormInputProps<any>) {
  return (
    <div className={css.formGroup}>
      <input
        {...props}
        className={`${css.formControl} ${props.error ? css.error : ''}`}
      />
    </div>
  );
}

export default PJInput;
