export const timePeriodList = [
  { name: '1 час', id: 2 },
  { name: '1.5 часа', id: 3 },
  { name: '2 часа', id: 4 },
  { name: '2.5 часа', id: 5 },
  { name: '3 часа', id: 6 },
  { name: '3.5 часа', id: 7 },
  { name: '4 часа', id: 8 },
  { name: '4.5 часа', id: 9 },
  { name: '5 часов', id: 10 },
  { name: '5.5 часов', id: 11 },
  { name: '6 часов', id: 12 },
  { name: '6.5 часов', id: 13 },
  { name: '7 часов', id: 14 },
  { name: '7.5 часов', id: 15 },
  { name: '8 часов', id: 16 },
  { name: '8.5 часов', id: 17 },
  { name: '9 часов', id: 18 },
  { name: '9.5 часов', id: 19 },
  { name: '10 часов', id: 20 },
  { name: '10.5 часов', id: 21 },
  { name: '11 часов', id: 22 },
  { name: '11.5 часов', id: 23 },
  { name: '12 часов', id: 24 },
  { name: '12.5 часов', id: 25 },
  { name: '13 часов', id: 26 },
  { name: '13.5 часов', id: 27 },
  { name: '14 часов', id: 28 },
  { name: '14.5 часов', id: 29 },
  { name: '15 часов', id: 30 },
  { name: '15.5 часов', id: 31 },
  { name: '16 часов', id: 32 },
  { name: '16.5 часов', id: 33 },
  { name: '17 часов', id: 34 },
];

export const months = [
  'января',
  'февраля',
  'марта',
  'апреля',
  'мая',
  'июня',
  'июля',
  'августа',
  'сентября',
  'октября',
  'ноября',
  'декабря',
];
