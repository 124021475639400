import css from './NavLink.module.scss';
import { WithChildren } from 'models/technical/withProps';

interface NavLinkProps extends WithChildren {
    topPrefix?: JSX.Element | string,
    onClick?: () => void,
    active?: boolean
}

const NavLink:React.FC<NavLinkProps> = ({
    children,
    topPrefix,
    onClick,
    className,
    active
                                        }) => {
    return (
        <div className={`${css.navLink} ${className} ${active ? css.active : ''}`} onClick={onClick}>
            <span className={css.navLink__topPrefix}>{topPrefix}</span>
            <span className={css.navLink__text}>{children}</span>
        </div>
    );
};

export default NavLink;