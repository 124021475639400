import moment from 'moment';
import UserStore from "../stores/UserStore";

type DateTime = string | Date;

function isToday(datetime: DateTime) {
  return moment(datetime).isSame(UserStore.getCurrentTime(), 'day');
}

function prettify(datetime: DateTime, format: string) {
  return moment(datetime).format(format);
}

function getTime(datetime: DateTime) {
  return moment(datetime).format('HH:mm');
}

const datetime = {
  isToday,
  prettify,
  getTime,
}

export default datetime;
