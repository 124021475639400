import GameEvent from '../models/entities/gameEvent';
import backendApi from './config';
import Pagination from '../models/entities/pagination';
import ChatMessage from '../models/entities/chat';
import { disabledErrorHandler } from "utils/functions";

class ChatAPI {
  prefix = '/events/';
  async getChat(hash: GameEvent['hash']): Promise<Pagination<ChatMessage>> {
    const r = await backendApi.get(this.prefix + `${hash}/chat/`, { data: disabledErrorHandler });
    return await r.data;
  }

  async getNextChat(url: string): Promise<Pagination<ChatMessage>> {
    const r = await backendApi.get(url, { data: disabledErrorHandler });
    return await r.data;
  }

  async sendMessage(
    message: string,
    hash: GameEvent['hash']
  ): Promise<Pagination<ChatMessage>> {
    const r = await backendApi.post(this.prefix + `${hash}/chat/`, { message });
    console.log(r);
    return await r.data;
  }
}

const chatAPI = new ChatAPI();
export default chatAPI;
