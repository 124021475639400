import browserHistory from 'browserHistory';
import GameHint from 'components/Pages/Profile/ProfilePage/Games/GameHint';
import OrangeLink from 'components/UI/CSS/OrangeLink/OrangeLink';
import style from './Games.module.scss';
import commonCss from 'css/Common.module.css';
import React, { useEffect, useState } from 'react';
import EventInfo from '../../../../../models/entities/eventInfo';
import api from '../../../../../api';
import User from '../../../../../models/entities/user';
import { observer } from 'mobx-react';
import ProfileStore from '../../../../../stores/ProfileStore';
import EventCard from "../../../../common/EventCard/EventCard";
import Spinner from "../../../../UI/Loader/Loader";
import cssTabs from "../Tabs.module.scss";

function MyTrainings() {

  const [games, setGames] = useState<EventInfo[] | null>(null);
  const [pending, setPending] = useState<boolean>(false);

  const fetch = async () => {
    setPending(true);
    const games = await api.profile.getFutureTrainings(
      ProfileStore.profile?.id as User['id']
    );
    setPending(false);
    setGames(games.results);
  };

  useEffect(() => {
    fetch();
  }, []);

  if (pending) return <Spinner className={cssTabs.spinner}/>

  if (!games) return null;

  return (
    <div className={style.games}>
      <GameHint>
        <div className={style.gameHintCenter}>
          <OrangeLink
            className={commonCss.font14}
            onClick={() => browserHistory.push(`/search?tabId=0&event_type=trainings`)}
          >
            Выбрать тренировку
          </OrangeLink>
        </div>
      </GameHint>
      {games.map((e) => (
        <div className={style.gridItem} key={e.event.id}>
          <EventCard event={e} key={e.event.hash} />
        </div>
      ))}
    </div>
  );
}

export default observer(MyTrainings);
