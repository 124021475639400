import {Gender} from "../models/entities/gender";
import linearGradient from "../css/linearGradient.module.css";

export const gendersText: Record<Gender, string> = {
    [Gender.any]: 'Любой',
    [Gender.man]: 'Мужской',
    [Gender.woman]: 'Женский'
};

export const dateFilterQueryFormat = 'DD.MM.YYYY';

export const arrGradients = Object.keys(linearGradient);