import React from 'react';
import styles from './FAQSection.module.scss';
import store from '../../../../stores/FAQStore';
import { observer } from 'mobx-react';
import { FAQNav } from '../FAQNav/FAQNav';
import { FAQArticle } from '../FAQArticle/FAQArticle';
import {FaqItem} from "../../../../models/entities/faqItem";

interface FAQSectionProps {
    items: FaqItem[],
    selectedItem: FaqItem
}

const FAQSection:React.FC<FAQSectionProps> = ({
    items,
    selectedItem
                                              }) => {

  const onItemSelect = (title: string) => {
    store.selectItem(title);
  };

  return (
    <section className={styles.section}>
      <div className={styles.col1}>
        <FAQNav
          title="Вопросы и ответы"
          items={items}
          selectedItem={selectedItem}
          onSelect={onItemSelect}
        />
      </div>
      <div className={styles.col2}>
        <FAQArticle faqItem={selectedItem} />
      </div>
    </section>
  );
};

export default observer(FAQSection);
