import browserHistory from 'browserHistory';
import ProfileAvatar from 'components/UI/ProfileAvatar/ProfileAvatar';
import { observer } from 'mobx-react';
import React from 'react';
import UserStore from 'stores/UserStore';
import ModalStore from '../../../stores/ModalStore';
import LoginForm from '../../Pages/shared/LoginForm/LoginForm';
import { NavLink } from 'react-router-dom';
import HeaderCity from './Cities/HeaderCity';
import './Header.scss';
import userBlockCss from './UserBlock.module.scss';
import PersonalNotificationsWidget from "./PersonalNotificationsWidget/PersonalNotificationsWidget";
import ChatNotificationsWidget from "./ChatNotificationsWidget/ChatNotificationsWidget";

function SignInButtons() {
  return (
    <div
      className="header__signIn"
      onClick={() => {
        ModalStore.showModal(<LoginForm />);
      }}
    >
      Войти
    </div>
  );
}

const UserBlock = observer(() => {
  return (
    <div
      className={userBlockCss.userBlock}
      onClick={() => browserHistory.push('/cabinet')}
    >
      <ProfileAvatar
        alt={UserStore.userData?.user?.name || ''}
        src={UserStore.userData?.user?.photo}
        styles={userBlockCss}
      />
      <div className="profileText">
        <div className="profileText__name">
          {UserStore.userData?.user?.name || UserStore.userData?.user?.email}
        </div>
        <div className="profileText__balance">
          {UserStore.userData?.balance || 0} ₽
        </div>
      </div>
    </div>
  );
});

const Header = observer((): JSX.Element => {
  return (
    <header className="header">
      <div className="header__wrapper">
        <NavLink to="/">
          <div className="header__logo" />
        </NavLink>
        <div className="header__auth-container">
          <HeaderCity />
          {UserStore.userData?.user &&
              <div className="header__widgets-container">
                <PersonalNotificationsWidget/>
                <ChatNotificationsWidget/>
              </div>
          }
          {UserStore.userData?.user ? <UserBlock /> : <SignInButtons />}
        </div>
      </div>
    </header>
  );
});

export default Header;
