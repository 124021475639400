import UserStore from '../../../stores/UserStore';
import ModalStore from '../../../stores/ModalStore';
import LoginForm from '../../Pages/shared/LoginForm/LoginForm';
import React from 'react';

const authorizedClick = (e: any) => {
  if (!UserStore.userData?.user) {
    e.preventDefault();
    ModalStore.showModal(<LoginForm />);
  }
};

export default authorizedClick;
