import React from 'react';
import { observer } from 'mobx-react';
import Button from '../../../../UI/Button/Button';
import { FormField } from '../../../../Hoc/Form/Field';
import { FormInput } from '../../../../UI/FormWithLabelsUI/FormInput/FormInput';
import Player from '../../../../../models/entities/player';
import Form from '../../../../Hoc/Form/Form';
import { useForm } from 'react-hook-form';
import formInput from './FormInput.module.css';
import EventViewStore from '../../../../../stores/EventViewStore';
import SendTicketRequest from '../../../../../models/requests/sendTicket';
import css from './SendTicketForm.module.scss';

function SendTicketForm({
  player,
  hideForm,
}: {
  player: Player;
  hideForm: () => void;
}) {

  const form = useForm({ defaultValues: { playerId: player.id, email: '' } });

  return (
      <Form
          formReturn={form}
          onSubmit={async (d: SendTicketRequest) => {
              await EventViewStore.sendTicket(d);
              form.reset({ playerId: player.id, email: '' });
              hideForm();
          }}
          className={css.form}
      >
          <FormField
              Component={FormInput}
              name="email"
              styles={formInput}
              placeholder="Введи новый E-Mail"
          />
          <FormField
              Component={FormInput}
              name="playerId"
              styles={formInput}
              type="hidden"
          />
          <Button
              type="submit"
              buttonText="Изменить"
              disabled={form.formState.isSubmitting}
              className={css.button}
          />
      </Form>
  );
}

export default observer(SendTicketForm);
