import {makeAutoObservable} from "mobx";
import {CampEventResp} from "../models/responses/camps";
import {CampEventReq} from "../models/requests/camps";
import api from "../api";
import {RequestState} from "../models/responses/common";

class CampPageStore {
    constructor() {
        makeAutoObservable(this);
    }
    data?: CampEventResp | null;
    requestState: RequestState = 'initial';
    campNotExist?: boolean

    async getEvent(data: CampEventReq) {
        try {
            this.requestState = 'pending';
            const r = await api.camps.getEvent(data);
            this.getEventDone(r);
        }
        catch (e: any) {
            this.data = null;
            this.requestState = 'error';
            console.log(e);
            if (e?.error?.response?.data?.code === 'camp_not_found') {
                this.campNotExist = true;
            }
        }
    }

    getEventDone(data: CampEventResp) {
        this.requestState = 'done';
        this.data = data;
    }

    resetEventData() {
        this.data = null;
        this.requestState = 'initial';
        this.campNotExist = false;
    }

}

export default new CampPageStore();