import React from "react";
import Button from 'components/UI/Button/Button';
import css from "./remindPhonePopup.module.scss";
import { useHistory } from "react-router-dom";
import ModalStore from "../../../stores/ModalStore";

const RemindPhonePopup: React.FC = () => {
    const history = useHistory();
    return (
        <div>
            <p className={css.title}>Уважаемый пользователь</p>
            <p className={css.text}>Команда SPLOTEAM просит указать контактные данные. Номер телефона необходим для возможности связаться с вами нашим администратором. Например, для информировании об изменениях в ваших событиях.<br/><br/>Мы дорожим каждым из вас! <span className={css.heart}>❤</span>, поэтому никому и ни при каких условиях, не передаем личную информацию.</p>
            <Button buttonText={'Перейти в профиль'} className={css.button} onClick={() => {
                history.push('/cabinet/edit');
                ModalStore.hideModal();
            }}/>
        </div>
    );
};

export default RemindPhonePopup;