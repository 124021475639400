import css from './MapSidebar.module.scss';
import placemark from './placemark.png';
import EventInfo from "../../../../../models/entities/eventInfo";
import crossIcon from 'imgs/close-icon.svg';
import classNames from "classnames";
import EventCard from "../../../../common/EventCard/EventCard";

export interface MapSidebarProps {
    events: EventInfo[],
    arenaName: string,
    address: string,
    onClose: () => void,
    date: string,
    positionFixed?: boolean,
}

const MapSidebar:React.FC<MapSidebarProps> = ({
    events,
    arenaName,
    address,
    onClose,
    date,
    positionFixed
                                              }) => {
    return (
        <div className={classNames(
            css.container,
            positionFixed && css.positionFixed
        )}>
            <div className={css.title}>Игры на {date}</div>
            <div className={css.arenaName}>{arenaName}</div>
            <div className={css.locationRow}>
                <img src={placemark} alt="Локация" className={css.placemark}/>
                <div className={css.location}>{address}</div>
            </div>
            <img src={crossIcon} alt="Закрыть" className={css.crossIcon} onClick={onClose}/>
            <div className={css.items}>
                {events.map(item =>
                    <div className={css.item} key={item.event.id}>
                        <EventCard event={item}/>
                    </div>
                )}
            </div>
        </div>
    );
};


export default MapSidebar;