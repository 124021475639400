import css from './CoachCard.module.scss';
import Coach from 'models/entities/coach';

interface CoachCardProps {
    data: Coach,
    className?: string,
    onClick?: () => void
}

const CoachCard:React.FC<CoachCardProps> = ({
    data,
    className,
    onClick
                                   }) => {

    const {
        name,
        photo
    } = data;

    return (
        <div className={`${css.coach} ${className ? className : ''}`} onClick={onClick}>
            <div className={`${css.coach__imgWrapper}`}>
                <img src={photo ? photo : require('imgs/camera_200.png').default} alt={name} className={css.coach__img}/>
            </div>
            <div className={css.coach__name}>
                {name}
            </div>
        </div>
    );
};

export default CoachCard;