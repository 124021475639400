import * as React from 'react';
import ModalStore from '../../../../../stores/ModalStore';
import Button from '../../../../UI/Button/Button';
const s = require('./css/avatar.module.css');

interface Props {
  readImage: (e: any) => void;
}

export class AvatarSelectPresenter extends React.Component<Props> {
  public render() {
    return (
      <div className={s.avatarModal}>
        <span className={s.avatarSelectTitle}>Выберите аватар пользователя</span>
        <br/>
        <br/>
        <span className={s.avatarSelectText}>Загрузить изображение:</span>
        <div className={s.avatarSelectInput}>
          <input type="file" onChange={this.props.readImage}/>
        </div>
        <br/>
        <div className={s.avatarBtnGroup}>
          <Button buttonText={'Закрыть'} onClick={() => ModalStore.hideModal()}/>
        </div>
      </div>
    );
  }
}
