import { observer } from 'mobx-react';
import React from 'react';
import ReactDOM from 'react-dom';
import 'css/index.css';
import './moment';
import appStore from 'stores/AppStore';
import App from './components/Hoc/App/App';
import reportWebVitals from './reportWebVitals';
import setMobHandler from './utils/setMobHandler';
import ym, { YMInitializer } from 'react-yandex-metrika';
import browserHistory from 'browserHistory';
import { DOMAIN } from './configuration';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

const AppWrap = observer(() => <App key={appStore.appKey} />);
console.log('VERSION', process?.env?.REACT_APP_VERSION);
setMobHandler();

if (process.env.NODE_ENV === 'production') {
  browserHistory.listen((location) => {
    ym('hit', `${DOMAIN}${location.pathname}`);
  });
  Sentry.init({
    dsn: 'https://d0105c9a497c42248c72d850bae83f62@o104838.ingest.sentry.io/6731792',
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <React.StrictMode>
    {process.env.NODE_ENV === 'production' ? (
      <YMInitializer
        accounts={[89251988]}
        options={{
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
          webvisor: true,
        }}
      />
    ) : null}
    <AppWrap />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
