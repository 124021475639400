import React from 'react';
import CheckboxGroupProps from '../../../models/ui/form/checkboxGroupProps';
import css from './CheckboxGroup.module.scss';
import CheckboxData from '../../../models/ui/form/checkboxData';
import Checkbox from '../Checkbox/Checkbox';


const CheckboxGroup: React.FC<CheckboxGroupProps> = ({
  options,
  selectedOptions,
  label,
  onChange,
  style,
  ...rest
}) => {
  const selectedOptionsMap = new Map<CheckboxData['value'], CheckboxData>(
    (selectedOptions || []).map((option) => [option.value, option])
  );

  const onCheckboxChange = (data: CheckboxData, value: boolean) => {
    if (value) {
      selectedOptionsMap.set(data.value, data);
    } else {
      selectedOptionsMap.delete(data.value);
    }

    onChange(Array.from(selectedOptionsMap.values()), rest);
  };

    return (
    <div className={`${css.checkboxGroup} ${style && css[style]}`}>
      {label && <p className={css.checkboxGroup__title}>{label}</p>}
      {options.map((data) => (
        <Checkbox
          onChange={onCheckboxChange}
          label={data.label}
          value={data.value}
          checked={selectedOptionsMap.has(data.value)}
          stylesModifier={style}
          key={data.value}
        />
      ))}
    </div>
  );
};

export default CheckboxGroup;
