import IChatMessage from '../../../../../models/entities/chat';
import moment from 'moment';
import UserStore from '../../../../../stores/UserStore';
import css from './Chat.module.scss';
import { observer } from 'mobx-react';
import ChatAvatar from './ChatAvatar';

interface Props {
  chatMessage: IChatMessage;
}

function ChatMessage({ chatMessage: { message, user, createdAt, id }}: Props) {
  const currentUser = user?.id === UserStore.userData?.user?.id;
  return (
    <div id={id.toString()} className={`${css.chat__body__message} ${currentUser ? css.me : ''}`}>
      <div className={css.chat__body__message__photo}>
        <ChatAvatar user={user} />
      </div>
      <div className={css.chat__body__message__body}>
        <div className={css.chat__body__message__text}>{message}</div>
        <div className={css.chat__body__message__time}>
          {moment(createdAt).format('H:mm a')}
        </div>
      </div>
    </div>
  );
}

export default observer(ChatMessage);
