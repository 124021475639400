import React from "react";
import styles from "./PageButton.module.scss";

interface Props {
    page: number;
    selected?: boolean;
    isBreakButton?: boolean;
    onClick: (page: number) => void,
}


export const PageButton: React.FC<Props> = (props) => {
    const displayedNumber = props.isBreakButton ? "..." : props.page + 1;

    const onClick = () => {
        if (props.selected) {
            return;
        }
        props.onClick(props.page);
    }

    return (
        <div
            className={`${styles.pageButton} ${props.selected ? styles.selected : ""}`}
            onClick={onClick}
        >
            {displayedNumber}
        </div>
    )
}