import { observer } from 'mobx-react';
import { onSelectChange } from 'models/entities/SelectOption';
import React from 'react';
import SearchPageStore from 'stores/SearchPageStore';
import { defaultLevel } from '../../../../common/gameDefaults';
import {GrayRoundedSelect} from "../../../../UI/Select/GrayRoundedSelect";
import {WithChildren} from "../../../../../models/technical/withProps";

interface PlayerLevelFilterProps extends WithChildren {}

const PlayerLevelFilter:React.FC<PlayerLevelFilterProps> = observer(({
    className
                                                                     }) => {
    const currentLevel = SearchPageStore.getCurrentPlayerLevelOrNone();
    const onChange:onSelectChange = (data) => {
        SearchPageStore.setPlayerLevelFilter(data.id);
    }
    return currentLevel && SearchPageStore.events ? (
        <GrayRoundedSelect
            value={currentLevel}
            options={[
            defaultLevel,
            ...SearchPageStore.events.playerLevels.map((pl) => ({
                id: pl.id,
                name: pl.name
            })),
            ]}
            onChange={onChange}
            className={className}
            withCrossIcon={true}
            notFlex={true}
        />
    ) : null;
});

export default PlayerLevelFilter;
