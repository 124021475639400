import { observer } from 'mobx-react';
import { onSelectChange } from 'models/entities/SelectOption';
import React from 'react';
import SearchPageStore from 'stores/SearchPageStore';
import { defaultArena } from '../../../../common/gameDefaults';
import {GrayRoundedSelect} from "../../../../UI/Select/GrayRoundedSelect";
import {WithChildren} from "../../../../../models/technical/withProps";

interface ArenaFilterProps extends WithChildren {}

const ArenaFilter:React.FC<ArenaFilterProps> = observer(({
    className
                                                         }) => {
    const arena = SearchPageStore.getCurrentArenaOrNone();
    const onChange:onSelectChange = (data) => {
        SearchPageStore.setArenaFilter(data.id);
    }
    return SearchPageStore.events && arena ? (
      <GrayRoundedSelect
        value={arena}
        onChange={onChange}
        options={[defaultArena, ...SearchPageStore.events.arenas.map(item => ({
            id: item.id,
            name: item.name
        }))]}
        className={className}
        withCrossIcon={true}
        notFlex={true}
      />
    ) : null;
});

export default ArenaFilter;
