import OrangeLink from "../../../../UI/CSS/OrangeLink/OrangeLink";
import {useHistory} from "react-router-dom";
import ModalStore from "../../../../../stores/ModalStore";

const FutureEventsExistsAndNotEmptyWallet:React.FC = () => {

    const history = useHistory();
    const redirectToSupport = () => {
        history.push('/support/');
        ModalStore.hideModal();
    }

    return (
        <div>
            Для удаления ваших данных необходимо выйти из всех игр/тренировок и получить возврат средств с личного счета Сплотим.<br/><br/>
            Проверьте пожалуйста свои события. Для получения возврата средств свяжитесь с
            <OrangeLink style={{ display: 'inline', marginLeft: '7px' }} onClick={redirectToSupport}>поддержкой</OrangeLink>
        </div>
    );
}

export default FutureEventsExistsAndNotEmptyWallet;