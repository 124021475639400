import browserHistory from 'browserHistory';
import * as React from 'react';
import UserStore from 'stores/UserStore';

function withClientAuth(
  WrappedComponent: React.ComponentType
): React.ComponentType {
  return class ClientAuthorizedResource extends React.Component {
    public componentDidMount() {
      this.redirectToMain();
    }

    redirectToMain() {
      if (!UserStore.userData?.user) {
        browserHistory.replace('/');
      }
    }

    public componentDidUpdate() {
      this.redirectToMain();
    }

    public render() {
      if (UserStore.userData?.user) {
        return <WrappedComponent {...this.props} />;
      }
      return null;
    }
  };
}

export default withClientAuth;
