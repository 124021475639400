import Coach from "../../../../../../models/entities/coach";
import css from './CoachPopup.module.scss';
import moment from "moment";

interface CoachPopupProps {
    data: Coach
}

const CoachPopup:React.FC<CoachPopupProps> = ({
    data
                                              }) => {

    const {
        photo,
        name,
        dateOfBirth,
        description,
        experience,
        phone,
        rank
    } = data;

    return (
        <div className={css.container}>
            <div className={`${css.imgWrapper}`}>
                <img src={photo ? photo : require('imgs/camera_200.png').default} alt={name} className={css.img}/>
            </div>
            <ul className={css.list}>
                {name && (
                    <li>
                        Имя: <span className={css.value}>{name}</span>
                    </li>
                )}
                {phone && (
                    <li>
                        Телефон: <a href={`tel:${phone}`} className={css.value}>{phone}</a>
                    </li>
                )}
                {dateOfBirth && (
                    <li>
                        Дата рождения: <span className={css.value}>{moment(dateOfBirth).format('DD.MM.yyyy')}</span>
                    </li>
                )}
                {experience && (
                    <li>
                        Опыт: <span className={css.value}>{experience}</span>
                    </li>
                )}
                {rank && (
                    <li>
                        Уровень: <span className={css.value}>{rank}</span>
                    </li>
                )}
                {description && (
                    <li>
                        Описание: <span className={css.value}>{description}</span>
                    </li>
                )}
            </ul>
        </div>
    );
};

export default CoachPopup;