import { WithChildren } from 'models/technical/withProps';
import React from 'react';
import stub from './img/avatarStub.svg';
import defaultStyles from './ProfileAvatar.module.css';
import { getPlayerSign } from 'utils/functions';
import linearGradient from "css/linearGradient.module.css";
import noPhoto from 'imgs/no-photo.png';

interface Props extends WithChildren {
  src?: string | null;
  alt: string;
  noPhoto?: boolean
}

const arrGradients = Object.keys(linearGradient);
function getGradient(name: string = '') {
  return arrGradients[name.length % arrGradients.length];
}

function ProfileAvatar(props: Props) {

  const css = props.styles ? props.styles : defaultStyles;

  return (
    <div className={css.avatarWrapper}>
      {props.src && !props.noPhoto ? (
        <img
          className={css.avatar}
          src={props.src || stub}
          alt={props.alt}
          style={props.style}
        />
      ) :
          <div className={`${css.avatar} ${css.noImg} ${linearGradient[getGradient(props.alt)]}`}>
                <span className={css.initials}>
                  { props.alt ? getPlayerSign(props.alt) : '' }
                </span>
          </div>
      }
      {!props.src && props.noPhoto && (
          <img
              className={css.noPhoto}
              src={noPhoto}
              alt={'Нет фото'}
          />
      )}
    </div>
  );
}

export default ProfileAvatar;
