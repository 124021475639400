import { WithChildren } from 'models/technical/withProps';
import React from 'react';
import styles from './AlignRight.module.css';

const AlignRight = ({ children, style }: WithChildren) => (
  <div className={styles.alignRight} style={style}>
    {children}
  </div>
);

export default AlignRight;
