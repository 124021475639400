import React, { useState } from 'react';
import SignUp from '../../shared/LoginForm/SignUp';
import SignIn from '../../shared/LoginForm/SignIn';
import './LoginForm.css';

export type AuthTabId = 'signIn' | 'signUp' | 'restorePass';

function getAuthTab(tabId: string) {
  switch (tabId) {
    case 'signUp':
      return <SignUp />;
    default:
      return <SignIn />;
  }
}

const LoginForm = () => {
  const [active, setActive] = useState('signIn');
  const setActiveTab = (id: AuthTabId) => setActive(id);
  const renderAuthTab = (id: AuthTabId, tabName: string) => {
    return (
      <div
        className={`auth-tabs__item ${
          active === id ? 'auth-tabs__item_active' : ''
        }`}
        onClick={() => setActiveTab(id)}
      >
        {tabName}
      </div>
    );
  };
  return (
    <div className="auth">
      <div className="auth-tabs">
        {renderAuthTab('signIn', 'Вход')}
        {renderAuthTab('signUp', 'Регистрация')}
      </div>
      {getAuthTab(active)}
    </div>
  );
};

export default LoginForm;
