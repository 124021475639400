import api from 'api';
import { makeAutoObservable } from 'mobx';
import PasswordRecoveryRequest from 'models/requests/passwordRecovery';
import DropPasswordRequest from 'models/requests/dropPassword';
import LoginRequest from 'models/requests/login';
import SignUpRequest from 'models/requests/signup';
import appStore from 'stores/AppStore';
import ModalStore from 'stores/ModalStore';
import UserStore from 'stores/UserStore';
import browserHistory from '../browserHistory';
import AccountConfirmModal from '../components/Pages/shared/LoginForm/AccountConfirmModal/AccountConfirmModal';
import RestorePassSuccess from "components/Pages/shared/LoginForm/RestorePassModals/RestorePassSuccess";

type LoginCallback = undefined | (() => void) | Promise<void>;

class AuthentificationStore {
  loginCallback: LoginCallback = undefined;
  constructor() {
    makeAutoObservable(this);
  }

  setLoginCallback(data: LoginCallback) {
    this.loginCallback = data;
  }

  async doLogin(data: LoginRequest) {
    let isOk;
    try {
      isOk = await api.auth.sendLogin(data);
    } catch (e: any) {
      const handled = this.handleAccountNotConfirmed(e);
      if (!handled) throw e;
    }
    if (isOk) {
      await UserStore.getUserData();
      ModalStore.hideModal();
      if (this.loginCallback && typeof this.loginCallback === 'function') {
        this.loginCallback();
        this.setLoginCallback(undefined);
      }
    }
  }

  async doSignUp(data: SignUpRequest) {
    const isOk = await api.auth.sendSignup(data);
    if (isOk) {
      ModalStore.showModal(<AccountConfirmModal />);
    }
  }

  async doLogout() {
    const isOk = await api.auth.sendLogout();
    if (isOk) {
      if (UserStore.userData) UserStore.userData = undefined;
      appStore.refreshApp();
    }
  }

  async doResetPassword(data: DropPasswordRequest) {
    const isOk = await api.auth.sendDropPassword(data);
    if (isOk) ModalStore.hideModal();
  }

  async doChangePassword(data: PasswordRecoveryRequest) {
    const isOk = await api.auth.sendChangePassword(data);
    if (isOk) {
      ModalStore.showModal(<RestorePassSuccess/>);
      browserHistory.push('/');
    }
  }

  handleAccountNotConfirmed(e: any) {
    const isAccountNotConfirmed =
      e?.error?.response?.data?.code === 'account_not_confirmed';
    if (isAccountNotConfirmed) {
      ModalStore.showModal(
        <AccountConfirmModal delay={e?.error?.response?.data?.context?.delay} />
      );
      return true;
    }
    return false;
  }
}

const authStore = new AuthentificationStore();
export default authStore;
