import ControlHint from 'components/UI/DefaultFormUI/ControlHint/ControlHint';
import ControlLabel from 'components/UI/DefaultFormUI/ControlLabel/ControlLabel';
import FormControl from 'components/UI/DefaultFormUI/FormControl/FormControl';
import { BaseInput } from 'components/UI/FormWithLabelsUI/FormInput/FormInput';
import FormInputProps from 'models/ui/form/formInputProps';
import React from 'react';
import inputStyles from './FormInputGroup.module.css';

const FormInputGroup: React.FC<FormInputProps<string>> = (props) => {
  const { getHint, label, hidden, ...rest } = props;
  const hint = props.getHint && props.getHint(props.value?.value);
  return (
    <FormControl hidden={!!hidden}>
      <ControlLabel>{label}</ControlLabel>
      <BaseInput {...rest} styles={inputStyles} />
      {hint && <ControlHint children={hint} />}
    </FormControl>
  );
};

export default FormInputGroup;
