import React from "react";
import css from './Filter.module.scss';
import {WithChildren} from "../../../models/technical/withProps";
import classNames from "classnames";

interface FilterProps extends WithChildren {
    title?: string,
    onClose?: () => void,
    openedOnMob: boolean,
    allCleaning?: () => void,
    activeFiltersCount?: number
}

const Filter:React.FC<FilterProps> = ({
    title = 'Фильтр',
    onClose,
    openedOnMob,
    children,
    className,
    allCleaning,
    activeFiltersCount
                                      }) => {
    const cssClass = classNames(
        css.filter,
        openedOnMob && css.openedOnMob,
        className
    )

    return (
        <div className={cssClass}>
            <div className={css.filter__close} onClick={onClose}>X</div>
            <div className={css.filter__titleRow}>
                <div className={css.filter__title}>{title}</div>
                {!!activeFiltersCount && <div className={css.filter__clear} onClick={allCleaning}>Очистить все</div>}
            </div>
            <div className={css.filter__container}>
                {React.Children.map(children, child => child)}
            </div>
        </div>
    );
};

export default Filter;