import React from 'react';
import Button from "../../../../UI/Button/Button";
import css from "./ConfirmExitEvent.module.css";

interface ConfirmExitEventProps {
  confirmEvent: () => void,
  cancelEvent: () => void,
  type?: string
}

const getText = (prop?: string) => {
  switch (prop) {
    case 'removeFriend':
      return 'Вы собираетесь удалить “друга“ из события.';
    case 'cancelEvent':
      return 'Вы собираетесь отменить событие.';
    default:
      return 'Вы собираетесь покинуть событие.';
  }
};

const ConfirmExitEvent: React.FC<ConfirmExitEventProps> = ({ confirmEvent, cancelEvent, type }) => {
  return (
    <div>{getText(type)} Пожалуйста подтвердите действие. Обратите внимание на правила сервиса по возврату денежных средств:
      <ul>
        <li>Денежные средства вернутся, если до начала события более 4х часов</li>
        {type !== 'cancelEvent' && (<li>Денежные средства вернутся, если вы выходите в течение часа после присоединения, но до начала события есть запас в 1 час 15 минут</li>)}
        {type !== 'cancelEvent' && (<li>Во всех иных случаях мы не сможем сделать возврат суммы.</li>)}
      </ul>
      Для уточнения вопросов по правилам сервиса вы всегда можете обратиться в поддержку или написать в нашу группу в VK
      <div className={css.buttons}>
        <Button buttonText={'Да'} onClick={confirmEvent}/>
        <Button buttonText={'Нет'} onClick={cancelEvent}/>
      </div>
    </div>
  );
};

export default ConfirmExitEvent;