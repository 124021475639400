import { FormField } from '../../../../Hoc/Form/Field';
import { FormInput } from '../../../../UI/FormWithLabelsUI/FormInput/FormInput';
import Button from '../../../../UI/Button/Button';
import Form from '../../../../Hoc/Form/Form';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import OrangeLink from '../../../../UI/CSS/OrangeLink/OrangeLink';
import ChangePassword from '../../../../../models/requests/changePassword';
import ProfileStore from '../../../../../stores/ProfileStore';
import ModalStore from "../../../../../stores/ModalStore";
import RestorePassSuccess from "../../../shared/LoginForm/RestorePassModals/RestorePassSuccess";

export default function ChangePasswordForm() {
  const passwordForm = useForm();
  const [showPasswordForm, setShowPasswordForm] = useState(false);
  return showPasswordForm ? (
    <Form
      formReturn={passwordForm}
      onSubmit={async (data: ChangePassword) => {
        await ProfileStore.changePassword(data);
        setShowPasswordForm(false);
        ModalStore.showModal(<RestorePassSuccess/>);
        passwordForm.reset();
      }}
      style={{ minWidth: '280px' }}
    >
      <FormField
        Component={FormInput}
        label="Старый пароль"
        name="oldPassword"
        type="password"
        rules={{ required: 'Выберите значение' }}
      />
      <FormField
        Component={FormInput}
        label="Новый пароль"
        name="password"
        type="password"
        rules={{
          required: 'Выберите значение',
          minLength: {
            value: 8,
            message: 'Пароль должен быть не менее 8 символов',
          },
          validate: (value) => {
            const isAllowed =
              /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])[a-zA-Z0-9\w\W]{8,32}$/.test(
                  value
              );
            if (!isAllowed) {
              return 'Пароль не соответствует требованиям';
            }
            if (value === passwordForm.watch('oldPassword')) {
              return 'Пароль совпадает со старым';
            }
            if (value !== passwordForm.watch('repeatPassword')) {
              return 'Пароли не совпадают';
            }
            return undefined;
          },
        }}
      />
      <FormField
        Component={FormInput}
        label="Повтор пароля"
        name="repeatPassword"
        type="password"
        rules={{
          required: 'Выберите значение',
          minLength: {
            value: 8,
            message: 'Пароль должен быть не менее 8 символов',
          },
          validate: async (value) => {
            if (value !== passwordForm.watch('password')) {
              return 'Пароли не совпадают';
            }
            await passwordForm.trigger('password');
            return undefined;
          },
        }}
      />
      <div style={{ color: '#6b7683' }}>
        Требования к паролю:
        <ul>
          <li>Минимум одна латинская буква (a-z)</li>
          <li>Минимум одна латинская заглавная буква (A-Z)</li>
          <li>Минимум одна цифра (0-9)</li>
          <li>Быть не менее 8 символов</li>
          <li>Новый пароль не совпадает со старым</li>
        </ul>
      </div>
      <Button
        type="submit"
        buttonText="Изменить пароль"
        disabled={passwordForm.formState.isSubmitting}
      />
    </Form>
  ) : (
    <>
      <OrangeLink onClick={() => setShowPasswordForm(true)}>
        Изменить пароль
      </OrangeLink>
    </>
  );
}
