import React, { useEffect } from 'react';
import { Widget } from '../../../UI/Widget/Widget';
import { observer } from 'mobx-react';
import bellIcon from 'imgs/bell.svg';
import browserHistory from '../../../../browserHistory';
import store from '../../../../stores/NotificationWidgetsStore';
import {Tabs} from "../../../Pages/Profile/ProfilePage/model";

const PersonalNotificationsWidget: React.FC = () => {
  const label = store.counters?.personalUnreadCount;

  useEffect(() => {
    store.loadCountersOnce();
  }, []);

  const onClick = () => {
    browserHistory.push(`/cabinet?tabId=${Tabs.notifications}`);
  };

  return <Widget iconSrc={bellIcon} label={label!} onClick={onClick} />;
};

export default observer(PersonalNotificationsWidget);
