import css from './MoreMembers.module.scss'
import {ClassName} from "../../../../models/ui/ClassName";
import classNames from "classnames";
import cssTeamMember from '../TeamMember.module.scss';

interface MoreMembersProps extends ClassName {
    playersCount: number;
}

const MoreMembers:React.FC<MoreMembersProps> = ({
    playersCount,
    className
                                              }) => {
    return (
        <div className={classNames(
            cssTeamMember.item,
            css.item,
            className
        )}>
            <span className={css.playersCount}>+{playersCount}</span>
        </div>
    );
};

export default MoreMembers;