export enum Sections {
    description = 'description',
    conditions = 'conditions',
    schedules = 'schedules',
    coaches = 'coaches',
    price = 'price',
    services = 'services',
    location = 'location'
}

export type ListSections = {
    [key in Sections]: string
}