import { makeAutoObservable } from 'mobx';
import api from '../api';
import { RequestState } from '../models/responses/common';
import HomeResponse from '../models/responses/home';

class HomeStore {
  homeData?: HomeResponse;
  requestState: RequestState = 'pending';

  constructor() {
    makeAutoObservable(this);
  }

  getHomeData() {
    api.home.getHome().then(this.getHomeDataDone, this.getHomeDataError);
  }

  getHomeDataDone = (data: HomeResponse) => {
    this.homeData = data;
    this.requestState = 'done';
  };

  getHomeDataError = () => (this.requestState = 'error');
}

export default new HomeStore();
