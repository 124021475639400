import backendApi from 'api/config';
import Order from 'models/entities/order';
import User from 'models/entities/user';
import PaymentResponse from '../models/responses/payment';

class OrdersAPI {
  prefix = 'orders/';

  async getOrder(orderId: Order['id']): Promise<PaymentResponse> {
    const r = await backendApi.get(`${this.prefix}${orderId}/`);
    return r.data;
  }

  async payWithDeposit(orderId: Order['id'], email?: User['email']) {
    const r = await backendApi.post(this.prefix + orderId + '/pay_deposit', {
      email
    });
    return r.data;
  }

  async payWithCard(
    orderId: Order['id'],
    email?: User['email']
  ): Promise<PaymentResponse> {
    const r = await backendApi.post(this.prefix + orderId + '/pay_card_v2', {
      email,
    });
    return r.data;
  }
}

const ordersApi = new OrdersAPI();
export default ordersApi;
