import User from '../models/entities/user';
import backendApi from './config';
import ProfileInfoResponse from '../models/responses/profileInfo';
import EventInfo from '../models/entities/eventInfo';
import Pagination from '../models/entities/pagination';
import PaymentResponse from '../models/responses/payment';
import { EditProfileRequestData } from '../models/requests/editProfile';
import ChangePassword from '../models/requests/changePassword';
import UserResponse from '../models/responses/user';
import QuizAllInfo from "../models/responses/quizAllInfo";
import City from "../models/entities/city";
import QuizAnswer from "../models/entities/quizAnswer";
import {Recommendations, RecommendationsTop} from "../models/responses/recommendations";

class ProfileAPI {
  prefix = '/users/';

  async getProfile(userId: User['id']): Promise<ProfileInfoResponse> {
    const r = await backendApi.get(`${this.prefix}${userId}/`);
    return r.data;
  }

  async getFutureEvents(userId: User['id']): Promise<Pagination<EventInfo>> {
    const r = await backendApi.get(`${this.prefix}${userId}/future_events/`);
    return r.data;
  }

  async getFutureTrainings(userId: User['id']): Promise<Pagination<EventInfo>> {
    const r = await backendApi.get(`${this.prefix}${userId}/future_trainings/`);
    return r.data;
  }

  getPastEvents = async (
    userId: User['id']
  ): Promise<EventInfo[]> => {
    const r = await backendApi.get(`${this.prefix}${userId}/past_events/`);
    return r.data.results;
  };

  getPastTrainings = async (
    userId: User['id']
  ): Promise<EventInfo[]> => {
    const r = await backendApi.get(`${this.prefix}${userId}/past_trainings/`);
    return r.data.results;
  };

  async uploadAvatar(file: Blob, ext: string) {
    var formData = new FormData();
    formData.append('avatar', file, 'avatar.png');
    const r = await backendApi.post(this.prefix + `upload_avatar`, formData, {
      headers: { 'Content-Type': 'multipart/form-data', custom: true },
    });
    console.log(r.data);
  }

  async removeAvatar() {
    const r = await backendApi.post(this.prefix + `remove_avatar`);
    return r.status === 200;
  }

  async getDepositPaymentData(amount: number): Promise<PaymentResponse> {
    const r = await backendApi.post(this.prefix + `deposit`, { amount });
    return r.data;
  }

  async editProfile(data: EditProfileRequestData): Promise<boolean> {
    const r = await backendApi.put(this.prefix + `me/`, data);
    return r.status === 200;
  }

  async changePassword(data: ChangePassword): Promise<boolean> {
    const r = await backendApi.post(this.prefix + `change_password`, data);
    return r.status === 200;
  }

  async updateUserFlags(data: Partial<UserResponse['config']>): Promise<void> {
    await backendApi.put(this.prefix + 'config/', data);
  }

  async getQuiz() {
    const r = await backendApi.get(`${this.prefix}quiz/`);
    return r.data;
  }

  async getQuizForCity(cityId: City['id']): Promise<QuizAllInfo> {
    const r = await backendApi.get(this.prefix + `quiz/?city_id=${cityId}`);
    return r.data;
  }

  async editQuiz(quizId: number, data: { answers: QuizAnswer[] }): Promise<boolean> {
    const r = await backendApi.put(this.prefix + `quiz/${quizId}/`, data);
    return r.status === 200;
  }

  async removeProfile(): Promise<boolean> {
    const r = await backendApi.delete(this.prefix + `archive`);
    return r.status === 200;
  }

  getRecommendations = async (): Promise<EventInfo[]> => {
    const r = await backendApi.get(`${this.prefix}me/recommendations/`);
    return r.data.results;
  }

  getRecommendationsTop = async (): Promise<EventInfo[]> => {
    const r = await backendApi.get(`${this.prefix}me/recommendations/top/`);
    return r.data.events;
  }

}

const profileAPI = new ProfileAPI();
export default profileAPI;
