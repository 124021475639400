import React from 'react';
import css from './css.module.css';
import {WithChildren} from "../../../../models/technical/withProps";

interface FormControlProps extends WithChildren {
    hidden: boolean
}

const FormControl: React.FC<FormControlProps> = ({ children, hidden }) => (
  <div className={`${css.formControl} ${hidden && css.hidden}`}>{children}</div>
);

export default FormControl;
