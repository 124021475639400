import moment from 'moment';
import UserStore from "../stores/UserStore";

export function getEventDate(date: string) {
  const m = moment(date);
  const isToday = m.isSame(UserStore.getCurrentTime(), 'day');
  const result = m.format('HH:mm, [escaped], dd');
  return result.replace('escaped', isToday ? 'Сегодня' : m.format('DD MMM'));
}

export function getGameTimes(start: moment.Moment, end: moment.Moment, periodsCountOffset: number = 0) {
  let startTimesRange = [];
  let nextPeriod = 0;
  let offsetInMinutes = moment.duration(30 * periodsCountOffset, 'minutes');
  while (true) {
    const time = start
      .clone()
      .add(moment.duration(30 * nextPeriod, 'minutes'));
    const timeWithOffset = time.clone().add(offsetInMinutes);
    if (timeWithOffset <= end) {
      startTimesRange.push({
        id: nextPeriod,
        name: time.format('HH:mm'),
        value: time,
      });
      nextPeriod += 1;
    } else {
      break;
    }
  }
  return startTimesRange;
}

