import {WithChildren} from "../../../models/technical/withProps";
import DefaultLayout from "../../Layouts/Default";
import React from "react";
import {Layout} from "../../Layouts/model";
import css from './Page.module.scss';
import classNames from "classnames";

interface PageProps extends WithChildren {
    layout?: React.ComponentType<Layout>
}

const Page:React.FC<PageProps> = ({
    children,
    layout,
    className
                                  }) => {

    const Layout = layout || DefaultLayout;

    return (
        <Layout>
            <div className={classNames(css.page, className)}>{children}</div>
        </Layout>
    );
};

export default Page;