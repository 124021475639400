import { observer } from 'mobx-react';
import React from 'react';
import M from 'react-modal';
import ModalStore from '../../../stores/ModalStore';
import './Modal.css';

const Modal: React.FC = observer((props) => {

  const {
    show,
    body,
    className
  } = ModalStore.modal;

  return (
    <M
      isOpen={show}
      ariaHideApp={false}
      overlayClassName="overlay"
      className={`modal ${className ? className : ''}`}
      bodyOpenClassName="modalIsOpen"
    >
      <button
        type="button"
        className="modal__close"
        onClick={() => {
          if (ModalStore.closeCallback) ModalStore.closeCallback();
          ModalStore.hideModal();
        }}
      />
      {body}
    </M>
  );
});

export default Modal;
