import LKLayout from 'components/Pages/Profile/LKLayout/LKLayout';
import lkStyle from '../LKLayout/LKLayout.module.css';
import ProfileStore from '../../../../stores/ProfileStore';
import { useLocation, useParams } from 'react-router';
import { useEffect, useRef } from 'react';
import UserStore from '../../../../stores/UserStore';
import { observer } from 'mobx-react';
import OwnTabs from './OwnTabs';
import TheirTabs from './TheirTabs';
import appStore from '../../../../stores/AppStore';

const ProfilePage = () => {
  const { userId } = useParams<{ userId: string }>();
  const location = useLocation();
  const myRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    (async () => {
      const profileId = userId ? Number(userId) : UserStore.userData?.user?.id;
      await ProfileStore.getProfile(profileId);
      if (appStore.userAgent.device.type === 'mobile')
        myRef.current?.scrollIntoView({ behavior: 'smooth' });
      return () => ProfileStore.resetStore();
    })();
  }, [userId]);

  return (
    <LKLayout key={location.key}>
      <div className={lkStyle.title}>
        {ProfileStore.isOwnProfile ? 'Личный кабинет' : 'Кабинет'}
      </div>
      <div className={lkStyle.content} ref={myRef}>
        {ProfileStore.profile && ProfileStore.isOwnProfile ? (
          <OwnTabs />
        ) : (
          <TheirTabs />
        )}
      </div>
    </LKLayout>
  );
};

export default observer(ProfilePage);
