import React from "react";
import commonCss from "../../../css/Common.module.css";
import css from './ErrorPage.module.scss';
import classNames from "classnames";
import Button from "../../UI/Button/Button";
import {useHistory} from "react-router-dom";
import Page from "../../Hoc/Page/Page";

const ErrorPage:React.FC = () => {

    const history = useHistory();

    return (
        <Page>
            <section className={css.section}>
                <div className={classNames(
                    commonCss.wrap,
                    css.container
                )}>
                    <h1 className={css.title}>Ошибка 404</h1>
                    <h2 className={css.description}>Страница не найдена</h2>
                    <div className={css.buttons}>
                        <Button onClick={() => {
                            history.push('/');
                        }} buttonText={'На главную'} className={css.button}/>
                        <Button onClick={() => {
                            history.push('/search/');
                        }} buttonText={'Найти игру'} className={css.button}/>
                    </div>
                </div>
            </section>
        </Page>
    );
};

export default ErrorPage;