import { YMaps, Map, ZoomControl, FullscreenControl, Placemark } from '@pbe/react-yandex-maps';
import css from './MapSection.module.scss';
import SearchPageStore from "../../../../stores/SearchPageStore";
import React, {useMemo, useState, useRef, useEffect, useCallback, Dispatch, SetStateAction} from "react";
import UserStore from "../../../../stores/UserStore";
import {observer} from "mobx-react";
import Spinner from "../../../UI/Loader/Loader";
import './Balloon.css';
import {MapEvents, MapEvent, Balloon, EmptyBalloon} from "./model";
import datetime from "../../../../utils/datetime";
import MapSidebar from "./MabSidebar/MapSidebar";
import {createPortal} from "react-dom";
import {MapSidebarProps} from "./MabSidebar/MapSidebar";
import './ymap.css';
import classNames from "classnames";
import {Tabs} from "../model";
import {declOfNum} from "../../../../utils/translations";

const bodyDom = document.querySelector('body');
const htmlDom = document.querySelector('html');
const orange = '#ff931e';

interface MapSectionProps {
    setCurrentTab: Dispatch<SetStateAction<number>>
}

const MapSection:React.FC<MapSectionProps> = ({
    setCurrentTab
                                              }) => {

    const [loadedMap, setLoadedMap] = useState<boolean>(false);

    const [activeArena, setActiveArena] = useState<number | null>(null);

    const [fullscreen, setFullscreen] = useState<boolean>(false);

    const pending = SearchPageStore.requestState === 'pending';

    const events = useMemo(():MapEvents | null => {
        const obj:MapEvents = {};
        if (SearchPageStore.arenaFilter) {
            const filteredArena = SearchPageStore.events?.arenas.find(arena => arena.id === SearchPageStore.arenaFilter);
            if (filteredArena && filteredArena.coordinates) {
                obj[filteredArena.id] = {
                    coordinates: filteredArena.coordinates,
                    address: filteredArena.address,
                    arenaName: filteredArena.name,
                    arenaLogo: filteredArena.logo,
                    arenaId: filteredArena.id,
                    events: SearchPageStore.events?.events.filter(event => event.arena.id === filteredArena.id) || [],
                    createGameDisabled: !SearchPageStore.events?.availablePeriods?.find(ap => ap.arena.id === filteredArena.id)
                }
            }
        }
        else {
            SearchPageStore.events?.arenas?.forEach((item) => {
                if (item.coordinates) {
                    obj[item.id] = {
                        coordinates: item.coordinates,
                        address: item.address,
                        arenaName: item.name,
                        arenaLogo: item.logo,
                        arenaId: item.id,
                        events: [],
                        createGameDisabled: !SearchPageStore.events?.availablePeriods?.find(ap => ap.arena.id === item.id)
                    }
                }
            });
            SearchPageStore.events?.events?.forEach((item) => {
                if (obj[item.arena.id]?.events) obj[item.arena.id].events.push(item);
            });
        }
        return !!Object.values(obj).length ? obj : null;
    }, [SearchPageStore.events]);

    const cityCoordinates = useMemo(() => {
        return UserStore.currentCity?.coordinates;
    }, [UserStore.currentCity]);

    const dateString = useMemo(() => {
        return datetime.isToday(SearchPageStore.dateFilter) ? 'Сегодня' : datetime.prettify(SearchPageStore.dateFilter, 'DD MMMM');
    }, [SearchPageStore.dateFilter]);

    const fullscreenRef = useRef(null);

    const mapRef = useRef(null);

    useEffect(() => {
        return () => {
            if (htmlDom) htmlDom.classList.remove('noScroll');
        }
    }, []);

    const mapSidebarProps = useMemo(():MapSidebarProps | null => {
        return events && activeArena && dateString ? {
            events: events[activeArena].events,
            arenaName: events[activeArena].arenaName,
            address: events[activeArena].address,
            onClose: () => {
                setActiveArena(null);
            },
            date: dateString
        } : null
    }, [events, dateString, activeArena]);

    const setBalloonEventListeners = useCallback(() => {
        setTimeout(() => {
            const balloon = document.querySelector('.balloon');
            const arenaId = balloon?.getAttribute('arenaId');
            const moreButton = balloon?.querySelector('.balloon__more');
            const createButton = balloon?.querySelector('.balloon__createLink');
            if (moreButton) {
                moreButton.addEventListener('click', () => {
                    setActiveArena(Number(arenaId));
                })
            }
            if (createButton) {
                createButton.addEventListener('click', () => {
                    setCurrentTab(Tabs.create);
                    SearchPageStore.setArenaFilter(Number(arenaId));
                })
            }
        }, 100);
    }, []);

    const filters = useMemo(() => {
        return [
            SearchPageStore.arenaFilter,
            SearchPageStore.sportFilter,
            SearchPageStore.playerLevelFilter,
            SearchPageStore.eventTypeFilter
        ]
    }, [
        SearchPageStore.arenaFilter,
        SearchPageStore.sportFilter,
        SearchPageStore.playerLevelFilter,
        SearchPageStore.eventTypeFilter
    ]);

    const filtersNotEmpty = useMemo(() => {
        return !!filters.filter(item => item).length;
    }, filters);

    useEffect(() => {
        // @ts-ignore
        if (mapRef.current?.balloon?.close) mapRef.current.balloon.close();
        setActiveArena(null);
    }, [filters, SearchPageStore.dateFilter]);

    return (
        <div className={classNames(
            css.container,
            activeArena && css.activeArena
        )}>
            {!pending && !loadedMap && !cityCoordinates && <div>Данный город сейчас недоступен</div>}
            {(!loadedMap || pending) && cityCoordinates && <Spinner className={css.spinner} />}
            {mapSidebarProps && !fullscreen && <MapSidebar {...mapSidebarProps} />}
            {mapSidebarProps && fullscreen && bodyDom && createPortal(
                <MapSidebar {...mapSidebarProps} positionFixed={true} />,
                bodyDom
            )}
            {cityCoordinates && (
                <YMaps
                    query={{
                        lang: 'ru_RU'
                    }}
                >
                    <Map
                        defaultState={{ center: cityCoordinates, zoom: 10 }}
                        className={css.mapContainer as string}
                        onLoad={() => {
                            setLoadedMap(true);
                        }}
                        // @ts-ignore
                        instanceRef={mapRef}
                    >
                        {events && Object.values(events).map(item =>
                            <Placemark
                                key={`${item.arenaId}-${item.coordinates.join('_')}`}
                                modules={["geoObject.addon.balloon"]}
                                defaultGeometry={item.coordinates}
                                properties={{
                                    balloonContent: getTargetBalloon(item)
                                }}
                                options={{
                                    preset: "islands#orangeDotIcon",
                                    iconColor: (() => {
                                        if (!filtersNotEmpty) return orange;
                                        else return !!item.events.length ? orange : 'gray'
                                    })()
                                }}
                                onClick={() => {
                                    if (activeArena) setActiveArena(null);
                                    setBalloonEventListeners();
                                }}
                            />
                        )}
                        <FullscreenControl
                            options={{
                                position: { top: '15px', left: '15px' }
                            }}
                            // @ts-ignore
                            instanceRef={fullscreenRef}
                            onLoad={(data) => {
                                // @ts-ignore
                                fullscreenRef.current?.events?.add('fullscreenenter', function() {
                                    setFullscreen(true);
                                    if (htmlDom) htmlDom.classList.add('noScroll');
                                });
                                // @ts-ignore
                                fullscreenRef.current?.events?.add('fullscreenexit', function() {
                                    setFullscreen(false);
                                    if (htmlDom) htmlDom.classList.remove('noScroll');
                                });

                                setTimeout(() => {
                                    // @ts-ignore
                                    fullscreenRef.current?._layout?._buttonElement?.classList.add('fullscreenButton');
                                }, 100);

                            }}
                        />
                        <ZoomControl options={{ position: { top: '10px', right: '10px' } }} />
                    </Map>
                </YMaps>
            )}
        </div>
    );
};

function getTargetBalloon(data: MapEvent) {
    if (!data.events.length) return getEmptyBalloon({
        arenaId: data.arenaId,
        arenaName: data.arenaName,
        arenaLogo: data.arenaLogo,
        createGameDisabled: data.createGameDisabled
    });
    else return getBalloon({
        arenaId: data.arenaId,
        arenaName: data.arenaName,
        arenaLogo: data.arenaLogo,
        eventsCount: data.events.length,
        createGameDisabled: data.createGameDisabled
    });
}

function getBalloon(props: Balloon) {
    return `
        <div class="balloon" arenaId="${props.arenaId}">
            <div class="balloon__imgWrapper">
                <img class="balloon__img" src="${props.arenaLogo}"/>
            </div>
            <div class="balloon__textSide">
                <div class="balloon__title">${props.arenaName}</div>
                <div class="balloon__desc">Доступно ${props.eventsCount} ${declOfNum(props.eventsCount, ['событие', 'события', 'событий'])}</div>
                <div class="balloon__bottom">
                    <div class="balloon__createLink ${props.createGameDisabled ? 'disabled' : ''}">Создать игру</div>
                    <div class="balloon__more">Посмотреть все</div>
                </div>
            </div>
        </div>
    `;
}

function getEmptyBalloon(props: EmptyBalloon) {
    return `
        <div class="balloon" arenaId="${props.arenaId}">
            <div class="balloon__imgWrapper">
                <img class="balloon__img" src="${props.arenaLogo}"/>
            </div>
            <div class="balloon__textSide">
                <div class="balloon__title">${props.arenaName}</div>
                <div class="balloon__notExistEvent">Нет активных событий</div>
                <div class="balloon__bottom">
                    <div class="balloon__createLink ${props.createGameDisabled ? 'disabled' : ''}">
                        ${props.createGameDisabled ? 'Невозможно создать игру при выбранных фильтрах' : 'Создать игру'}
                    </div>
                </div>
            </div>
        </div>
    `;
}

export default observer(MapSection);