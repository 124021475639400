import { WithChildren } from 'models/technical/withProps';
import styles from './Tabs.module.css';

function TabList(props: WithChildren) {
  return (
    <div className={styles.wrapper}>
      <div className={`${styles.tabList} ${props.className || ''}`}>{props.children}</div>
    </div>
  );
}

export default TabList;
