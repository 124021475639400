export enum SearchAccess {
  private = 'private',
  searchTeam = 'search_team',
  searchPartners = 'search_partners',
  offlineBooking = 'offline_booking',
  maintenance = 'maintenance',
  searchPlayers = 'search_players',
  training = 'training',
  externalTraining = 'external_training',
  partnerGame = 'partner_game',
  vip = 'vip'
}

export enum EventStatus {
  passed = 'passed',
  canceled = 'canceled',
  planned = 'planned',
}
