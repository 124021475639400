import css from './Chat.module.scss';
import UserStore from '../../../../../stores/UserStore';
import { observer } from 'mobx-react';
import Form from '../../../../Hoc/Form/Form';
import { FormField } from '../../../../Hoc/Form/Field';
import { useForm } from 'react-hook-form';
import EventViewStore from '../../../../../stores/EventViewStore';
import { useCallback, useEffect, useRef } from 'react';
import { doSubmit } from '../../../../Hoc/Form/doSubmit';
import React from 'react';

interface ChatFormProps {
  sendMessageCallback: () => void
}

const ChatForm: React.FC<ChatFormProps> = ({ sendMessageCallback }) => {
  const resetForm = () => {
    form.reset({ message: '' });
  };
  const submit = async (data: { message: string }) => {
    try {
      if (!data.message) return;
      await EventViewStore.sendMessage(data.message);
      sendMessageCallback();
    }
    finally {
      resetForm();
    }
  };
  const form = useForm({ defaultValues: { message: '' } });
  const innerRef = useRef<HTMLDivElement>(null);
  const handleKeydown = useCallback(
    (e: KeyboardEvent) => {
      if (e.code === 'Enter') {
        e.preventDefault();
        form.handleSubmit(doSubmit(submit, form))();
      }
    },
    // eslint-disable-next-line
    [form]
  );
  useEffect(() => {
    const div = innerRef.current;
    div && div.addEventListener('keydown', handleKeydown);
    return () => {
      div && div.removeEventListener('keydown', handleKeydown);
    };
  }, [handleKeydown]);
  return (
    <div className={css.chat__form} ref={innerRef}>
      <Form formReturn={form} onSubmit={submit}>
        <FormField
          Component={'textarea'}
          name="message"
          className={`${css.formControl}  ${
            !UserStore.userData?.user ? css.block_chat_item : ''
          }`}
          rows={1}
          placeholder={`${
            EventViewStore.canUseChat
              ? 'Написать сообщение…'
              : 'Для того чтобы написать, присоединись...'
          }`}
          rules={{
            maxLength: 255,
          }}
          disabled={!EventViewStore.canUseChat}
        />
        <button
          type="submit"
          className={`${!EventViewStore.canUseChat ? css.block_chat_item : ''}`}
        />
      </Form>
    </div>
  );
};

export default observer(ChatForm);
