import { observer } from 'mobx-react';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import City, {Timezone} from '../../../../models/entities/city';
import CityStore from '../../../../stores/CityStore';
import useOnClickOutside from '../../../common/hooks/onClickOutside';
import UserStore from '../../../../stores/UserStore';
import {useLocation} from "react-router";
import appStore from "../../../../stores/AppStore";
import {getTimezoneStr} from "../../../../utils/functions";
import moment from "moment";
import useInterval from "../../../common/hooks/useInterval";

const HeaderCity = observer(() => {

  const fetch = async () => {
    await CityStore.getCityData();
    const cityTarget = cityTargetId && CityStore.getCityById(Number(cityTargetId));
    if (cityTarget && !appStore.citySwitchByGetParamDone && cityTarget.id !== UserStore.currentCity?.id) {
      appStore.setCitySwitchByGetParamDone();
      UserStore.setCurrentCity(cityTarget);
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  const { search } = useLocation();
  const cityTargetId = new URLSearchParams(search).get('city');

  const ref = useRef<HTMLDivElement>(null);
  const [expanded, setExpanded] = useState(false);

  const toggle = () => setExpanded(!expanded);

  const setAndToggle = (x: City) => () => {
    UserStore.setCurrentCity(x);
    toggle();
  };

  const formatCurrentTime = (timezone?: Timezone) => moment(UserStore.getCurrentTime(timezone)).format('LT');

  const [currentTime, setCurrentTime] = useState(formatCurrentTime(UserStore.currentCity?.timezone));
  useInterval(() => {
    setCurrentTime(formatCurrentTime(UserStore.currentCity?.timezone));
  }, 1000);

  useOnClickOutside(ref, () => setExpanded(false));

  console.log(UserStore.getCurrentTime());

  return (
    <div className={`location ${CityStore.citySwitcherDisabled ? 'disabled' : ''}`} ref={ref}>
      <div className="location__default" onClick={toggle}>
        {UserStore.currentCity?.name}
        <i
          className={`location__arrow ${
            expanded ? 'location__arrow_expanded' : ''
          }`}
        />
      </div>
      {UserStore.currentCity && <div className={'locationTimezone'}>{currentTime} (GMT: {getTimezoneStr(UserStore.currentCity.timezone)})</div>}
      {expanded && (
        <div className="location__list">
          {CityStore.cityData?.cities.map((x) => (
            <div className="location__item" key={x.id} onClick={setAndToggle(x)}>
              {x.name}
              <div className={'locationItemTimezone'}>{formatCurrentTime(x.timezone)} (GMT: {getTimezoneStr(x.timezone)})</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
});

export default HeaderCity;
