import style from './ProfileCard.module.scss';
import vkImg from './img/vk.svg';
import fbImg from './img/fb.png';
import googleImg from 'components/Pages/shared/LoginForm/img/google-icon.svg';
import appleImg from 'components/Pages/shared/LoginForm/img/apple-icon.svg';
import React from 'react';
import { observer } from 'mobx-react';
import { AuthorizationType } from '../../../../../enums/player';
import UserStore from '../../../../../stores/UserStore';

function getSocialIconByType(type: keyof typeof AuthorizationType): string | null {
  switch (type) {
    case 'apple':
      return appleImg;
    case 'fb':
      return fbImg;
    case 'vk':
      return  vkImg;
    case 'google': return googleImg;
    default:
      return null;
  }
}

function ProfileSocial(){
  const imgSrc = getSocialIconByType(UserStore.userData?.user?.type as keyof typeof AuthorizationType);
  if(!imgSrc) return null;
  return (<div className={style.social}>
    <img src={imgSrc} alt={UserStore.userData?.user?.type} />
  </div>)
}

export default observer(ProfileSocial);
