import Button from "../../../UI/Button/Button";
import commonCss from "css/Common.module.css";
import css from './Style.module.scss';
import classNames from "classnames";

const MobAppErrorCallbackPage:React.FC = () => {
    return (
        <div className={classNames(
            commonCss.wrap,
            css.container
        )}>
            <h1 className={css.title}>Ошибка оплаты</h1>
            <a href="sploteam://SearchStack/SuccessPay" target={'_blank'}><Button buttonText={'Вернуться в приложение'}/></a>
        </div>
    );
};

export default MobAppErrorCallbackPage;