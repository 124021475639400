import { ErrorMessage } from '@hookform/error-message';
import { doSubmit } from 'components/Hoc/Form/doSubmit';
import { WithChildren } from 'models/technical/withProps';
import React from 'react';
import { UseFormReturn } from 'react-hook-form/dist/types';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import css from './form.module.css';

interface FormProps<FV> extends WithChildren {
  children: React.ReactElement | React.ReactElement[] | boolean
  onSubmit: any;
  className?: string;
  formReturn: UseFormReturn<FV>;
  disabled?: boolean
}

export default function Form<FV extends FieldValues>({
  children,
  onSubmit,
  className,
  formReturn,
  style,
  disabled
}: FormProps<FV>) {
  const methods = formReturn;
  const { handleSubmit, formState } = methods;
  const { isSubmitting } = formState;

  methods.watch(() => {
    // @ts-ignore
    if (methods.formState.errors['formError']) {
      methods.clearErrors('formError' as any);
    }
  });

  return (
    <form
      onSubmit={handleSubmit(doSubmit(onSubmit, formReturn))}
      className={`${className || ''} ${isSubmitting || disabled ? css.buttonDisabled : ''}`}
      style={style}
    >
      <input {...methods.register('formError' as any)} type="hidden" />
      {React.Children.map(children, (child) => {
        if (
          typeof child === 'boolean' ||
          child === null ||
          child.type === 'boolean'
        )
          return null;
        return child.props?.name
          ? React.createElement(child.type, {
              ...{
                ...child.props,
                control: methods.control,
                register: methods.register,
                setValue: methods.setValue,
                key: child.props.name,
              },
            })
          : child;
      })}
      <ErrorMessage
        name={'formError' as any}
        errors={methods.formState.errors}
        className={css.error}
        as="p"
      />
    </form>
  );
}
