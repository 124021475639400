import commonCss from "../../../../css/Common.module.css";
import Spinner from "../Loader";
import React from "react";
import {ClassName} from "../../../../models/ui/ClassName";
import classNames from "classnames";

interface SpinnerWrapperProps extends ClassName {}

const SpinnerWrapper:React.FC<SpinnerWrapperProps> = ({
    className
                                                      }) => {
    return (
        <div className={classNames(
            commonCss.spinnerWrapper,
            className
        )}><Spinner/></div>
    );
};

export default SpinnerWrapper;