import { observer } from 'mobx-react';
import { onSelectChange } from 'models/entities/SelectOption';
import React from 'react';
import SearchPageStore from 'stores/SearchPageStore';
import {GrayRoundedSelect} from "../../../../UI/Select/GrayRoundedSelect";
import {WithChildren} from "../../../../../models/technical/withProps";

interface EventTypeFilterProps extends WithChildren {}

const EventTypeFilter:React.FC<EventTypeFilterProps> = observer(({
    className
                                                                 }) => {

    return <GrayRoundedSelect
        value={SearchPageStore.getCurrentEventTypeOrNone()}
        options={SearchPageStore.getEventTypesOptions()}
        onChange={SearchPageStore.setEventTypeFilter as onSelectChange}
        className={className}
        withCrossIcon={true}
        notFlex={true}
    />
});

export default EventTypeFilter;
