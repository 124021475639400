import React from 'react';
import styles from './NotificationList.module.css';
import { Notification } from '../Notification/Notification';
import store from '../../../../../../stores/NotificationsSectionStore';
import { observer } from 'mobx-react';
import { isPersonalNotification } from '../../../../../../models/guards/isPersonalNotification';
import { NotificationKind, NotificationReason } from '../../../../../../enums/notification';
import NotificationWidgetsStore from '../../../../../../stores/NotificationWidgetsStore';
import {Tabs as OwnTabs} from "../../model";

const NotificationList: React.FC = () => {
  const notifications = store.pageData!.results;

  const onSelect = (id: number, isSelected: boolean) => {
    if (isSelected) {
      store.addSelected(id);
    } else {
      store.removeSelected(id);
    }
  };

  const onRead = (id: number) => {
    (async () => {
      await store.readNotifications([id]);
      await store.loadCounters();
      await NotificationWidgetsStore.loadCounters();
    })();
  };

  const list = () => {
    return notifications.map((data) => {

      const selected =
        store.allNotificationsSelected || store.selectedSet.has(data.id);

      const unread = !data.readAt;

      const important = isPersonalNotification(data)
        ? data.kind === NotificationKind.IMPORTANT
        : false;

      const date = isPersonalNotification(data)
        ? data.createdAt
        : data.updatedAt;

      const isRecommendation = "reason" in data && data.reason === NotificationReason.RECOMMENDATIONS;

      const redirectPath = (() => {
        if (isRecommendation) {
          return `/cabinet?tabId=${OwnTabs.recommendations}`;
        }
        else return data.eventUrl && new URL(data.eventUrl).pathname;
      })();

      const linkText = isRecommendation ? 'Подробнее' : 'Перейти к игре';

      return (
        <Notification
          key={data.id}
          id={data.id}
          title={data.title}
          text={data.text}
          date={date}
          important={important}
          unread={unread}
          selected={selected}
          onSelect={onSelect}
          onRead={onRead}
          redirectPath={redirectPath}
          linkText={linkText}
        />
      );
    });
  };

  return <div className={styles.list}>{list()}</div>;
};

export default observer(NotificationList);
