import Select from 'components/UI/Select/Select';
import { observer } from 'mobx-react';
import { onSelectChange } from 'models/entities/SelectOption';
import React, {useMemo} from 'react';
import SearchPageStore from 'stores/SearchPageStore';
import defaultSelectStyles from 'components/UI/Select/Select.module.css';
import sorterStyles from 'components/Pages/Search/Filters/SortFilter/SorterSelect.module.css';
import {GrayRoundedSelect} from "../../../../UI/Select/GrayRoundedSelect";
import {SelectProps} from "components/UI/Select/Select";

let styles = {};
Object.assign(styles, defaultSelectStyles, sorterStyles);

export const searchSorts = [
  { name: 'По времени', id: 1, value: 'event_start', legacy: 'time' },
  { name: 'По арене', id: 2, value: 'arena_priority', legacy: 'arena' },
];

interface SortFilterProps extends Pick<SelectProps, 'withCrossIcon' | 'disabled'> {
  type?: 'GrayRoundedSelect',
}

const SortFilter:React.FC<SortFilterProps> = observer(({
  type,
  disabled
                                      }) => {

  const ComponentLocal = useMemo(() => {
    if (type && type === 'GrayRoundedSelect') return GrayRoundedSelect;
    else return Select;
  }, []);

  return (
      <ComponentLocal
          value={SearchPageStore.currentSortFilter}
          options={searchSorts}
          onChange={SearchPageStore.setSortFilter as onSelectChange}
          styles={styles}
          withCrossIcon={true}
          disabled={disabled}
      />
  );
});

export default SortFilter;
