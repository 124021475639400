import appStore from "../stores/AppStore";

const addResizeWindowListener = () => {
  if (window.innerWidth <= 900) appStore.setMob(true);
  else appStore.setMob(false);
  window.addEventListener('resize', () => {
    if (window.innerWidth <= 900 && !appStore.mob) {
      appStore.setMob(true);
    } else if (window.innerWidth > 900 && appStore.mob) {
      appStore.setMob(false);
    }
  });
};

export default addResizeWindowListener;