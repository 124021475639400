import { makeAutoObservable } from 'mobx';
import React from 'react';
import UserStore from "./UserStore";

class ModalStore {
  modal: { show: boolean; body: React.ReactElement | null, className?: string } = {
    show: false,
    body: null,
  };
  remindPhonePopupActive: boolean = false;
  closeCallback: (() => void) | null

  constructor() {
    makeAutoObservable(this);
    this.closeCallback = null;
  }

  showModal(body: React.ReactElement, className?: string) {
    this.modal = { show: true, body, className };
  }

  hideModal() {
    if (this.remindPhonePopupActive) {
      UserStore.handleRemindPhonePopupClose();
      this.setRemindPhonePopupActive(false);
    }
    this.modal = { show: false, body: null };
    this.closeCallback = null;
  }

  setRemindPhonePopupActive(value: boolean) {
    this.remindPhonePopupActive = value;
  }

  setCloseCallback(data: () => void) {
    this.closeCallback = data;
  }
}

export default new ModalStore();
