import { TabProps } from 'components/UI/Tabs/models';
import styles from './Tabs.module.css';
import classNames from "classnames";
import browserHistory from "../../../browserHistory";

const Tab = ({ children, tabs, index, onClick, className, routerAnchor }: TabProps) => {
  return (
    <div
      className={classNames(
          styles.tab,
          tabs.currentTab === index && styles.activeTab,
          className
      )}
      onClick={() => {
          tabs.setCurrentTab(index);
          if (onClick) onClick();
          if (routerAnchor) browserHistory.push(`${browserHistory.location.pathname}?${routerAnchor}=${index}`);
      }}
    >
      {children}
    </div>
  );
};

export default Tab;
