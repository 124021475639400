import Button from "../../../../UI/Button/Button";
import css from './ConfirmModal.module.scss';
import UserStore from "../../../../../stores/UserStore";
import ModalStore from "../../../../../stores/ModalStore";

const ConfirmModal:React.FC = () => {

    const confirm = () => {
        ModalStore.hideModal();
        UserStore.removeProfile();
    }

    const cancel = () => {
        ModalStore.hideModal();
    }

    return (
        <div>
            <div>Удаление данных - необратимое действие. Вы уверены?</div>
            <div className={css.buttons}>
                <Button buttonText={'Да'} onClick={confirm}/>
                <Button buttonText={'Нет'} onClick={cancel}/>
            </div>
        </div>
    );
}

export default ConfirmModal;