import CampsSearchPageStore from "../../../../../stores/CampsSearchPageStore";
import {onSelectChange} from "../../../../../models/entities/SelectOption";
import Select from "../../../../UI/Select/Select";
import React from "react";
import {observer} from "mobx-react";

const OrganizersFilter:React.FC = () => {

    const onChange:onSelectChange = (option) => {
        CampsSearchPageStore.setOrganizerFilter(option.id === 0 ? null : option.id);
    }

    return (
        <Select
            value={CampsSearchPageStore.getOrganizerFilter()}
            options={CampsSearchPageStore.getOrganizerOptions()}
            onChange={onChange}
        />
    );
};

export default observer(OrganizersFilter);