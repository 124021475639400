import FormSelectProps from 'models/ui/form/formSelectProps';
import React from 'react';
import Select from 'react-select';
import './ControlledSelect.css';

const styleProxy = new Proxy(
  {},
  {
    get: (target, propKey) => () => {},
  }
);

const ControlledSelect: React.FC<FormSelectProps> = ({
  setValue,
  value,
  className,
  defaultValue,
  options,
  ...rest
}) => {
  return (
    <Select
      defaultValue={defaultValue}
      className={className}
      value={value}
      options={options}
      styles={styleProxy}
      placeholder="Выберите значение"
      classNamePrefix="react-select"
      isSearchable={false}
      {...rest}
    />
  );
};

export default ControlledSelect;
