import React, { useState } from 'react';
import styles from './Notification.module.scss';
import { FlatOrangeCheckbox } from '../../../../../UI/Checkbox/FlatOrangeCheckbox';
import CheckboxData from '../../../../../../models/ui/form/checkboxData';
import moment from 'moment';
import browserHistory from '../../../../../../browserHistory';
import OrangeLink from '../../../../../UI/CSS/OrangeLink/OrangeLink';

export interface NotificationProps {
  id: number;
  title: string;
  text: string;
  date: string;
  important: boolean;
  unread: boolean;
  selected: boolean;
  onSelect: (id: number, isSelected: boolean) => void;
  onRead: (id: number) => void;
  redirectPath?: string;
  linkText: string
}

interface State {
  expanded: boolean;
  hasBeenRead: boolean;
}

export const Notification: React.FC<NotificationProps> = (props) => {
  const [state, setState] = useState<State>({
    expanded: false,
    hasBeenRead: false,
  });
  const title = props.important ? `🔥 ${props.title}` : props.title;
  const readAtDate = moment(props.date);
  const displayedDate = readAtDate.format('D MMM');
  const displayedTime = readAtDate.format('HH:mm');

  const unreadIcon = () => {
    if (!props.unread) {
      return null;
    }

    return (
      <i
        className={`${styles.unreadIcon} ${
          state.hasBeenRead ? styles.slideOut : ''
        }`}
      ></i>
    );
  };

  const onSelect = (data: CheckboxData, isSelected: boolean) => {
    props.onSelect(props.id, isSelected);
  };

  const markAsRead = async () => {
    if (props.unread && !state.hasBeenRead) {
      props.onRead(props.id);
    }
  };

  const markAsReadAndToggle = () => {
    markAsRead();
    setState((state) => ({
      expanded: !state.expanded,
      hasBeenRead: true,
    }));
  };

  const redirectToUrl = async () => {
    await markAsRead();
    if (props.redirectPath) {
      browserHistory.push(props.redirectPath);
      return;
    }
  };

  return (
    <div className={styles.notification} onClick={markAsReadAndToggle}>
      <div className={styles.column1}>
        {unreadIcon()}
        <FlatOrangeCheckbox
          label=""
          value=""
          checked={props.selected}
          onChange={onSelect}
          stopClickPropagation={true}
        />
      </div>
      <div className={styles.column2}>
        <div className={styles.headline}>
          <div className={styles.title}>{title}</div>
          <div className={styles.date}>
            <span className={styles.dateString}>{displayedDate}</span>
            <span className={styles.timeString}>, {displayedTime}</span>
          </div>
        </div>
        <div
          className={`${styles.text} ${state.expanded ? styles.expanded : ''}`}
        >
          {props.text}
          {props.redirectPath && (
            <OrangeLink onClick={redirectToUrl}>{props.linkText}</OrangeLink>
          )}
        </div>
      </div>
    </div>
  );
};
