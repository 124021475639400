import Footer from 'components/Hoc/Footer/Footer';
import Header from 'components/Hoc/Header/Header';
import BackLink from 'components/Pages/Profile/LKLayout/BackLink/BackLink';
import { WithChildren } from 'models/technical/withProps';
import styles from './LKLayout.module.css';
import commonCss from 'css/Common.module.css';

const LKLayout = (props: WithChildren) => {
  return (
    <>
      <Header />
      <div className={styles.layoutWrap}>
        <div className={commonCss.wrap}>
          <BackLink />
          {props.children}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default LKLayout;
