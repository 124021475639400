import css from './Quiz.module.scss';
import Select from "../../../../UI/Select/Select";
import React, { useState, useEffect } from "react";
import ProfileStore from "../../../../../stores/ProfileStore";
import QuizQuestion from "./QuizQuestion";
import Button from "../../../../UI/Button/Button";
import CheckboxData from "../../../../../models/ui/form/checkboxData";
import { StateKey, StateValue, OnChangeProps } from "./QuizHelper";
import { getNormalizedOptions } from "./QuizHelper";
import QuizQuestionType from "models/entities/quizQuestion";
import QuizAnswer from "../../../../../models/entities/quizAnswer";
import profileAPI from "../../../../../api/profile";
import { SelectOption } from "../../../../../models/entities/SelectOption";
import { quizErrors } from "../../../../Hoc/Form/errorCodes";
import ErrorResponse from "../../../../../models/responses/error";
import { AxiosError } from 'axios';
import ModalStore from "../../../../../stores/ModalStore";
import ModalSuccess from "./ModalSuccess";
import ReactDOM from 'react-dom';
import Spinner from "../../../../UI/Loader/Loader";
import {observer} from "mobx-react";
import cssTabs from '../Tabs.module.scss';

const examplesOptions = { /* пустые значения для вопросов */
  'checkbox': [],
  'checkbox/inbox': [],
  'radio': {},
};

interface StateType {
  [key: StateKey]: any
}

type ErrorContext = Pick<QuizAnswer, 'questionId' | 'parentQuestionId' | 'parentQuestionOptionId'>;

type QuestionsErrorsType = string[];

const fillAnswer = ( /* заполняем стейт ответами */
                     type: QuizQuestionType['kind'],
                     defaultState: StateType,
                     normalizedOptionsTemp: CheckboxData[],
                     answer: QuizAnswer
) => {
  if (type === 'checkbox' || type === 'checkbox/inbox') {
    return [...defaultState[answer.questionId], ...normalizedOptionsTemp];
  }
  else if (type === 'radio') {
    return normalizedOptionsTemp[0];
  }
};

const getStateValueFormat = (kind: QuizQuestionType['kind']) => JSON.parse(JSON.stringify(examplesOptions[kind])); /* получаем пустое значение нужного формата в зависимости от типа вопроса */

const initState = (
    questions: QuizQuestionType[] | null | undefined,
    answers: QuizAnswer[] | null | undefined,
) => { /* собираем основной стейт из вопросов и потом заполняем ответами если они есть */
  let defaultState:StateType = {};
  questions?.forEach((question) => {
    if (question.parentId && question.options) {
      const parentQuestion = questions.find((item) => item.id === question.parentId);
      parentQuestion?.options.forEach((option) => {
        defaultState[`${question.id}_${question.parentId}_${option.id}`] = getStateValueFormat(question.kind);
      });
    }
    else defaultState[question.id] = getStateValueFormat(question.kind);
  });
  answers?.forEach((answer) => {
    const question = questions?.find((item) => item.id === answer.questionId);
    if (question) {
      const option = question.options.find((option) => option.id === answer.optionId);
      const normalizedOptionsTemp = option && getNormalizedOptions([option]);
      if (!normalizedOptionsTemp) return;
      if (answer.parentQuestionId && answer.parentQuestionOptionId) {
        defaultState[`${answer.questionId}_${answer.parentQuestionId}_${answer.parentQuestionOptionId}`] = fillAnswer(question.kind, defaultState, normalizedOptionsTemp, answer);
      }
      else {
        defaultState[answer.questionId] = fillAnswer(question.kind, defaultState, normalizedOptionsTemp, answer);
      }
    }
  });
  return defaultState;
};

const checkEmpty = (value: StateValue) => { /* проверяем значения основного стейта на пустоту */
  if (Array.isArray(value) || typeof value === 'string') return !!value.length;
  else if (typeof value === 'object') return !!Object.keys(value).length;
};

const Quiz:React.FC = () => {

  const [buttonDisabled, setButtonDisabled] = useState<boolean>(!!ProfileStore.quiz && ProfileStore.quiz.quiz.answers.length > 0);
  const [questions, setQuestions] = useState<QuizQuestionType[] | null>(ProfileStore.quiz?.quiz.questions || null);
  const [values, setValues] = useState<StateType>(initState(ProfileStore.quiz?.quiz.questions, ProfileStore.quiz?.quiz.answers) || {});
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [questionsErrors, setQuestionsErrors] = useState<QuestionsErrorsType>([]);

  const fetch = async () => {
    await ProfileStore.getQuiz();
    getDataAndUpdateState();
  };

  const getDataAndUpdateState = () => {
    const questions = ProfileStore.quiz!.quiz.questions;
    const answers = ProfileStore.quiz!.quiz.answers;
    ReactDOM.unstable_batchedUpdates(() => {
      setQuestions(questions);
      setValues(initState(questions, answers));
      setButtonDisabled(answers.length > 0);
    });
  };

  useEffect(() => {
    if (!ProfileStore.quiz) fetch();
  }, []);

  const handleFieldChange:OnChangeProps = (value, restObj) => { /* обновляем основной стейт при изменении ответа на какой-нибудь вопрос */
    setValues({ ...values, [restObj.id]: value });
    if (buttonDisabled) setButtonDisabled(false);
  };

  const hideSubquestion = (parentId: StateKey, targetValue: any) => { /* скрываем под вопрос если его родитель не активен */
    if (Array.isArray(values[parentId])) return !!values[parentId].find((option: CheckboxData) => option.value == targetValue);
    else if (typeof values[parentId] === 'string' && values[parentId].length > 0) return true;
    else if (typeof values[parentId] === 'object' && Object.keys(values[parentId]).length > 0) return values[parentId].value == targetValue;
  };

  const reqNormalizer = () => { /* нормализуем реквест для отправки финальных ответов */
    let finalArr = [];
    for (const [key, value] of Object.entries(values)) { /* проходимся по каждому свойству основного стейта */
      if (checkEmpty(value)) {
        finalArr.push(...collectAnswer(key, value)); /* собираем массив ответов в нужном формате для каждого не пустого значения основного стейта и пушем в финальный массив ответов */
      }
    }
    return finalArr;
  };

  const collectAnswer = (key: string, value: any) => { /* собираем массив ответов в нужном формате для каждого не пустого значения основного стейта */
    const questionKeyArr = key.split('_').map((i) => Number(i));
    const question = questions?.find((question) => question.id === questionKeyArr[0]);
    if (!question) return;
    if (questionKeyArr.length <= 1) {
      if (question.kind === 'checkbox' || question.kind === 'checkbox/inbox') {
        return value.map((answer: any) => ({
          questionId: question.id,
          parentQuestionId: null,
          parentQuestionOptionId: null,
          optionId: answer.value,
          value: null
        }));
      }
      else if (question.kind === 'radio') {
        return [{
          questionId: question.id,
          parentQuestionId: null,
          parentQuestionOptionId: null,
          optionId: value.value,
          value: null
        }]
      }
      else return []
    }
    else {
      if (!hideSubquestion(questionKeyArr[1], questionKeyArr[2])) return [];
      if (question.kind === 'radio') {
        return [{
          questionId: question.id,
          parentQuestionId: questionKeyArr[1],
          parentQuestionOptionId: questionKeyArr[2],
          optionId: value.value,
          value: null
        }]
      }
    }
  };

  const saveQuiz = async () => {
    const req = {
      answers: reqNormalizer()
    };
    try {
      await profileAPI.editQuiz(ProfileStore.quiz!.quiz!.id!, req);
      await ProfileStore.getQuiz();
      ModalStore.showModal(<ModalSuccess/>);
      resetErrors();
      setButtonDisabled(true);
    }
    catch (e) {
      quizErrorHandler(e);
    }
  };

  const quizErrorHandler = (e: any) => {
    console.log(e);
    const error: AxiosError<ErrorResponse> = e.error;
    const errorCode = error.response?.data.code;
    if (errorCode && quizErrors[errorCode]) {
      ReactDOM.unstable_batchedUpdates(() => {
        setErrorMessage(quizErrors[errorCode]); /* показываем сообщение об ошибке */
        if (errorCode === 'quiz_not_completed') { /* добавляем id вопроса в массив с ошибками, чтобы подсветить вопрос на который не был дан ответ */
          setQuestionsErrors(errorsFiller(e.error.response.data.context.answers));
        }
      });
    }
  };

  const errorsFiller = (srcArr: ErrorContext[]) => srcArr.map((item) => {
    let final = String(item.questionId);
    if (item.parentQuestionId) final = final + `_${item.parentQuestionId}`;
    if (item.parentQuestionOptionId) final = final + `_${item.parentQuestionOptionId}`;
    return final;
  });

  const checkQuestionError = (id: string) => questionsErrors.includes(id);
  const checkQuestionErrorWithParent = (questionId: string, finalId: string) => {
    if (checkQuestionError(questionId)) return true;
    else return checkQuestionError(finalId);
  };

  const resetErrors = () => {
    ReactDOM.unstable_batchedUpdates(() => {
      setErrorMessage('');
      setQuestionsErrors([]);
    });
  };

  const citySelected = async (data: SelectOption) => { /* при изменении города обновляем стор, получаем нужные вопроса для нового города и пересобираем основной стейт */
    await ProfileStore.getQuizForCity(data.id);
    getDataAndUpdateState();
  };

  const pending = ProfileStore.requestState === 'pending';

  if (pending) return <Spinner className={cssTabs.spinner}/>
  if (!questions || Object.keys(values).length === 0) return null;

  return (
    <div className={css.quiz}>
      <Select
        value={ProfileStore.quiz!.city}
        options={ProfileStore.quiz!.cities}
        onChange={(data) => { citySelected(data) }}
      />
      {questions?.length > 0 && (
        <div className={css.quiz__questions}>
          {questions?.map((question) => {
            if (question.parentId) {
              const parentQuestion = questions?.find((item) => item.id === question.parentId);
              return parentQuestion?.options.map((option) => {
                const parentQuestionId = parentQuestion.id;
                const finalId = `${question.id}_${parentQuestionId}_${option.id}`;
                return <QuizQuestion
                  key={finalId}
                  {...question}
                  text={`${question.text} (${option.text})`}
                  id={finalId}
                  hide={!hideSubquestion(parentQuestionId, option.id)}
                  onChange={handleFieldChange}
                  selectedOptions={values[finalId]}
                  error={checkQuestionErrorWithParent(String(question.id), finalId)}
                />
              });
            }
            else {
              return <QuizQuestion
                key={question.id}
                {...question}
                id={question.id}
                onChange={handleFieldChange}
                selectedOptions={values[question.id]}
                error={checkQuestionError(String(question.id))}
              />
            }
          })}
        </div>
      )}
      <Button disabled={buttonDisabled} buttonText={'Сохранить'} onClick={() => saveQuiz()} className={css.quiz__button}/>
      {errorMessage && (
        <div className={css.quiz__errorBlock}>{errorMessage}</div>
      )}
    </div>
  );
};

export default observer(Quiz);
