import css from './ViewSwitcher.module.scss';
import classNames from "classnames";
import {WithChildren} from "../../../../models/technical/withProps";
import {Views} from "../model";
import {Dispatch, SetStateAction} from "react";
import {LocationUpdater} from "../../../../utils/locationUpdater";
import {applyQuery} from "../../../../utils/urlConstructors";

interface ViewSwitcherProps extends WithChildren {
    state: {
        activeView: Views,
        setActiveView: Dispatch<SetStateAction<Views>>
    },
    disabledItem?: Views | false
}

const ViewSwitcher:React.FC<ViewSwitcherProps> = ({
    className,
    state,
    disabledItem
                                                  }) => {

    const onClick = (value: Views) => {
        state.setActiveView(value);
        LocationUpdater.replaceLocation(
            applyQuery(
                'view',
                Views[value]
            )
        );
    }

    return (
        <div className={classNames(css.container, className)}>
            <div
                className={classNames(
                    css.item,
                    state.activeView === Views.table && css.active,
                    disabledItem === Views.table && css.disabled
                )}
                onClick={() => {
                    onClick(Views.table);
                }}
            >
                <div className={css.item__imgWrapper}>
                    <svg className={classNames(css.item__img, css.table)} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M9 2.5C9 1.96957 9.21071 1.46086 9.58579 1.08579C9.96086 0.710714 10.4696 0.5 11 0.5H14C14.5304 0.5 15.0391 0.710714 15.4142 1.08579C15.7893 1.46086 16 1.96957 16 2.5V5.5C16 6.03043 15.7893 6.53914 15.4142 6.91421C15.0391 7.28929 14.5304 7.5 14 7.5H11C10.4696 7.5 9.96086 7.28929 9.58579 6.91421C9.21071 6.53914 9 6.03043 9 5.5V2.5ZM14 2.5H11V5.5H14V2.5ZM2 9.5C1.46957 9.5 0.960859 9.71071 0.585786 10.0858C0.210714 10.4609 0 10.9696 0 11.5V14.5C0 15.0304 0.210714 15.5391 0.585786 15.9142C0.960859 16.2893 1.46957 16.5 2 16.5H5C5.53043 16.5 6.03914 16.2893 6.41421 15.9142C6.78929 15.5391 7 15.0304 7 14.5V11.5C7 10.9696 6.78929 10.4609 6.41421 10.0858C6.03914 9.71071 5.53043 9.5 5 9.5H2ZM2 11.5H5V14.5H2V11.5ZM11 9.5C10.4696 9.5 9.96086 9.71071 9.58579 10.0858C9.21071 10.4609 9 10.9696 9 11.5V14.5C9 15.0304 9.21071 15.5391 9.58579 15.9142C9.96086 16.2893 10.4696 16.5 11 16.5H14C14.5304 16.5 15.0391 16.2893 15.4142 15.9142C15.7893 15.5391 16 15.0304 16 14.5V11.5C16 10.9696 15.7893 10.4609 15.4142 10.0858C15.0391 9.71071 14.5304 9.5 14 9.5H11ZM11 11.5H14V14.5H11V11.5ZM2 0.5C1.46957 0.5 0.960859 0.710714 0.585786 1.08579C0.210714 1.46086 0 1.96957 0 2.5V5.5C0 6.03043 0.210714 6.53914 0.585786 6.91421C0.960859 7.28929 1.46957 7.5 2 7.5H5C5.53043 7.5 6.03914 7.28929 6.41421 6.91421C6.78929 6.53914 7 6.03043 7 5.5V2.5C7 1.96957 6.78929 1.46086 6.41421 1.08579C6.03914 0.710714 5.53043 0.5 5 0.5H2ZM2 2.5H5V5.5H2V2.5Z"/>
                    </svg>
                </div>
                <div className={css.item__title}>Таблица</div>
            </div>
            <div
                className={classNames(
                    css.item,
                    state.activeView === Views.map && css.active,
                    disabledItem === Views.map && css.disabled
                )}
                onClick={() => {
                    onClick(Views.map);
                }}
            >
                <div className={css.item__imgWrapper}>
                    <svg className={classNames(css.item__img, css.map)} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M0.5 18.2208L7.60794 20.5901L14.5769 16.6078L20.5 18.977V2.82298L14.4231 0.392212L7.39206 4.40994L0.5 2.11259V18.2208ZM12.5 15.4911L8.5 17.7768V6.08033L12.5 3.79462V15.4911ZM14.5 14.423L18.5 16.023V4.17704L14.5769 2.60781L14.5 2.65176V14.423ZM2.5 16.7793L6.5 18.1126V6.22077L2.5 4.88744V16.7793Z"/>
                    </svg>
                </div>
                <div className={css.item__title}>Карта</div>
            </div>
        </div>
    );
};

export default ViewSwitcher;