import backendApi from 'api/config';
import {CampsEventsReq, CampEventReq} from "../models/requests/camps";
import {CampsFiltersResp, CampsEventsResp, CampEventResp} from "../models/responses/camps";
import {SendOrderReq} from "../models/requests/camps";

class CampEventsAPI {
  prefix = '/camps/';

  async getFilters(): Promise<CampsFiltersResp> {
    const r = await backendApi.get(`${this.prefix}filters/`);
    return r.data;
  }

  async getEvents(data: CampsEventsReq): Promise<CampsEventsResp> {
    const r = await backendApi.get(`${this.prefix}`, {
      params: data
    });
    return r.data;
  }

  async getEvent(data: CampEventReq): Promise<CampEventResp> {
    const r = await backendApi.get(`${this.prefix}${data.slug}/`);
    return r.data;
  }

  async sendOrder(data: SendOrderReq):Promise<boolean> {
    const r = await backendApi.post(`${this.prefix}${data.slug}/callbacks/`, data.data);
    return r.status === 200;
  }

}

const campEventsAPI = new CampEventsAPI();
export default campEventsAPI;
