import { makeAutoObservable } from 'mobx';
import { NotificationCounters } from '../models/responses/notificationCounters';
import api from '../api';

class NotificationWidgetsStore {
  isLoading: boolean;
  counters?: NotificationCounters;

  constructor() {
    this.isLoading = false;

    makeAutoObservable(this);
  }

  async loadCounters() {
    this.isLoading = true;
    this.counters = await api.notifications.getCounters();
    this.isLoading = false;
  }

  async loadCountersOnce() {
    if (this.isLoading || this.counters) {
      return;
    }
    await this.loadCounters();
  }
}

export default new NotificationWidgetsStore();
