import browserHistory from 'browserHistory';
import withClientAuth from 'components/Hoc/AuthorizedComponent/authorizedResourse';
import EventCreatePage from 'components/Pages/Event/Create/EventCreatePage';
import EventPayPage from 'components/Pages/Event/Pay/EventPayPage';
import ViewEventPage from 'components/Pages/Event/View/ViewEventPage';
import EditProfilePage from 'components/Pages/Profile/EditProfilePage/EditProfilePage';
import ProfilePage from 'components/Pages/Profile/ProfilePage/ProfilePage';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { Route, Router, Switch } from 'react-router';
import UserStore from '../../../stores/UserStore';
import HomePage from '../../Pages/Home/HomePage';
import LegalPage from '../../Pages/LegalPage';
import PasswordRecoveryPage from '../../Pages/PasswordRecoveryPage';
import PolicyPage from '../../Pages/PolicyPage';
import SearchPage from '../../Pages/Search/SearchPage';
import SupportPage from '../../Pages/SupportPage';
import Modal from '../Modal/Modal';
import ArenaPage from '../../Pages/Arena/ArenaPage';
import ErrorPaymentPage from '../../Pages/Payment/ErrorPaymentPage';
import RemindPhonePopup from 'components/common/remindPhonePopup/remindPhonePopup';
import ModalStore from 'stores/ModalStore';
import appStore from '../../../stores/AppStore';
import CriticalError from '../ErrorBoundary/CriticalError';
import * as Sentry from '@sentry/react';
import FAQPage from "../../Pages/FAQ/FAQPage/FAQPage";
import CampsSearchPage from "../../Pages/Camps/SearchPage/CampsSearchPage";
import CampPage from "../../Pages/Camps/CampPage/CampPage";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import ErrorPage from "../../Pages/Error/ErrorPage";
import MobAppSuccessCallbackPage from 'components/Pages/Payment/MobAppCallbacks/Success';
import MobAppErrorCallbackPage from "../../Pages/Payment/MobAppCallbacks/Error";

const App = observer(() => {
  useEffect(() => {
    const getUserData = async () => {
      await UserStore.getUserData();
    };
    getUserData().then(() => {
      if (UserStore.userData?.config.phonePopup) {
        ModalStore.showModal(<RemindPhonePopup />);
        ModalStore.setRemindPhonePopupActive(true);
      }
    });
  }, []);

  if (appStore.criticalError) {
    return <CriticalError />;
  }

  return UserStore.canLoadApp ? (
    <Sentry.ErrorBoundary fallback={<CriticalError />}>
      <Router history={browserHistory}>
        <ScrollToTop/>
        <>
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/search/" component={SearchPage} />
            <Route exact path="/support/" component={SupportPage} />
            <Route exact path="/legal/" component={LegalPage} />
            <Route exact path="/policy/" component={PolicyPage} />
            <Route
              exact
              path="/passwordRecovery/"
              component={PasswordRecoveryPage}
            />
            <Route exact path="/event/view/:hash" component={ViewEventPage} />
            <Route
              exact
              path="/event/create/:hash"
              component={EventCreatePage}
            />
            <Route
              exact
              path="/order/:orderId/confirm/"
              component={ErrorPaymentPage}
            />
            <Route
              exact
              path="/event/pay/:hash/order/:orderId"
              component={EventPayPage}
            />
            <Route
              exact
              path="/cabinet/"
              component={withClientAuth(ProfilePage)}
            />
            <Route
              exact
              path="/cabinet/view/:userId"
              component={withClientAuth(ProfilePage)}
            />
            <Route
              exact
              path="/cabinet/edit/"
              component={withClientAuth(EditProfilePage)}
            />
            <Route exact path="/arenas/:slug" component={ArenaPage} />
            <Route exact path="/faq/" component={FAQPage} />
            <Route exact path="/camps/" component={CampsSearchPage} />
            <Route exact path="/camps/:slug" component={CampPage} />
            <Route exact path="/event/pay-success/" component={MobAppSuccessCallbackPage} />
            <Route exact path="/event/pay-fail/" component={MobAppErrorCallbackPage} />
            <Route component={ErrorPage} />
          </Switch>
          <Modal />
        </>
      </Router>
    </Sentry.ErrorBoundary>
  ) : null;
});

export default App;
