import React from 'react';
import styles from './FAQArticle.module.scss';
import { FaqItem } from '../../../../models/entities/faqItem';

interface Props {
  faqItem?: FaqItem;
}

export const FAQArticle: React.FC<Props> = (props) => {
  if (!props.faqItem) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>{props.faqItem.title}</div>
      <div
        className={styles.content}
        dangerouslySetInnerHTML={{ __html: props.faqItem.contentHtml }}
      />
    </div>
  );
};
