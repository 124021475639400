import React, { useEffect } from 'react';
import { Widget } from '../../../UI/Widget/Widget';
import { observer } from 'mobx-react';
import messageIcon from 'imgs/message.svg';
import browserHistory from '../../../../browserHistory';
import store from '../../../../stores/NotificationWidgetsStore';
import {SpecialNotificationGroup} from "../../../../enums/notification";
import {Tabs} from "../../../Pages/Profile/ProfilePage/model";

const ChatNotificationsWidget: React.FC = () => {
  const label = store.counters?.unreadChatsCount;

  useEffect(() => {
    store.loadCountersOnce();
  }, []);

  const onClick = () => {
    browserHistory.push(`/cabinet?tabId=${Tabs.notifications}&group=${SpecialNotificationGroup.CHATS}`);
  };

  return <Widget iconSrc={messageIcon} label={label!} onClick={onClick} />;
};

export default observer(ChatNotificationsWidget);
