import authAPI from 'api/auth';
import gameEventsAPI from 'api/gameEvents';
import ordersApi from 'api/orders';
import vouchersAPI from 'api/vouchers';
import cityAPI from './city';
import homeAPI from './home';
import arenaAPI from './arena';
import profileAPI from './profile';
import chatAPI from './chat';
import notificationsAPI from './notifications';
import faqAPI from './faq';
import campEventsAPI from "./campEvents";

class API {
  home = homeAPI;
  city = cityAPI;
  auth = authAPI;
  gameEvents = gameEventsAPI;
  vouchers = vouchersAPI;
  orders = ordersApi;
  profile = profileAPI;
  arena = arenaAPI;
  chat = chatAPI;
  notifications = notificationsAPI;
  faq = faqAPI;
  camps = campEventsAPI;
}

const api = new API();
export default api;
