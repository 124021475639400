import TabList from '../../../UI/Tabs/TabList';
import Tab from '../../../UI/Tabs/Tab';
import ExitButton from './ExitButton';
import TabPanel from '../../../UI/Tabs/TabPanel';
import ProfileCard from './ProfileCard/ProfileCard';
import MyGames from './Games/MyGames';
import MyTrainings from './Games/MyTrainings';
import useTabs from '../../../UI/Tabs/tabHook';
import Quiz from './Quiz/Quiz';
import NotificationsSection from './Notifications/NotificationsSection/NotificationsSection';
import { getQuery } from '../../../../utils/urlConstructors';
import { useMemo } from 'react';
import css from './Tabs.module.scss';
import {Tabs, titles} from "./model";
import EventsArchive from "./Games/EventsArchive/EventsArchive";
import Recommendations from "./Games/Recommendations/Recommendations";

function OwnTabs() {

  const defaultTabIndex = useMemo(() => {
    const index = Number(getQuery('tabId')) || Tabs.profile;
    if (index < 0 || index > Object.keys(Tabs).length / 2) {
      return Tabs.profile;
    }
    return index;
  }, []);

  const { connect } = useTabs(defaultTabIndex, 'tabId');

  return (
    <>
      <TabList className={css.tabs}>
        <Tab {...connect(Tabs.profile)}>{titles[Tabs.profile]}</Tab>
        <Tab {...connect(Tabs.futureGames)}>{titles[Tabs.futureGames]}</Tab>
        <Tab {...connect(Tabs.futureTrainings)}>{titles[Tabs.futureTrainings]}</Tab>
        <Tab {...connect(Tabs.quiz)}>{titles[Tabs.quiz]}</Tab>
        <Tab {...connect(Tabs.eventsArchive)}>{titles[Tabs.eventsArchive]}</Tab>
        <Tab {...connect(Tabs.recommendations)}>{titles[Tabs.recommendations]}</Tab>
        <Tab {...connect(Tabs.notifications)}>{titles[Tabs.notifications]}</Tab>
        <ExitButton />
      </TabList>

        <div className={css.container}>
            <TabPanel {...connect(Tabs.profile)}>
                <ProfileCard />
            </TabPanel>
            <TabPanel {...connect(Tabs.futureGames)}>
                <MyGames />
            </TabPanel>
            <TabPanel {...connect(Tabs.futureTrainings)}>
                <MyTrainings />
            </TabPanel>
            <TabPanel {...connect(Tabs.quiz)}>
                <Quiz />
            </TabPanel>
            <TabPanel {...connect(Tabs.eventsArchive)}>
                <EventsArchive/>
            </TabPanel>
            <TabPanel {...connect(Tabs.recommendations)}>
                <Recommendations/>
            </TabPanel>
            <TabPanel {...connect(Tabs.notifications)}>
                <NotificationsSection />
            </TabPanel>
        </div>
    </>
  );
}

export default OwnTabs;
