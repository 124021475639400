import commonCss from 'css/Common.module.css';
import PayForm from 'components/Pages/Event/Pay/PayForm';
import VoucherForm from 'components/Pages/Event/Pay/VoucherInput';
import BackLink from 'components/Pages/Profile/LKLayout/BackLink/BackLink';
import { observer } from 'mobx-react';
import moment from 'moment/moment';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import EventPayStore from 'stores/EventPayStore';
import css from './EventPayPage.module.scss';
import Page from "../../../Hoc/Page/Page";

const EventPayPage = observer(() => {
  const { hash, orderId } = useParams<{ hash: string; orderId: string }>();
  useEffect(() => {
    EventPayStore.getData(hash, orderId);
    return () => EventPayStore.reset();
  }, [hash, orderId]);

  if (!EventPayStore.eventData) return null;

  return (
    <Page>
      <div className={commonCss.wrap}>
        <BackLink />
        <div className={css.container}>
          <h1 className={commonCss.pageTitle}>
            Оплата заказа №{EventPayStore.eventData.order.id}
          </h1>
          <div>
            <div className={css.infoBlock}>
              <span className={css.infoBlockTitle}>Имя игрока </span>
              <span className={css.infoBlockText}>
                {EventPayStore.eventData.user.name || ''}
              </span>
            </div>
            <div className={css.infoBlock}>
              <span className={css.infoBlockTitle}>Стоимость </span>
              <span className={css.infoBlockText}>
                {EventPayStore.eventData.order.cost} ₽
              </span>
            </div>

            {!EventPayStore.eventData.disableVouchers && (
                <div className={css.infoBlockColumn}>
                  <span className={css.infoBlockTitle}>Промокод </span>
                  <span className={css.infoBlockText}>
                    <VoucherForm />
                  </span>
                </div>
            )}

            <PayForm />

            <div className={css.addInfo}>
              <span className={css.addInfoTitle}>Информация о возвратах</span>

              {EventPayStore.eventData.cancelDate ? (
                <span className={css.addInfoText}>
                  Возврат денежных средств доступен при отмене бронирования до{' '}
                  {moment(EventPayStore.eventData.cancelDate).format(
                    'DD.MM.YYYY HH:mm:ss'
                  )}
                  . После наступления этого времени денежные средства не
                  возвращаются.
                </span>
              ) : (
                <span className={css.addInfoText}>
                  Внимание, время бронирования наступит менее чем через час,
                  возврат денежных средств при отмене невозможен.
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
});

export default EventPayPage;
