import React from 'react';
import styles from './FAQNav.module.scss';
import { FaqItem } from '../../../../models/entities/faqItem';
import classNames from 'classnames';

interface Props {
  title: string;
  selectedItem?: FaqItem;
  items: FaqItem[];
  onSelect: (itemId: string) => void;
}

export const FAQNav: React.FC<Props> = (props) => {
  const item = (data: FaqItem) => {
    const itemClassName = classNames(
      styles.item,
      data.title === props.selectedItem?.title && styles.selectedItem
    );

    return (
      <div
        className={itemClassName}
        key={data.title}
        onClick={() => props.onSelect(data.title)}
      >
        {data.title}
      </div>
    );
  };

  const items = () => props.items.map(item);

  return (
    <div className={styles.container}>
      <div className={styles.title}>{props.title}</div>
      <div className={styles.itemList}>{items()}</div>
    </div>
  );
};
