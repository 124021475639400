import { getPlayerSign } from '../../../../../utils/functions';
import React from 'react';
import css from './Chat.module.scss';
import User from '../../../../../models/entities/user';
import classNames from "classnames";
import linearGradient from "../../../../../css/linearGradient.module.css";
import {arrGradients} from "../../../../../utils/const";

function ChatAvatar({ user }: { user: User }) {
  return (
    <div
      className={classNames(
          css.gameCard__member,
          !user.photo && linearGradient[arrGradients[Number(user.id) % arrGradients.length]]
      )}
      style={{ backgroundImage: user.photo ? `url(${user.photo})` : '' }}
    >
      <span className={css.gameCard__memberName} title={user.name}>
        {user.photo ? '' : getPlayerSign(user.name)}
      </span>
    </div>
  );
}

export default ChatAvatar;
