import React, { useEffect } from 'react';
import styles from './NotificationsSection.module.scss';
import { observer } from 'mobx-react';
import store from '../../../../../../stores/NotificationsSectionStore';
import NotificationGroupsNav from '../NotificationCategories/NotificationGroupsNav';
import NotificationsHeader from '../NotificationsHeader/NotificationsHeader';
import NotificationList from '../NotificationList/NotificationList';
import NotificationsFooter from '../NotificationsFooter/NotificationsFooter';
import { getQuery } from '../../../../../../utils/urlConstructors';
import {NotificationGroup} from "../../../../../../models/entities/notificationGroup";
import Spinner from "../../../../../UI/Loader/Loader";
import cssTabs from 'components/Pages/Profile/ProfilePage/Tabs.module.scss';

const NotificationsSection: React.FC = () => {

  useEffect(() => {
    store.loadCounters();

    const groupKey = getQuery('group');
    if (groupKey) {
        store.selectGroup(groupKey as NotificationGroup);
    } else {
        store.selectDefaultGroup();
    }
  }, []);

  if (!store.pageData) {
      return <Spinner className={cssTabs.spinner}/>
  }

  const isDesktopVersion = window.matchMedia('(min-width: 840px)').matches;
  const isEmptyPage = !store.pageData.results.length;

  const content = () => {
    if (isEmptyPage) {
      return <div>Не найдено уведомлений по выбранной категории.</div>;
    }
    return (
      <>
        <NotificationsHeader />
        <NotificationList />
        <NotificationsFooter />
      </>
    );
  };

  return (
    <div className={styles.section}>
      <div className={styles.column1}>
        <NotificationGroupsNav
          type={isDesktopVersion ? 'menu' : 'select'}
          separatorIndexes={[6]}
        />
      </div>
      <div className={styles.column2}>{content()}</div>
    </div>
  );
};

export default observer(NotificationsSection);
