export enum Tabs {
    profile,
    futureGames,
    recommendations,
    futureTrainings,
    quiz,
    notifications,
    eventsArchive
}

export const titles = {
    [Tabs.profile]: 'Профиль',
    [Tabs.futureGames]: 'Мои игры',
    [Tabs.recommendations]: 'Рекомендации',
    [Tabs.futureTrainings]: 'Мои тренировки',
    [Tabs.quiz]: 'Опрос',
    [Tabs.notifications]: 'Уведомления',
    [Tabs.eventsArchive]: 'Архив событий'
}