import { Ad } from '../../../../../models/entities/ad';
import {Card} from "../../../../UI/Card/Card";
import styles from './Card.module.scss';
import React from 'react';
import moment from 'moment';
import classNames from 'classnames';

interface Props {
  ad: Ad;
}

export const AdCard = (props:Props):JSX.Element => {

  const formatDate = (stamp: string) => moment(stamp).format('DD MMMM');
  const datesText = `${formatDate(props.ad.eventStartAt)} - ${formatDate(
    props.ad.eventEndAt
  )}`;

  return (
    <Card className={styles.card}>
        <div className={styles.topRow}>Реклама</div>
        <div className={styles.contentContainer}>
            <div className={classNames(
                styles.header,
                styles.contentItem
            )}>
                <div className={styles.headerInfo}>
                    <div className={styles.dates}>{datesText}</div>
                    <div className={classNames([styles.text, styles.orange])}>
                        {props.ad.name}
                    </div>
                </div>
                <a href={props.ad.partnerUrl} target="_blank" className={styles.logo}>
                    <img src={props.ad.logo} alt="logo" />
                </a>
            </div>
            <div className={classNames(
                styles.content,
                styles.contentItem
            )}>
                <div className={styles.info}>
                    <div className={styles.text}>{props.ad.sports}</div>
                    <div className={styles.text}>{props.ad.organizer}</div>
                    <div className={styles.text}>{props.ad.location}</div>
                </div>
            </div>
        </div>
        <a href={props.ad.partnerUrl} target="_blank" className={styles.footerLink}>Подробнее</a>
    </Card>
  );
};
