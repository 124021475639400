import { FormField } from 'components/Hoc/Form/Field';
import Form from 'components/Hoc/Form/Form';
import { FormInput } from 'components/UI/FormWithLabelsUI/FormInput/FormInput';
import DropPasswordRequest from 'models/requests/dropPassword';
import LoginRequest from 'models/requests/login';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import AuthorizationStore from 'stores/AuthorizationStore';
import Button from '../../../UI/Button/Button';
import SignInSocial from '../../shared/LoginForm/SignInSocial';
import ModalStore from 'stores/ModalStore';
import RestorePassEmailSendSuccess from "components/Pages/shared/LoginForm/RestorePassModals/RestorePassEmailSendSuccess";

interface PassProps {
  returnBack: (toggle: boolean) => void;
  defaultEmail: string
}

const RestorePass: React.FC<PassProps> = ({ returnBack, defaultEmail }: PassProps) => {
  const form = useForm({
    defaultValues: {
      email: defaultEmail
    }
  });
  return (
    <Form
      formReturn={form}
      onSubmit={async (data: DropPasswordRequest) => {
        await AuthorizationStore.doResetPassword(data);
        ModalStore.showModal(<RestorePassEmailSendSuccess />);
      }}
    >
      <div className="signIn__restore-pass" onClick={() => returnBack(false)}>
        Назад
      </div>
      <FormField
        Component={FormInput}
        name="email"
        label="Email"
        defaultValue=""
      />
      <Button type="submit" buttonText="Восстановить пароль" />
    </Form>
  );
};

interface SigInProps {
  returnBack: (toggle: boolean) => void;
  setEmail: (email: string) => void;
}

const SignInForm: React.FC<SigInProps> = ({ returnBack, setEmail }: SigInProps) => {
  const form = useForm<LoginRequest>();
  setEmail(form.watch('email'));
  return (
    <div className="signIn">
      <Form<LoginRequest>
        formReturn={form}
        onSubmit={async (data: LoginRequest) => {
          await AuthorizationStore.doLogin(data);
        }}
      >
        <FormField
          name="email"
          type="email"
          label="Email"
          Component={FormInput}
          rules={{required: true}}
        />
        <FormField
          name="password"
          type="password"
          label="Пароль"
          Component={FormInput}
          rules={{required: true}}
        />
        <div className="signIn__restore-pass" onClick={() => returnBack(true)}>
          Забыли пароль?
        </div>
        <Button type="submit" buttonText="Войти" />
        <div className="signIn__another">или войдите с помощью</div>
        <SignInSocial />
      </Form>
    </div>
  );
};

const SignIn: React.FC = () => {
  const [restorePassTabActive, setRestorePassTabActive] = useState(false);
  const [emailField, setEmailField] = useState('');
  return restorePassTabActive ? (
    <RestorePass returnBack={setRestorePassTabActive} defaultEmail={emailField} />
  ) : (
    <SignInForm returnBack={setRestorePassTabActive} setEmail={setEmailField} />
  );
};

export default SignIn;
