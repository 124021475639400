import React from "react";
import QuizQuestionResponse from "../../../../../models/entities/quizQuestion";
import CheckboxGroup from "../../../../UI/CheckboxGroup/CheckboxGroup";
import RadioButtonGroup from "../../../../UI/RadioButton/RadioButtonGroup";
import { OnChangeProps } from "./QuizHelper";
import css from "./QuizQuestion.module.scss";
import { getNormalizedOptions } from "./QuizHelper";

interface QuestionTypesInterface {
  [key: string]: [React.FC<any>, string?]
}

const questionTypes:QuestionTypesInterface = {
  'checkbox': [CheckboxGroup],
  'checkbox/inbox': [CheckboxGroup, 'inbox'],
  'radio': [RadioButtonGroup, 'inbox'],
};

interface QuizQuestionProps extends Omit<QuizQuestionResponse, 'id'> {
  id: string | number,
  selectedOptions: any,
  onChange: OnChangeProps,
  hide?: boolean,
  error?: boolean
}

const playerLevelClass = [
  'light',
  'medium',
  'hard'
];

const QuizQuestion: React.FC<QuizQuestionProps> = (props) => {
  const ChildComponent = questionTypes[props.kind][0];
  return (
    <div style={props.hide ? { display: 'none' } : {}} className={css.quizQuestion}>
      {props.text && (<p className={`${css.quizQuestion__title} ${props.error ? css.error : ''}`}>{props.text}</p>)}
      {questionTypes[props.kind] && (
        <ChildComponent
          value={props.selectedOptions}
          selectedOptions={props.selectedOptions}
          options={getNormalizedOptions(props.options)}
          onChange={props.onChange}
          id={props.id}
          style={questionTypes[props.kind][1]}
          optionsClass={props.kind === 'radio' && playerLevelClass}
        />
      )}
    </div>
  );
};

export default QuizQuestion;